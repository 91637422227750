import { Component, OnInit } from '@angular/core';
import { ElmconnectionsComponent } from '../elmconnections/elmconnections.component';
@Component({
  selector: 'app-elmconnectionsloader',
  templateUrl: './elmconnectionsloader.component.html',
  styleUrls: ['./elmconnectionsloader.component.css']
})
export class ElmconnectionsloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}