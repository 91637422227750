import { Component, OnInit } from '@angular/core';
import { GeofencesComponent } from '../geofences.component'

@Component({
  selector: 'app-geofencesloader',
  templateUrl: './geofencesloader.component.html',
  styleUrls: ['./geofencesloader.component.css']
})
export class GeofencesloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
