import { HostListener,Component, ViewEncapsulation } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent,EMPTY , Observable} from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/first';
import { Subscription } from 'rxjs/Subscription';
//npm i ng-connection-service
import { ConnectionService } from 'ng-connection-service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
//import { } from '@types/';
declare var $ :any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})



export class AppComponent {
  title = 'sareb';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus="";
  isLoggedIn:boolean;
  constructor( private local:LocalStorageService,private translate:TranslateService,private router: Router){
    /*window.onbeforeunload = function (e) {
      return null;
    } 
    window.onunload = function (e) {
      localStorage.removeItem('currentUser');
    }*/
    //this.isLoggedIn=true;
   
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    if(currentUser!=null){
      this.isLoggedIn=true;
                      
    }
    else{
      this.isLoggedIn=false;
    
      this.router.navigate(['login']);

    }
    this.getConnections();
  
 }

 
 getConnections(){
  this.onlineEvent=null;
  this.offlineEvent=null;
  this.onlineEvent = fromEvent(window, 'online');
  this.offlineEvent = fromEvent(window, 'offline');
  
  this.subscriptions.push(this.onlineEvent.first().subscribe(e => {
    this.connectionStatusMessage = 'Back to online';
    this.connectionStatus = 'online';

    this.translate.get(this.connectionStatusMessage).first().subscribe(res => {
      $.notify({ message:res}, {type:"success"});
    });
    location.reload();
    

    }));

    this.subscriptions.push(this.offlineEvent.first().subscribe(e => {
    this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
    this.connectionStatus = 'offline';
   /* for(var i=0; i<100; i++){
      window.clearInterval(i);
    }*/
    location.hash="noBack";
    window.onpopstate = function (event) {
      location.hash="noBack";
    }
    function disableF5(e) { if ( ((e.which || e.keyCode) == 116) || ((e.which || e.keyCode) == 82) ) e.preventDefault(); };    
    $(document).on("keydown", disableF5);
    $(window).bind('beforeunload', function(e) {
     return "Please don't reload untill connection return..";
      e.preventDefault();
    });
   
   


   
    this.translate.get(this.connectionStatusMessage).first().subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
    
  }));

  
   
  
}


  /*
  @HostListener('window:beforeunload', ['$event'])
     public beforeunloadHandler($event) {
     $event.returnValue = "Are you sure?";
    }
    */
  
}

