import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from './security/AuthGuard';
import { UsersComponent } from './components/users/users.component';
// import { ApiService } from './network/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { DataTablesModule } from 'angular-datatables';
import { } from 'google-maps';
import { ProfileloaderComponent } from './components/profile/profileloader/profileloader.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DevicesloaderComponent } from './components/devices/devicesloader/devicesloader.component';
import { DevicesComponent } from './components/devices/devices.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { DriversListloaderComponent } from './components/drivers/driversloader/driversloader.component';
import { DriverscreateComponent } from './components/drivers/driverscreate/driverscreate.component';
import { DrivercreateloaderComponent } from './components/drivers/driverscreate/drivercreateloader/drivercreateloader.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DevicecreateComponent } from './components/devices/devicecreate/devicecreate/devicecreate.component';
import { DevicecreateloaderComponent } from './components/devices/devicecreate/devicecreateloader/devicecreateloader.component';
import { TripreportComponent } from './components/reports/trips/tripreport/tripreport.component';
import { TripreportloaderComponent } from './components/reports/trips/tripreportloader/tripreportloader.component';
import { StopreportComponent } from './components/reports/stops/stopreport/stopreport.component';
import { StopreportloaderComponent } from './components/reports/stops/stopreportloader/stopreportloader.component';
import { EventreportComponent } from './components/reports/events/eventreport/eventreport.component';
import { EventreportloaderComponent } from './components/reports/events/eventreportloader/eventreportloader.component';
import { UsersloaderComponent } from './components/users/usersloader/usersloader.component';
import { UsercreateComponent } from './components/users/usercreate/usercreate/usercreate.component';
import { UsercreateloaderComponent } from './components/users/usercreate/usercreateloader/usercreateloader.component';
import { GeofencesComponent } from './components/geofences/geofences.component';
import { GeofencesloaderComponent } from './components/geofences/geofencesloader/geofencesloader.component';
import { GeofencescreateComponent } from './components/geofences/geofencescreate/geofencescreate.component';
import { GeofencecreateloaderComponent } from './components/geofences/geofencescreate/geofencecreateloader/geofencecreateloader.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import {ExcelService} from './services/excel.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ForgetpasswordComponent } from './components/forgetPassword/forgetPassword.component';
import {CaliberationTableloaderComponent} from './components/devices/caliberationTable/caliberationTableloader/caliberationTableloader.component'
import {CaliberationTableComponent} from './components/devices/caliberationTable/caliberationTable/caliberationTable.component'
import {DeviceworkinghoursloaderComponent} from './components/reports/deviceworkinghours/deviceworkinghoursloader/deviceworkinghoursloader.component'
import {DeviceworkinghoursComponent} from './components/reports/deviceworkinghours/deviceworkinghours/deviceworkinghours.component'
import {DriverworkinghoursloaderComponent} from './components/reports/driverworkinghours/driverworkinghoursloader/driverworkinghoursloader.component'
import {DriverworkinghoursComponent} from './components/reports/driverworkinghours/driverworkinghours/driverworkinghours.component'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ElmconnectionsloaderComponent} from './components/elmconnections/elmconnectionsloader/elmconnectionsloader.component'
import {ElmconnectionsComponent} from './components/elmconnections/elmconnections/elmconnections.component'
import { BlockUIModule } from 'ng-block-ui';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    MainComponent,
    UsersComponent,
    DevicesloaderComponent,
    DevicesComponent,
    DriversComponent,
    DriversListloaderComponent,
    DriverscreateComponent,
    DrivercreateloaderComponent,
    DevicecreateComponent,
    DevicecreateloaderComponent,
    TripreportComponent,
    TripreportloaderComponent,
    StopreportComponent,
    StopreportloaderComponent,
    EventreportComponent,
    EventreportloaderComponent,
    UsersloaderComponent,
    UsercreateComponent,
    UsercreateloaderComponent,
    GeofencesComponent,
    GeofencesloaderComponent,
    GeofencescreateComponent,
    GeofencecreateloaderComponent,
    GeofencesloaderComponent,
    ProfileComponent,
    ProfileloaderComponent,
    UploadImageComponent,
    ForgetpasswordComponent,
    CaliberationTableloaderComponent,
    CaliberationTableComponent,
    DeviceworkinghoursloaderComponent,
    DeviceworkinghoursComponent,
    DriverworkinghoursloaderComponent,
    DriverworkinghoursComponent,
    ElmconnectionsloaderComponent,
    ElmconnectionsComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    NgSelectModule,
    NgbModule,
    BlockUIModule.forRoot(),
 
   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AuthGuard,
              ExcelService,
              NavbarComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/local/', '.json');
}