import { Component, OnInit, AfterViewInit,ChangeDetectionStrategy, Input,NgZone } from '@angular/core';
import { Driver } from '../../../shared/drivers';
import { ApiService } from '../../../network/api.service';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgModule }      from '@angular/core';
import { checkAndUpdateDirectiveDynamic } from '@angular/core/src/view/provider';
import { TranslateService } from '@ngx-translate/core';
import * as swal from '../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
declare var jquery:any;
declare var $ :any;
declare function require(path: string): any;
var moment = require('moment-hijri');       

@Component({
  selector: 'app-driverscreate',
  templateUrl: './driverscreate.component.html',
  styleUrls: ['./driverscreate.component.css']
})

export class DriverscreateComponent implements AfterViewInit,OnInit {
  @Input() driverId: number;
  driver: Driver;
  dublicatedId:boolean;
  dublicatedName:boolean;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  dublicatedMobile:boolean;
  errorDateGreg:boolean;
  errorDateHij:boolean;
  arrHijjYear: Array<number>;
  arrGregYear: Array<number>;
  gregorian:boolean;
  dir="";
  dirButton="";
  dirtable="";
  Month="";
  Day="";
  Year="";
  Muharram=""
  Safar=""
  Rab1="";
  Rab2="";
  Jum1="";
  Jum2="";
  Sha="";
  Ramadan="";
  Shawwal="";
  Dhu1="";
  Rajab=""
  Dhu2="";
  January="";
  February="";
  March="";
  April="";
  May="";
  June="";
  July="";
  August="";
  September="";
  October="";
  November="";
  December="";
  Driver_Name="";
  Mobile_Number="";
  hijriday="";
  hijrimonth="";
 hijriyear="";
 Gregorianday="";
 Gregorianmonth="";
 Gregorianyear="";
  arrHijjMonth=[];
  arrGregMonth=[];
  driverForm: FormGroup;
  submitted:boolean;
  corporationObj:String;
  hijjDayValue:number;
  hasReferenceKey:boolean;
  selectedFile:File;
  submittedImage:string;
  imageUrl:string;
  selectedFileSize:boolean;
  selectedFileType:boolean;

  constructor(private local:LocalStorageService,public translate: TranslateService,private formBuilder: FormBuilder,private apiService: ApiService,private router: Router ,private routerActive :ActivatedRoute,
    private zone: NgZone,
    private session:SessionStorageService) {
      this.selectedFile=null;
      this.submittedImage=null;
      this.imageUrl="http://31.204.150.201/sareb_backend/photos/drivers/Not-available.png";
      this.selectedFileSize=false;
      this.selectedFileType=false;
      
     
  }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
  }
  ngOnInit() {
    
    this.hasReferenceKey=false;
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      this.Month="Month";
      this.Muharram="Muḥarram";
      this.Safar="Ṣafar";
      this.Rab1="Rabī‘ al-awwal";
      this.Rab2="Rabī‘ ath-thānī";
      this.Jum1="Jumādá al-ūlá";
      this.Jum2="Jumādá al-ākhirah";
      this.Sha="Sha‘bān";
      this.Ramadan="Ramaḍān";
      this.Shawwal="Shawwāl";
      this.Dhu1="Dhū al-Qa‘dah";
      this.Dhu2="Dhū al-Ḥijjah";
      this.Rajab="Rajab";
      this.Day="Day";
      this.Year="Year";
      this.January="January";
      this.February="February";
      this.March="March";
      this.April="April";
      this.May="May";
      this.June="June";
      this.July="July";
      this.August="August";
      this.September="September";
      this.October="October";
      this.November="November";
      this.December="December";
     
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      this.Month="شهر";
      this.Muharram="محرم";
      this.Safar="صفر";
      this.Rab1="ربيع الأول";
      this.Rab2="ربيع الثاني";
      this.Rajab="رجب";
      this.Jum1="جماد الأول";
      this.Jum2="جماد الثاني";
      this.Sha="شعبان";
      this.Ramadan="رمضان";
      this.Shawwal="شوال";
      this.Dhu1="ذو القعدة";
      this.Dhu2="ذو الحجة";
      this.Day="يوم";
      this.Year="سنة";
      this.January="يناير";
      this.February="فبراير";
      this.March="مارس";
      this.April="أبريل";
      this.May="مايو";
      this.June="يونيو";
      this.July="يوليو";
      this.August="أغسطس";
      this.September="سبتمبر";
      this.October="أكتوبر";
      this.November="نوفمبر";
      this.December="ديسيمبر";
      var elem1 = document.getElementById('radio1');
      elem1.style.cssFloat="right";
      var elem2 = document.getElementById('radio2');
      elem2.style.cssFloat="right";

     /* var elem3 = document.getElementById('dayHdir');
      elem3.style.cssFloat="right";
      var elem4 = document.getElementById('monthHdir');
      elem4.style.cssFloat="right";
      var elem5 = document.getElementById('yearHdir');
      elem5.style.cssFloat="right";
*/
     /* var elem6 = document.getElementById('dayGdir');
      elem6.style.cssFloat="right";
      var elem7 = document.getElementById('monthGdir');
      elem7.style.cssFloat="right";
      var elem8 = document.getElementById('yearGdir');
      elem8.style.cssFloat="right";*/

    }
    this.getConnections();
 
   
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    console.log("Trans : "+currentLan);
    this.driverForm = this.formBuilder.group({
      driverName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
      driverMobile: ['', [Validators.required,Validators.minLength,Validators.pattern]],
      driverUniqueid : ['', [Validators.required,Validators.minLength,Validators.pattern]],
      driverHijjDay : ['', [Validators.required]],
      driverHijjMonth : ['', [Validators.required]],
      driverHijjYear : ['', [Validators.required]],
      driverGergDay : ['', [Validators.required]],
      driverGergMonth : ['', [Validators.required]],
      driverGergYear : ['', [Validators.required]]
      
  });
  this.hijjDayValue = 0;
  this.submitted = false;
  this.gregorian = true;
  this.driver = new Driver();
  this.arrHijjYear = new Array<number>();
  this.arrGregYear = new Array<number>();
  this.sethijriYearArray();
  this.setGregorianYearArray();
  this.arrHijjMonth = [  "Muharram",
  "Safar",
  "Rabi' al-awwal",
  "Rabi' al-thani",
  "Jumada al-awwal",
  "Jumada al-thani",
  "Rajab",
  "Sha'ban",
  "Ramadan",
  "Shawwal",
  "Dhu al-Qi'dah",
  "Dhu al-Hijjah",
];
this.arrGregMonth = [ "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
];

  if(this.driverId)
    {
      
      let driverdata: any;
      driverdata={'driverId':this.driverId};
      if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.apiService.getDriverInfo(driverdata).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            this.driver = response["data"];
            this.Driver_Name=response["data"].name;
            this.Mobile_Number=response["data"].mobileNum;
            
            if(this.driver.dateType==0)
            {
              
              var element = document.getElementById("Gregorian_date");
              (<HTMLInputElement>element).checked = true;
              this.driver.Gregorianday=this.driver.day;
              this.driver.Gregorianmonth=this.driver.month;
              this.driver.Gregorianyear=this.driver.year;
              this.gregorian=true;
              this.hijriday="";
              this.hijrimonth="";
             this.hijriyear="";
             this.Gregorianday=response["data"].Gregorianday;
             this.Gregorianmonth=response["data"].Gregorianmonth;
             this.Gregorianyear=response["data"].Gregorianyear;
             this.formFields.driverHijjDay.setValue("");
             this.formFields.driverHijjMonth.setValue("");
             this.formFields.driverHijjYear.setValue("");
            }
            else
            {
              var element = document.getElementById("Hijri_date");
              (<HTMLInputElement>element).checked = true;
              this.driver.hijriday=this.driver.day;
              this.driver.hijrimonth=this.driver.month;
              this.driver.hijriyear=this.driver.year;
              this.gregorian=false;
              this.hijriday=response["data"].hijriday;
              this.hijrimonth=response["data"].hijrimonth;
             this.hijriyear=response["data"].hijriyear;
             this.Gregorianday="";
             this.Gregorianmonth="";
             this.Gregorianyear="";
             this.formFields.driverGergDay.setValue("");
             this.formFields.driverGergMonth.setValue("");
             this.formFields.driverGergYear.setValue("");
          }
             this.driver.referenceKey=response["data"].referenceKey;
             if(response["data"].photo !=null && response["data"].photo!="")
            {
              this.imageUrl="http://31.204.150.201/sareb_backend/photos/drivers/"+response["data"].photo;
            }
            else
            {
              this.imageUrl="http://31.204.150.201/sareb_backend/photos/drivers/Not-available.png";
            }
             if(this.driver.referenceKey!=null && this.driver.referenceKey!="")
             {
               this.hasReferenceKey=true;
             }
             if(this.hasReferenceKey)
             {
              this.driverForm.get('driverName').disable();
              this.driverForm.get('driverMobile').disable();
              this.driverForm.get('driverUniqueid').disable();
              this.driverForm.get('driverHijjDay').disable();
              this.driverForm.get('driverHijjMonth').disable();
              this.driverForm.get('driverHijjYear').disable();
              this.driverForm.get('driverGergDay').disable();
              this.driverForm.get('driverGergMonth').disable();
              this.driverForm.get('driverGergYear').disable();
             }
            
           //console.log("drivername"+this.driver.Gregorianday);
           //console.log("drivername"+this.arrGregMonth[this.driver.Gregorianmonth[1]]);

          }
        else {
            // show errors message
            console.log(response["error"]+"---"+response['message']);
            
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
      });
    }
    }
    else{
      var element = document.getElementById("Gregorian_date");
      (<HTMLInputElement>element).checked = true;
    }
   
  //call get info service

  }

  ngAfterViewInit(){
    
    // $('.select2').select2();
  }

  daysArray(n: number): number[] {
    var arr = new Array();
    for(var i =1;i< n+1 ;i ++){
      arr.push(i);
    }
    return arr;
  }
  
  sethijriYearArray() {
    for(var i =1339;i< 1439 ;i ++){
      this.arrHijjYear.push(i);
    }
  }
  setGregorianYearArray() {
    for(var i =1900;i< 2018 ;i ++){
      this.arrGregYear.push(i);
    }
  }
  changeDateType(event){
    this.gregorian=!this.gregorian;
    if(  this.gregorian==false)
    {
      this.driver.dateType=1;
    }
    else if(this.gregorian==true)
    {
      this.driver.dateType=0;
    }  
   console.log("gregorian : " +this.gregorian);

    
    
  }
  
  setDateDislayStyle(dis){
    var display = "none";
    if(dis == true){
      display = "unset";
    }
    let styles = {
        'display':  display
    };
    return styles;
  }
  // convenience getter for easy access to form fields
  get formFields() { return this.driverForm.controls; }

   onSubmit() {
    this.errorDateGreg=false;
    if(this.submittedImage!=null)
    {
      this.driver.photo=this.submittedImage;
    }
    else{
      this.driver.photo="No_upload";
    }
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
     if(this.driver.dateType==0)
     {
      
       this.driver.day=this.driver.Gregorianday;
       this.driver.month=this.driver.Gregorianmonth;
       this.driver.year=this.driver.Gregorianyear;
       var Myyear = parseInt(this.driver.year);
       var dt = new Date();
       var intYear = dt.getFullYear() - 18;
       console.log("date : " +intYear);
       console.log("date : " +Myyear);
       


        if(intYear<Myyear){
          this.errorDateGreg=true;
        }
        else{
          this.errorDateGreg=false;
        }
     }
     else if(this.driver.dateType==1)
     {
      this.driver.day=this.driver.hijriday;
      this.driver.month=this.driver.hijrimonth;
      this.driver.year=this.driver.hijriyear;
      var Myyear = parseInt(this.driver.year);
      var dt = new Date();
      var currYear = dt.getFullYear() - 598 +2;
      console.log("currYear : " +currYear);
      console.log("Myyear : " + Myyear );
      if(currYear<Myyear){
        this.errorDateGreg=true;
      }
      else{
        this.errorDateGreg=false;
      }

     }
     
    this.submitted = true;
    this.dublicatedId=false;
    this.dublicatedName=false;
    this.dublicatedMobile=false;
    
    //var email = this.formFields.email.value;
   //var password = this.formFields.password.value;
    
    if(this.errorDateGreg==false&&!this.formFields.driverName.errors && !this.formFields.driverMobile.errors && !this.formFields.driverUniqueid.errors)
    {
      if(this.gregorian){
          if( !this.formFields.driverGergDay.errors && !this.formFields.driverGergMonth.errors && !this.formFields.driverGergYear.errors){
            console.log("date : " +this.driver.dateType +" day : "+this.driver.day);
            console.log("date : " +this.driver.dateType +" month : "+this.driver.month);
            console.log("date : " +this.driver.dateType +" year : "+this.driver.year);
            this.apiService.addOrUpdateDriver(this.driver).subscribe((response) => {
            

              if (response["error"] == 0) {
                  console.log(response);
                  this.driver = response["data"];
                  /*var message =response["message"];
                  console.log("message: "+message);
                  var result="";
                  this.translate.get("Updated successfully.").subscribe(res => {
                    //console.log(res);
                    result=res;
                  
                  });*/
                  this.translate.get(response["message"]).subscribe(res => {
                    $.notify({message: res}, {type: 'success'});
                  });
               
                  //console.log("Trans : "+this.translate.get(response["message"]));

                  this.zone.run(() => this.router.navigate(['drivers'],{ 
                    skipLocationChange: true 
                  }));
                 
      
                }
              else {
                //$.notify({message: response["message"]}, {type: 'danger'});
                console.log(response["error"]+response['message']);
                console.log(response["details"]);
                for (var k = 0; k <response["details"].length; k++) {
                  if(response["details"][k]==1){
                    this.dublicatedName=true;
                  }
                  if(response["details"][k]==2){
                    this.dublicatedId=true;
                  }
                  if(response["details"][k]==3){
                    this.dublicatedMobile=true;
                  }
                }  
              }
          },
          error => {
              // this.alertService.error(error);
              console.log("error" + error);
              // this.loading = false;
          });
          
          }
      }
      else{
        if( !this.formFields.driverHijjDay.errors && !this.formFields.driverHijjMonth.errors && !this.formFields.driverHijjYear.errors){
            // call the update or create service
            console.log("date : " +this.driver.dateType +" day : "+this.driver.day);
            console.log("date : " +this.driver.dateType +" month : "+this.driver.month);
            console.log("date : " +this.driver.dateType +" year : "+this.driver.year);
            this.apiService.addOrUpdateDriver(this.driver).subscribe((response) => {
              if (response["error"] == 0) {
                  console.log(response);
                  this.driver = response["data"];
                  this.translate.get(response["message"]).subscribe(res => {
                    $.notify({message: res}, {type: 'success'});
                  });
                  this.zone.run(() => this.router.navigate(['drivers'],{ 
                    skipLocationChange: true 
                  }));

      
                }
              else {
                //$.notify({message: response["message"]}, {type: 'danger'});
                console.log(response["error"]+response['message']);
                console.log(response["details"]);
                for (var k = 0; k <response["details"].length; k++) {
                  if(response["details"][k]==1){
                    this.dublicatedName=true;
                  }
                  if(response["details"][k]==2){
                    this.dublicatedId=true;
                  }
                  if(response["details"][k]==3){
                    this.dublicatedMobile=true;
                  }
                }  

              }
          },
          error => {
              // this.alertService.error(error);
              console.log("error" + error);
              // this.loading = false;
          });
          
        }
      }
    } 
    else{
        this.submitted = true;
    }
    // console.log("submit" +  email + " password = "+ password);
    
    // this.loading = true;
  }
  }

  onChangee(deviceValue) {
    console.log(deviceValue);
  }
  /*clearDriver()
  {
   this.driverForm.reset();
   const dayH=this.driverForm.get('driverHijjDay');
   dayH.setValue("");
   const monthH=this.driverForm.get('driverHijjMonth');
   monthH.setValue("");
   const yearH=this.driverForm.get('driverHijjYear');
   yearH.setValue("");
   const dayG=this.driverForm.get('driverGergDay');
   dayG.setValue("");
   const monthG=this.driverForm.get('driverGergMonth');
   monthG.setValue("");
   const yearG=this.driverForm.get('driverGergYear');
   yearG.setValue("");
    
  }*/

  goToBackAdd()
  {
   var self=this;
  
   if(this.connectionStatus=="offline")
   {
     this.translate.get(this.connectionStatusMessage).subscribe(res => {
       $.notify({ message:res}, {type:"danger"});
     });
   }
   else{
    if(this.formFields.driverName.value!="" || this.formFields.driverMobile.value!="" || this.formFields.driverUniqueid.value!=""||this.formFields.driverHijjDay.value!="" ||this.formFields.driverHijjMonth.value!="" ||this.formFields.driverHijjYear.value!=""|| this.formFields.driverGergDay.value!="" || this.formFields.driverGergMonth.value!="" || this.formFields.driverGergYear.value!="" ){
    var title="Do you want to disacard changes?";
    var text1="Disacard";
    var text2="Cancel";
    this.translate.get(title).subscribe(res => {
      title=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    swal({
      title:title,
      text: "",
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text2,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text1,
      closeOnConfirm: true
    },
    function(){
      window.history.go(-1);
    });
   }
   else{
    window.history.go(-1);
  
   }
  }
  
    
  }
    
  goToBackEdit()
  {
   var self=this;
  
   if(this.connectionStatus=="offline")
   {
     this.translate.get(this.connectionStatusMessage).subscribe(res => {
       $.notify({ message:res}, {type:"danger"});
     });
   }
   else{
    
  
  
     if(this.formFields.driverName.value!=this.Driver_Name || this.formFields.driverMobile.value!=this.Mobile_Number 
      || this.formFields.driverGergDay.value!=this.Gregorianday || this.formFields.driverHijjDay.value!=this.hijriday
      || this.formFields.driverGergMonth.value!=this.Gregorianmonth || this.formFields.driverHijjMonth.value!=this.hijrimonth
      || this.formFields.driverGergYear.value!=this.Gregorianyear || this.formFields.driverHijjYear.value!=this.hijriyear  ){
    
      var title="Do you want to disacard changes?";
    var text1="Disacard";
    var text2="Cancel";
    this.translate.get(title).subscribe(res => {
      title=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    swal({
      title:title,
      text: "",
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text2,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text1,
      closeOnConfirm: true
    },
    function(){
      window.history.go(-1);
    });
   }
   else{
    window.history.go(-1);
  
   }
  }
  
    
  }
  savePhoto(image)
{
  this.submittedImage=image;
    
}
  onFileSelected(file:FileList)
  {
    this.selectedFile=file.item(0);
   
    
    var reader=new FileReader();
    reader.onload=(event:any)=>{
     this.savePhoto(event.target.result);
     this.imageUrl=event.target.result;
    }
   
    reader.readAsDataURL(this.selectedFile);
   
    this.selectedFileSize=false;
    this.selectedFileType=false;
     console.log("imageTYpe"+this.selectedFile.type);
    if((this.selectedFile.size/1000)>1000)
    {
      this.selectedFileSize=true;
    }
    if((this.selectedFile.type!="image/png")&&(this.selectedFile.type!="image/jpg") &&(this.selectedFile.type!="image/jpeg"))
    {
      this.selectedFileType=true;
    }
    
  }
    
}
