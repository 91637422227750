import { Component,Output ,EventEmitter, OnInit, NgZone, ViewChild } from '@angular/core';
import { ApiService } from '../../network/api.service';
import { Geofence } from '../../shared/geofences';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as swal from '../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import { DataTableDirective } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

var self;
declare var $ :any;

@Component({
  selector: 'app-geofences',
  templateUrl: './geofences.component.html',
  styleUrls: ['./geofences.component.css']
})
export class GeofencesComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  Edit="Edit";
  Delete="Delete";
  geofence: Geofence;
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Geofence> = new Subject();
  editGeogence:[];
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  dir="";
  dirButton="";
  dirtable="";
  constructor(private local:LocalStorageService,public translate: TranslateService,private apiService: ApiService,private router: Router,private zone: NgZone) { }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
  ngOnInit() {
    this.getConnections();
    self = this;
   
      self.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        responsive: false,
        lengthChange: false,
        sort:false
      };
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
     
    }
    
    self.geofence = new Geofence();
    self.getAllGeofencesLastInfo();
  }
  
  getAllGeofencesLastInfo(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getAllGeofences().subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
          self.geofences = response["deta"];
          if(self.geofences.length==0){
            self.geofences=[];
          }
          else{
          self.dtTrigger.next();
          }

        }
      else {
          // show errors message
          console.log(response["error"]);
          console.log(response["message"]);
         
      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
  
  }
}
  goCreateGeofence(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['creategeofence']);
    }
   
  }

  goUpdateGeofence(geoId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['creategeofence'],{ queryParams: {geofence:geoId} ,skipLocationChange: false }));
    }
  }
  showDeleteAlert(geoId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    var title1="Are you sure you want to delete this geofence?";
    var text1="You will not be able to recover this record";
    var title2="Deleted!";
    var text2="Your geofence has been deleted.";
    var text3="Cancel";
    var text4="Remove";
    var text5="Ok";
    this.translate.get(title1).subscribe(res => {
      title1=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(title2).subscribe(res => {
      title2=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    this.translate.get(text3).subscribe(res => {
      text3=res;
    });
    this.translate.get(text4).subscribe(res => {
      text4=res;
    });
    this.translate.get(text5).subscribe(res => {
      text5=res;
    });
    swal({
      title: title1,
      text: text1,
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text3,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text4,
      closeOnConfirm: true
    },
    function(){
        console.log("test" + geoId);
        var id=geoId;
        // call delete service
        self.delete_geo(id);
        
       
    });
  }
    }
    delete_geo(gId){
      if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.apiService.deleteGeofence(gId).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            // state reload
            var title2="Deleted!";
            var text2="Your geofence has been deleted.";
            var text5="Ok";
            this.translate.get(title2).subscribe(res => {
              title2=res;
            });
            this.translate.get(text2).subscribe(res => {
              text2=res;
            });
            this.translate.get(text5).subscribe(res => {
              text5=res;
            });
            swal({
              title: title2,
              text: text2,
              type:'success',
              confirmButtonText: text5,
            });
            self.rerender();
          }
        else {
          /*swal({
            title: 'Try Again!',
            text:response["message"],
            type:'error'
           
          },
          function(){
              swal({
                title: 'Try Again!',
                text:'Try Again.',
                type:'error'
              });
          });*/
        }
      },
      error => {
          console.log("error" + error);
      });
    }
    }

    rerender(): void {
      self.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        // this.dtTrigger.next();
        self.getAllGeofencesLastInfo();
      });
    }
  }