import { Component, OnInit } from '@angular/core';
import { EventreportComponent } from '../eventreport/eventreport.component';
@Component({
  selector: 'app-eventreportloader',
  templateUrl: './eventreportloader.component.html',
  styleUrls: ['./eventreportloader.component.css']
})
export class EventreportloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
