import { Component, OnInit, Input, NgZone,AfterViewInit } from '@angular/core';
import { Device } from '../../../../shared/devices';
import { NgModule }      from '@angular/core';
import { ApiService } from '../../../../network/api.service';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { cleanSession } from 'selenium-webdriver/safari';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription,Subject} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import * as swal from '../../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import { isNumber } from 'util';

declare var jquery:any;
declare var $ :any;
var self
@Component({
  selector: 'app-caliberationTable',
  templateUrl: './caliberationTable.component.html',
  styleUrls: ['./caliberationTable.component.css']
})
export class CaliberationTableComponent implements OnInit,AfterViewInit {
    @Input() deviceId: number;
    
    public dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Device> = new Subject();
    tableData:any;
    editField:any;
    dir="";
    dirButton="";
    dirtable="";
    
    
    constructor(private apiService: ApiService,
        private router: Router ,
        private zone: NgZone,
        public translate: TranslateService,
        private local:LocalStorageService,private formBuilder: FormBuilder
        )
{   
}
    ngOnInit()
    {
        
        self=this;
        self.dtOptions = {
            responsive: false,
            sort:false,
            searching:false

            
          };
        this.tableData=[];  
        var currentLan = this.local.get('lan');
        currentLan = JSON.parse(currentLan);
        if(currentLan=='en'){
        this.dir="ltr";
        this.dirButton="rtl";
        }
        else if(currentLan=='ar'){
            this.dir="rtl";
            this.dirButton="ltr";
            this.dirtable="ltr";
        }
       /* for(let i=0;i<10;i++)
        {
            let data = {
                "sensor1": 0 ,
                "sensor2": 0,
                "weight": 0,
              };
            this.tableData.push(data);
        }*/
        this.getCaliberationData();  

    }
    ngAfterViewInit(){
     //  self.dtTrigger.next();
    }
    getCaliberationData()
    {
        console.log("deviceId"+this.deviceId);
        this.apiService.getCalibrationData(this.deviceId).subscribe((response) => {
            if (response["error"] == 0) {
                console.log("response"+response['data'].length);
                if(response['data'][0]!=null)
                {
                    console.log("data"+response['data']);
                    response['data'].forEach(element => {
                         let data = {
                            "sensor1": element.s1 ,
                            "sensor2": element.s2,
                            "weight": element.w,
                          };
                          this.tableData.push(data);
                    });
                    for(let i=this.tableData.length;i<10;i++)
                    {
                        let data = {
                            "sensor1": 0 ,
                            "sensor2": 0,
                            "weight": 0,
                        };
                        this.tableData.push(data);
                    }
                }else
                {
                    for(let i=this.tableData.length;i<10;i++)
                    {
                        let data = {
                            "sensor1": 0 ,
                            "sensor2": 0,
                            "weight": 0,
                        };
                        this.tableData.push(data);
                    }
                }
               
      
            }
            else{
                
              console.log("response"+response['message']);
            }      
        },
        error => {
            
            console.log("error" + error);
           
        });
    }
    
    saveTableData()
    { 
       
        var dataToSave=[];
        this.tableData.forEach(element => {
            
                let data={
                    "s1":Number(element.sensor1),
                    "s2":Number(element.sensor2),
                    "w":Number(element.weight)
                }
                dataToSave.push(data);
           
            
        }); 
        this.apiService.addCaliberationData(this.deviceId,dataToSave).subscribe((response) => {
            if (response["error"] == 0) {
               
                this.zone.run(() => this.router.navigate(['devices'],{ 
                    skipLocationChange: false 
                  }));
                 /* this.translate.get(response["message"]).subscribe(res => {
                    $.notify({ message:'Data saved successfully'}, {type: 'success'});
                  });*/
                    $.notify({ message:'Data saved successfully'}, {type:"success"});
                  
      
            }
            else{
                 
              console.log("response"+response['message']);
            }      
        },
        error => {
            
            console.log("error" + error);
           
        });
       

    }
    changeValue(id: number, property: string, event: any) {
        //this.editField = event.target.textContent;
        //console.log("index"+id+"data"+event.target.textContent+"pro"+property);
        console.log("event"+event.key);
        if(!((parseInt(event.key) > -1 && parseInt(event.key) <10 )|| event.keyCode==8 ||event.keyCode==37 || event.keyCode==38 || event.keyCode==39 || event.keyCode==40))
        {
            event.preventDefault();
        }
        // if(!((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105) || event.keyCode==8))
            //  event.preventDefault();
            // this.editField = event.target.textContent;
      }
      updateList(id: number, property: string, event: any) {
        const editField = event.target.textContent;
        this.tableData[id][property] = editField;
      }

}