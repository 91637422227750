import {Deserializable} from "../../shared/deserializable";

export class Dashboarddevicesstatusnumbers implements Deserializable {
    public online_devices: number;
    public unknown_devices: number;
    public offline_devices: number;
    public total_drivers: number;

    // constructor(public online_devices: number,
    //             public unknown_devices: number,
    //             public offline_devices: number,
    //             public total_drivers: number){

    // }
    constructor(){
        this.online_devices = 0;
        this.unknown_devices = 0;
        this.offline_devices = 0;
        this.total_drivers = 0;
    }
    public deserialize(input: any) {
        Object.assign(this, input);
        return this;
        
    }
}
