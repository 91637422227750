import { Component, OnInit, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../network/api.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigVariables } from '../../../../shared/config';
import { map } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { Device } from '../../../../shared/devices';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StopReportModel } from '../../../../shared/stopsreportmodel';
import {ExcelService} from '../../../../services/excel.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

var self;
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-stopreport',
  templateUrl: './stopreport.component.html',
  styleUrls: ['./stopreport.component.css']
})
export class StopreportComponent implements OnInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  submitted:boolean;
  devices: Device[];
  dateCheck:boolean;
  reportForm: FormGroup;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<StopReportModel> = new Subject();
  stopReportModelArray:StopReportModel[];
  selectedDevice:String;
  selectedStartDate:String;
  selectedEndDate:String;
  export:boolean;
  dir="";
  dirButton="";
  dirtable="";
  constructor(private apiService: ApiService,
    private router: Router ,
    private zone: NgZone ,
    private translate:TranslateService,
    private local:LocalStorageService,
    private  httpClient:  HttpClient,
    private formBuilder: FormBuilder,private excelService:ExcelService ) {
      this.dateCheck=true;
     }
     getConnections(){
      this.subscriptions=[];
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      this.subscriptions.push(this.onlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Back to online';
        this.connectionStatus = 'online';
        /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
          $.notify({ message:res}, {type:"success"});
        });
        location.reload();*/
        }));
  
      this.subscriptions.push(this.offlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
        this.connectionStatus = 'offline';
        /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
          $.notify({ message:res}, {type:"danger"});
        });*/
      }));
     
    }
  ngAfterViewInit(){
    this.getConnections();
    this.dtTrigger.next();
    $('.datepicker-only-init').datetimepicker({
      widgetPositioning: {
          horizontal: 'left'
      },
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down"
      },
      format: 'YYYY-MM-DD H:s'
    });  
  }
  ngOnInit() {
    this.getConnections();
   
    this.submitted = false;
    this.reportForm = this.formBuilder.group({
        deviceId: ['', [Validators.required]],
        dateFrom: ['', [Validators.required]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
        dateTo : ['', [Validators.required]],
    });
    this.getAllDevicesLastInfo();
    self = this;
    this.selectedDevice = "" ;
    this.selectedStartDate = "";
    this.selectedEndDate = "";
      self.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        responsive: false,
        lengthChange: false,
        serverSide : true,
        processing: true,
        searching: false,
        sort:false,
        oLanguage: {
          
          "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
  
        ajax: (dataTablesParameters: any, callback) => {
          if(this.selectedDevice == "" || this.selectedEndDate == "" || this.selectedStartDate == ""){
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
            self.stopReportModelArray = [];
            return;
          }
          this.dateCheck=true;
          if(this.checkDatesDiff(this.selectedStartDate,this.selectedEndDate)<=0)
          {
              this.dateCheck=false;
          }
          var currentUser = this.apiService.getUserToken();
          var headerss = new HttpHeaders({
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          });
  
          var body = {
            "selectedDevices": this.selectedDevice ,
            "startDate": this.selectedStartDate,
            "endDate": this.selectedEndDate,
            "offset": dataTablesParameters["start"],
          };
          if(this.connectionStatus=="offline")
      {
        this.translate.get(this.connectionStatusMessage).subscribe(res => {
          $.notify({ message:res}, {type:"danger"});
        });
      }
      else{
          this.httpClient.post(`${ConfigVariables.API_URL}/get_stop_report`,body,{headers:headerss })
            .subscribe(resp => {
              console.log( resp["data"]);
              self.stopReportModelArray = resp["data"];
              if(resp["data"].length>0)
              {
                  this.export=true;
              }
              else{
                this.export=false;
              }
              
              callback({
                recordsTotal: resp["reportLength"],
                recordsFiltered: resp["reportLength"],
                data: []
              });
            });
        }},
      };
   
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      var elem1 = document.getElementById('Select');
      //elem1.style.cssFloat="right";
      elem1.style.textAlign="right";
      
      
      var elem2 = document.getElementById('From');
      elem2.style.cssFloat="right";
      elem2.style.textAlign="right";

      var elem3 = document.getElementById('To');
      elem3.style.textAlign="right";
      elem3.style.cssFloat="right";


      var elem4 = document.getElementById('Submit');
      elem4.style.cssFloat="left";   
      self = this;

     /* var elem = document.getElementById('formSelect');
      elem.style.textAlign="right";*/
     
    }
   
  }
  displayToConsole(datatableElement: DataTableDirective): void {
    this.selectedDevice = this.formFields.deviceId.value;
    this.selectedStartDate = (<HTMLInputElement>document.getElementById("start_Date")).value;
    this.selectedEndDate = (<HTMLInputElement>document.getElementById("end_Date")).value;
    this.submitted = true;
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      console.log(dtInstance.ajax.reload);
    });
  }

  getAllDevicesLastInfo(){    
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.devices = response["data"];
          }
        else {
            // show errors message
            console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });}

  }
  get formFields() { return this.reportForm.controls; }
  checkDatesDiff(from,to)
 {
  var diffc = new Date(to).getTime() - new Date(from).getTime();
  return diffc;
 }
 viewLocationOnMap(latitude,longitude)
 {
  if(this.connectionStatus=="offline")
  {
    this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
  }
  else{
   this.initMap(latitude,longitude);
  }
 }
 initMap(latitude,longitude){ 
    
  var mapCenter = {lat: parseFloat(latitude), lng: parseFloat(longitude)};
  
   var map = new google.maps.Map(
       document.getElementById('map'), {zoom: 8, center: mapCenter});
       var marker = new google.maps.Marker({
        position:mapCenter,
        map: map
      });
                         
}
exportToExcel()
{
  if(this.connectionStatus=="offline")
  {
    this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
  }
  else{
  var data;
  var reportData:any;
  var currentLan = this.local.get('lan');
  currentLan = JSON.parse(currentLan);
  reportData={"reportName":"stopReport","selectedDevices":this.selectedDevice,"startDate":this.selectedStartDate,"endDate":this.selectedEndDate};
  this.apiService.exportToExcel(reportData).subscribe((response) => {
         if (response["error"] == 0) {
             console.log(response['data']);
           data = response["data"];
           if(currentLan=='ar'){
 
            for(var i=0;i<data.length;i++){
               data[i].خط_الطول= data[i].latitude;
                delete data[i].latitude;
                data[i].خط_العرض= data[i].longitude;
                delete data[i].longitude;
                data[i].التنبيه= data[i].alarm;
                delete data[i].alarm;
                if( data[i].التنبيه=='no'){
                  data[i].التنبيه='لا يوجد';
                }
                data[i].أسم_السائق= data[i].driverName;
                delete data[i].driverName;
                if( data[i].أسم_السائق=='Not assigned'){
                  data[i].أسم_السائق='لم يعيين بعد';
                }
                data[i].ساعات_المحرك= data[i].engineHours;
                delete data[i].engineHours;
                data[i].المدة_الزمنية= data[i].duration;
                delete data[i].duration;
                data[i].وقت_النهاية= data[i].endTime;
                delete data[i].endTime;
                data[i].وقت_ألبداية= data[i].startTime;
                delete data[i].startTime;
                data[i].أسم_المركبة= data[i].deviceName;
                delete data[i].deviceName;
               
               
               
               
               
               
                
              
               

            }
           }
           else{
 
            for(var i=0;i<data.length;i++){
                data[i].Vehicle_Name= data[i].deviceName;
                delete data[i].deviceName;
                data[i].Start_Time= data[i].startTime;
                delete data[i].startTime;
                data[i].End_Time= data[i].endTime;
                delete data[i].endTime;
                data[i].Duration= data[i].duration;
                delete data[i].duration;
                data[i].Engine_Hours= data[i].engineHours;
                delete data[i].engineHours;
                data[i].Driver_Name= data[i].driverName;
                delete data[i].driverName;
                data[i].Alarm= data[i].alarm;
                delete data[i].alarm;
                data[i].Latitude= data[i].latitude;
                delete data[i].latitude;
                data[i].Longitude= data[i].longitude;
                delete data[i].longitude;
              
            }
           }
           this.excelService.exportAsExcelFile(data,'stopReport');
           }
         else {
             // show errors message
             console.log(response["error"]);
         }
     },
     error => {
         // this.alertService.error(error);
         console.log("error" + error);
         // this.loading = false;
     });
}
}

}
