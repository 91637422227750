import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../network/api.service';
import { FormBuilder, FormGroup, Validators , ReactiveFormsModule } from '@angular/forms';
import { first } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { AppComponent } from '../../app.component';
declare var $ :any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  emailValid = false;
  success = true;
  languages="languages";
  passwordValid = false;
  self;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  constructor(  private translate: TranslateService,
                private session:SessionStorageService,
                private router: Router ,
                private zone: NgZone , 
                private apiService: ApiService,
                private local:LocalStorageService,
                private sessionStorage:SessionStorageService,
                private formBuilder: FormBuilder,
                private app:AppComponent) { 
                //console.log("jjj");
                  import('./loginJsScripts.js');
                  var currentLan = this.local.get('lan');
                  currentLan = JSON.parse(currentLan);
                  if(currentLan!=null){
                    this.switchLanguage(currentLan);
                  }
                  else{
                    this.switchLanguage('en');
              
                  }
                  
                  var currentUser = this.local.get('currentUser');
                  currentUser = JSON.parse(currentUser);
                  if(currentUser!=null){
                    this.app.isLoggedIn=true;
                   
                    
                      this.router.navigate(['main']); 
                    
                                     
                  }
                  else{
                    this.app.isLoggedIn=false;

                  }
                }

getConnections(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
  }
  ngOnInit() {
    this.getConnections();
    
      this.success = true;
      this.self = this;
      this.loginForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required]] //, Validators.minLength(6)]
          
      });
     
      
  }

  // convenience getter for easy access to form fields
  get formFields() { return this.loginForm.controls; }
  onSelect(){
    this.success = true;

  }
   onSubmit() {
    this.submitted = true;
    var email = this.formFields.email.value;
    var password = this.formFields.password.value;
    //email=this.b64_to_utf8(email);
    //password=this.b64_to_utf8(password);
    console.log("email"+email);
    
    if(!this.formFields.email.errors && !this.formFields.password.errors)
   {
         
           this.loginService(email,password);
        
          
       
         
   }    
    else{
        this.submitted = true;
    }
    // console.log("submit" +  email + " password = "+ password);
    
    // this.loading = true;
}

  // btnClick= function () {
      // call login service with username and password
      // this.loginService();
      // this.router.navigate(['main']);
      
    //   this.zone.run(() => {
    //     this.router.navigate(['/main']), { skipLocationChange: true }});
      
      // this.zone.run(() => this.router.navigate(['main']));
  // };

  private loginService(username: string, password: string){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.login(username,password).pipe(first()).subscribe((data) => {
        
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (data.error == 0) {
            this.success = true;
            //localStorage.setItem('currentUser', JSON.stringify(data));
            //expire at one day
            console.log(JSON.stringify(data));
          ///erorr logic [maryam]
          //  this.local.set('currentUser', JSON.stringify(data),1/24);
          this.local.set('currentUser', JSON.stringify(data));
          
          
            //console.log(this.local.get('currentUser'));
            //this.router.navigate(['main']);
           
            //this.app.isLoggedIn=true;
          
           
              window.location.href='main';
           
            //this.self.zone.run(() => this.self.router.navigate(['main']));
            // this.router.navigate(['main']);
        }
        else {
            // show errors message
            this.success = false;
        }

    },
    error => {
        // this.alertService.error(error);
        // console.log("error" + error);
        this.loading = false;
    });
  }
  }
  onClick(lan){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.languages=lan;
      if(lan=='AR'){
        this.switchLanguage('ar');
        
      }
      else if(lan=='EN'){
        this.switchLanguage('en');

      }
    }
  }
  switchLanguage(language: string) {
    console.log("lan: " + language);
    this.local.set('lan', JSON.stringify(language),1);
    this.translate.use(language);
  }
  
}
