import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgetpassword',
  templateUrl:'./forgetPassword.component.html' ,
  styleUrls: []
  
})
export class ForgetpasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
