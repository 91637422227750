import { Component, OnInit } from '@angular/core';
import { DeviceworkinghoursComponent } from '../deviceworkinghours/deviceworkinghours.component';
@Component({
  selector: 'app-deviceworkinghoursloader',
  templateUrl: './deviceworkinghoursloader.component.html',
  styleUrls: ['./deviceworkinghoursloader.component.css']
})
export class DeviceworkinghoursloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}