export class Driver {
    id: number;
    mobileNum: String;
    name: String;
    uniqueId: String;
    hijriday:string;
    hijrimonth:string;
    hijriyear:string;
    Gregorianday:string;
    Gregorianmonth:string;
    Gregorianyear:string;
    dateType: number;
    day:string;
    month:string;
    year:string;
    referenceKey:string;
    photo:string;
    constructor(){
        this.id = 0;
        this.mobileNum = "";
        this.name = "";
        this.uniqueId = "";
        this.hijriday = "";
        this.hijrimonth = "";
        this.hijriyear="";
        this.Gregorianday="";
        this.Gregorianmonth="";
        this.Gregorianyear="";
        this.dateType = 0;
        this.day="";
        this.month="";
        this.year="";
        this.referenceKey="";
        this.photo="";
    }

    public deserialize(input: any) {
        Object.assign(this, input);
        return this;   
    }
}
