import { Component, OnInit, AfterContentInit } from '@angular/core';
import { NavbarComponent } from '../navbar/navbar.component'; 
import * as $ from 'jquery';
import { AppComponent } from '../../app.component';

// import * as $script from '../../../assets/customscripts/homescripts';// '../../../assets/customscripts/homescripts.js';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'], 
})
export class MainComponent implements OnInit ,AfterContentInit  {

  constructor(private app:AppComponent) {
    // console.log("mainComponent");
    // import('./homescripts');
    this.app.isLoggedIn=true;
   }

  

  ngOnInit(){
    // COUNTERS
        
  }

  ngAfterContentInit(){
  }
}
