import { Component, OnInit, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { TripReportModel } from '../../../../shared/tripreportmodel';
import { ApiService } from '../../../../network/api.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigVariables } from '../../../../shared/config';
import { map } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { Device } from '../../../../shared/devices';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ExcelService} from '../../../../services/excel.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';

var self;
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-tripreport',
  templateUrl: './tripreport.component.html',
  styleUrls: ['./tripreport.component.css']
})
export class TripreportComponent implements AfterViewInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  submitted:boolean;
  devices: Device[];
  routePoints:any[];
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  reportForm: FormGroup;
  dateCheck:boolean;
  export:boolean;
  alldevices: Device[];
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<TripReportModel> = new Subject();
  tripReportModelArray:TripReportModel[];
  selectedDevice:String;
  selectedStartDate:String;
  selectedEndDate:String;
  dir="";
  dirButton="";
  dirtable="";
  constructor(private local:LocalStorageService,public translate: TranslateService,private apiService: ApiService,
    private router: Router ,
    private zone: NgZone ,
    private  httpClient:  HttpClient,
    private formBuilder: FormBuilder,private excelService:ExcelService ) { 
      this.dateCheck=true;
    }

  ngAfterViewInit(){
    this.getConnections();
    this.dtTrigger.next();
    $('.datepicker-only-init').datetimepicker({
      widgetPositioning: {
          horizontal: 'left'
      },
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down"
      },
      format: 'YYYY-MM-DD H:s'
    });  
  }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
  ngOnInit() {
    this.getConnections();
    this.submitted = false;
    
    this.reportForm = this.formBuilder.group({
        deviceId: ['', [Validators.required]],
        dateFrom: ['', [Validators.required]],
        dateTo : ['', [Validators.required]],
    });
    this.getAllDevicesLastInfo();
    self = this;
    self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: false,
      lengthChange: false,
      serverSide : true,
      processing: true,
      searching: false,
      sort:false,
     
      oLanguage: {
        
        "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
    },
 
      ajax: (dataTablesParameters: any, callback) => {
        if(this.selectedDevice == "" || this.selectedEndDate == "" || this.selectedStartDate == ""){
          
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
          self.tripReportModelArray = [];
          return;
        }
        this.dateCheck=true;
        if(this.checkDatesDiff(this.selectedStartDate,this.selectedEndDate)<=0)
        {
            this.dateCheck=false;
        }
         
        var currentUser = this.apiService.getUserToken();
       
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });

        var body = {
          "selectedDevices": this.selectedDevice ,
          "startDate": this.selectedStartDate,
          "endDate": this.selectedEndDate,
          "offset": dataTablesParameters["start"]
        };
        if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
        this.httpClient.post(`${ConfigVariables.API_URL}/get_trip_report`,body,{headers:headerss })
          .subscribe(resp => {
            console.log(resp["data"]);
            var tripreport=[];
            var report=[];
            tripreport = resp["data"];
            var remian=tripreport.length-Number(dataTablesParameters["start"]);
            if(remian<10)
            {
              var end=Number(dataTablesParameters["start"])+remian;
            }else
            {
              var end= Number(dataTablesParameters["start"])+10;
            }
            for(var i=dataTablesParameters["start"];i<end;i++)
            {
              report.push(tripreport[i]);
            }
            self.tripReportModelArray=report;
            /*self.tripReportModelArray.forEach(element => {
            
            });*/
            if(self.tripReportModelArray.length>0)
            {
                this.export=true;
            }
            else{
              this.export=false;
            }
            
            callback({
              recordsTotal: resp["reportLength"],
              recordsFiltered: resp["reportLength"],
              data: []
            });
          });
      }},
    
    };
     
    this.selectedDevice = "" ;
    this.selectedStartDate = "";
    this.selectedEndDate = "";
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      var elem1 = document.getElementById('Select');
      //elem1.style.cssFloat="right";
      elem1.style.textAlign="right";
      
      
      var elem2 = document.getElementById('From');
      elem2.style.cssFloat="right";
      elem2.style.textAlign="right";

      var elem3 = document.getElementById('To');
      elem3.style.textAlign="right";
      elem3.style.cssFloat="right";

      var elem4 = document.getElementById('Submit');
      elem4.style.cssFloat="left";      
      self = this;
      /*var elem = document.getElementById('formSelect');
      elem.style.textAlign="right";*/
     
    }
    
    
  }
  getReportData()
  {
    var currentUser = this.apiService.getUserToken();
       
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });

    var body = {
      "selectedDevices": this.selectedDevice ,
      "startDate": this.selectedStartDate,
      "endDate": this.selectedEndDate,
      "offset":0
    };
    this.httpClient.post(`${ConfigVariables.API_URL}/get_trip_report`,body,{headers:headerss })
          .subscribe(resp => {
            console.log(resp["data"]);
            var tripreport=[];
            var report=[];
            tripreport = resp["data"];
            if(tripreport.length<10)
            {
              var end=tripreport.length;
            }else
            {
              var end=10;
            }
            for(var i=0;i<end;i++)
            {
              report.push(tripreport[i]);
            }
            self.tripReportModelArray=report;
            /*self.tripReportModelArray.forEach(element => {
            
            });*/
            if(self.tripReportModelArray.length>0)
            {
                this.export=true;
            }
            else{
              this.export=false;
            }
            
            
          });
  }
  
  public displayToConsole(datatableElement: DataTableDirective): void {
    this.selectedDevice = this.formFields.deviceId.value;
    this.selectedStartDate = (<HTMLInputElement>document.getElementById("start_Date")).value;
    this.selectedEndDate = (<HTMLInputElement>document.getElementById("end_Date")).value;
    this.submitted = true;
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      //console.log(dtInstance.ajax.reload);
    });
  }

  getAllDevicesLastInfo(){ 
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{   
    this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.devices = response["data"];
          }
        else {
            // show errors message
            console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
  }
  }
  get formFields() { return this.reportForm.controls; }
  viewLocationOnMap(deviceId,startPositionId,endPositionId)
  {
    console.log("id"+deviceId);
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
     //console.log("deviceid"+deviceId+"startdate"+start_Date+"endDate"+end_Date);
     let tripData: any;
     tripData={"deviceId":deviceId,"startPositionId":startPositionId,"endPositionId":endPositionId };
     this.apiService.getTripPositions(tripData).subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
          self.routePoints=response['data'];
          this.initMap(self.routePoints);
         // self.devices = response["data"];
         // self.dtTrigger.next();

        }
      else {
          // show errors message
          console.log(response["error"]);
      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
}
     
  }
  initMap(positions){ 
    let waypts:any []=[]; 
       
   var mapCenter = {lat: parseFloat(positions[0].latitude), lng: parseFloat(positions[0].longitude)};
   var directionsService = new google.maps.DirectionsService;
   var directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
   var map = new google.maps.Map(
    document.getElementById('maptrip'), {zoom: 12, center: mapCenter});
    directionsDisplay.setMap(map);
   

  
    
    for(var i=0;i<positions.length;i++)
    {
     
         waypts.push({
            lat: parseFloat(positions[i].latitude), lng:parseFloat(positions[i].longitude)
         });
      
    }
   /* console.log("waypts"+waypts);
    var i=0;
    var length=waypts.length;
    if(length>0)
    {

      var start;
      var end;
      
      while(length>25)
      {
        let wayptsMiniRoute = []; 
        start=i;
        end=i+24;
        for (i=i+1;i<end;i++)
        {
                //  var point = new google.maps.LatLng(waypts[i].latitude,waypts[i].longitude);
             // waypts.push({location:point});
             wayptsMiniRoute.push({
                      location:{lat:parseFloat(waypts[i].lat), lng:parseFloat(waypts[i].lng)} ,
                       stopover: true
              });	
              
            
        }
        this.calculateAndDisplayRoute(directionsService, directionsDisplay,waypts[start].lat,waypts[start].lng,waypts[end].lat,waypts[end].lng,wayptsMiniRoute,map);
        length=length-24;

      }
      start=i;
      end=waypts.length-1;	
      let wayptsMiniRoute = [];
      for (i=i+1;i<end;i++)
        {
                  //var point = new google.maps.LatLng(lat[i], lng[i]);
             // waypts.push({location:point});
             wayptsMiniRoute.push({
                      location:{lat:parseFloat(waypts[i].lat), lng:parseFloat(waypts[i].lng)} ,
                       stopover: true
              });	
             
        }
        this.calculateAndDisplayRoute(directionsService, directionsDisplay,waypts[start].lat,waypts[start].lng,waypts[end].lat,waypts[end].lng, wayptsMiniRoute,map);
      
    }*/
    var lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    };
        var line = new google.maps.Polyline({
          path: waypts,
          icons: [{
            icon: lineSymbol,
            offset: '100%'
          }],
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 4,
          map: map
        });
                          
 }
/* calculateAndDisplayRoute(directionsService, directionsDisplay,startLat,startLan,endLat,endLan, wayptsMiniRoute,map)
 {    
      var lineSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        };
      directionsService.route({
        origin: new google.maps.LatLng(startLat,startLan),
        destination: new google.maps.LatLng(endLat,endLan),
        waypoints: wayptsMiniRoute,
        optimizeWaypoints: true,
        travelMode: 'DRIVING'
      }, function(response, status) {
        console.log("status"+status);
        if (status === 'OK') {
          
          directionsDisplay.setDirections(response);
          var poly_array=[];
                            for(var l=0;l<directionsDisplay.directions.routes[0].overview_path.length;l++)
                            {
                                var point=directionsDisplay.directions.routes[0].overview_path[l];
                                poly_array.push(new google.maps.LatLng(point.lat(),point.lng()));
                            }
          var poly = new google.maps.Polyline({
            path:poly_array,
            icons: [{
              icon: lineSymbol,
              offset: '100%'
            }],
            strokeColor: '#000000',
            strokeOpacity: 1.0,
            strokeWeight: 3
          });
          poly.setMap(map);
        } else {
        //  window.alert('Directions request failed due to ' + status);
        }
      });
     
 }*/
 checkDatesDiff(from,to)
 {
  var diffc = new Date(to).getTime() - new Date(from).getTime();
  return diffc;
 }
 exportToExcel()
 {
  if(this.connectionStatus=="offline")
  {
    this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
  }
  else{
   var data;
   var reportData:any;
   var currentLan = this.local.get('lan');
   currentLan = JSON.parse(currentLan);
   reportData={"reportName":"tripReport","selectedDevices":this.selectedDevice,"startDate":this.selectedStartDate,"endDate":this.selectedEndDate};
   this.apiService.exportToExcel(reportData).subscribe((response) => {
          if (response["error"] == 0) {
              console.log(response['data']);
            data = response["data"];
            if(currentLan=='ar'){ 
              for(var i=0;i<data.length;i++){
                data[i].أسم_السائق= data[i].driverName;
                  delete data[i].driverName;
                  if( data[i].أسم_السائق=='Not assigned'){
                    data[i].أسم_السائق='لم يعيين بعد';
                  }
                  data[i].المدة_الزمنية= data[i].duration;
                  delete data[i].duration;
                  data[i].أقصي_سرعة= data[i].maxSpeed;
                  delete data[i].maxSpeed;
                  data[i].متوسط_السرعة= data[i].averageSpeed;
                  delete data[i].averageSpeed;
                  data[i].المسافة= data[i].distance;
                  delete data[i].distance;
                  data[i].نهاية_خط_العرض= data[i].endLongitude;
                  delete data[i].endLongitude;
                  data[i].نهاية_خط_الطول= data[i].endLatitude;
                  delete data[i].endLatitude;
                  data[i].وقت_النهاية= data[i].endTime;
                  delete data[i].endTime;
                  data[i].بداية_خط_العرض= data[i].startLongitude;
                  delete data[i].startLongitude;
                  data[i].بداية_خط_الطول= data[i].startLatitude;
                  delete data[i].startLatitude;
                  data[i].وقت_البداية= data[i].startTime;
                  delete data[i].startTime;
                  data[i].اسم_المركبة= data[i].deviceName;
                  delete data[i].deviceName;
                

              }
            }
             
              else{ 
                for(var i=0;i<data.length;i++){
                    data[i].Vehicle_Name= data[i].deviceName;
                    delete data[i].deviceName;
                    data[i].Start_Time= data[i].startTime;
                    delete data[i].startTime;
                    data[i].Start_Latitude= data[i].startLatitude;
                    delete data[i].startLatitude;
                    data[i].Start_Longitude= data[i].startLongitude;
                    delete data[i].startLongitude;
                    data[i].End_Time= data[i].endTime;
                    delete data[i].endTime;
                    data[i].End_Latitude= data[i].endLatitude;
                    delete data[i].endLatitude;
                    data[i].End_Longitude= data[i].endLongitude;
                    delete data[i].endLongitude;
                    data[i].Distance= data[i].distance;
                    delete data[i].distance;
                    data[i].Average_Speed= data[i].averageSpeed;
                    delete data[i].averageSpeed;
                    data[i].Max_Speed= data[i].maxSpeed;
                    delete data[i].maxSpeed;
                    data[i].Duration= data[i].duration;
                    delete data[i].duration;
                    data[i].Driver_Name= data[i].driverName;
                    delete data[i].driverName;
                }
              }

                   
                
            

            this.excelService.exportAsExcelFile(data,'tripReport');
            }
          else {
              // show errors message
              console.log(response["error"]);
          }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
      });
 }
}
 
}
