import { DriversComponent } from '../drivers.component'

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-driversloader',
  templateUrl: './driversloader.component.html',
  styleUrls: ['./driversloader.component.css']
})
export class DriversListloaderComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}