import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Device } from '../../../../shared/devices';
import { NgModule }      from '@angular/core';
import { ApiService } from '../../../../network/api.service';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { cleanSession } from 'selenium-webdriver/safari';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import * as swal from '../../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'

declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-devicecreate',
  templateUrl: './devicecreate.component.html',
  styleUrls: ['./devicecreate.component.css']
})
export class DevicecreateComponent implements OnInit {

  @Input() deviceId: number;
  dir="";
  dirButton="";
  dirtable="";
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  device:Device;
  deviceForm: FormGroup;
  submitted:boolean;
  duplicationTypes:any;
  duplicateName:boolean;
  duplicateUniqueId:boolean;
  duplicateSequenceNumber:boolean;
  duplicatePlate:boolean;
  Select_Type="";
  Private_Car="";
  Public_Transport="";
  Private_Transport="";
  Public_Bus="";
  Private_Bus="";
  Taxi="";
  Heavy_Equipment="";
  Export="";
  Diplomatic="";
  Motorcycle="";
  Temporary="";
  Select_Left_Letter="";
  Select_Right_Letter="";
  Select_Middle_Letter="";
  Device_Name="";
  Sequence_Number="";
  Plate_Type="";
  Plate_Num="";
  Left_Letter="";
  Right_Letter="";
  Middle_Letter="";
  ownerName="";
  ownerId="";
  brand="";
  model="";
  carWeight="";
  madeYear="";
  color="";
  username="";
  hasReferenceKey:boolean;
  arrYear: Array<number>;
  colors;
  licenseExp=false;
  hijriday="";
  hijrimonth="";
  hijriyear="";
  Gregorianday="";
  Gregorianmonth="";
  Gregorianyear="";
  arrHijjYear: Array<number>;
  arrGregYear: Array<number>;
  gregorian:boolean;
  Muharram=""
  Safar=""
  Rab1="";
  Rab2="";
  Jum1="";
  Jum2="";
  Sha="";
  Ramadan="";
  Shawwal="";
  Dhu1="";
  Rajab=""
  Dhu2="";
  January="";
  February="";
  March="";
  April="";
  May="";
  June="";
  July="";
  August="";
  September="";
  October="";
  November="";
  December="";
  Year="";
  Day="";
  Month="";
  imageUrl:string;
  selectedFile:File;
  selectedFileSize:boolean;
  selectedFileType:boolean;
  submittedImage:string;
  

  constructor(private local:LocalStorageService ,public translate: TranslateService,private formBuilder: FormBuilder,private apiService: ApiService,private router: Router ,private routerActive :ActivatedRoute,
    private zone: NgZone) {
    this.submitted = false;
    this.device= new Device();
    this.routerActive.queryParams.subscribe(params => {
    this.deviceId = params["id"];
    this.duplicateName=false;
    this.duplicateUniqueId=false;
    this.duplicateSequenceNumber=false;
    this.duplicatePlate=false;
    this.imageUrl="http://31.204.150.201/sareb_backend/photos/vehicles/Not-available.jpg";
    this.selectedFile=null;
    this.selectedFileSize=false;
    this.selectedFileType=false;
    this.submittedImage=null;
    
  });
}
getConnections(){
  this.subscriptions=[];
  this.onlineEvent = fromEvent(window, 'online');
  this.offlineEvent = fromEvent(window, 'offline');
  this.subscriptions.push(this.onlineEvent.subscribe(e => {
    this.connectionStatusMessage = 'Back to online';
    this.connectionStatus = 'online';
    /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"success"});
    });
    location.reload();*/
    }));

  this.subscriptions.push(this.offlineEvent.subscribe(e => {
    this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
    this.connectionStatus = 'offline';
    /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });*/
  }));
 
}


  ngOnInit() {
    this.gregorian=true;
    this.hasReferenceKey=true;
    var element = document.getElementById("Gregorian_date");
              (<HTMLInputElement>element).checked = true;
    var currentLan = this.local.get('lan');
    var currentUser= this.local.get('currentUser');
    currentUser=JSON.parse(currentUser);
    
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      this.Select_Type="Select Type";
      this.Private_Car="Private Car";
      this.Public_Transport="Public Transport";
      this.Private_Transport="Private Transport";
      this.Public_Bus="Public Bus";
      this.Private_Bus="Private Bus";
      this.Taxi="Taxi";
      this.Heavy_Equipment="Heavy Equipment";
      this.Export="Export";
      this.Diplomatic="Diplomatic";
      this.Motorcycle="Motorcycle";
      this.Temporary="Temporary";
      this.Select_Left_Letter="Select Left Letter";
      this.Select_Middle_Letter="Select Middle Letter";
      this.Select_Right_Letter="Select Right Letter";
      this.Muharram="Muḥarram";
      this.Safar="Ṣafar";
      this.Rab1="Rabī‘ al-awwal";
      this.Rab2="Rabī‘ ath-thānī";
      this.Jum1="Jumādá al-ūlá";
      this.Jum2="Jumādá al-ākhirah";
      this.Sha="Sha‘bān";
      this.Ramadan="Ramaḍān";
      this.Shawwal="Shawwāl";
      this.Dhu1="Dhū al-Qa‘dah";
      this.Dhu2="Dhū al-Ḥijjah";
      this.Rajab="Rajab";
      this.Day="Day";
      this.Year="Year";
      this.Month="Month";
      this.January="January";
      this.February="February";
      this.March="March";
      this.April="April";
      this.May="May";
      this.June="June";
      this.July="July";
      this.August="August";
      this.September="September";
      this.October="October";
      this.November="November";
      this.December="December";
     
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      this.Select_Type="أختار نوع";
      this.Private_Car="سيارة خاصة";
      this.Public_Transport="موصلات عامة";
      this.Private_Transport="موصلات خاصة";
      this.Public_Bus="أتوبيس عام";
      this.Private_Bus="أتوبيس خاص";
      this.Taxi="سيارة أجرة";
      this.Heavy_Equipment="معدات ثقيلة";
      this.Export="تصدير";
      this.Diplomatic="دبلوماسي";
      this.Motorcycle="دراجة نارية";
      this.Temporary="مؤقت";
      this.Select_Left_Letter="أختار حرف أيسر";
      this.Select_Middle_Letter="أختار حرف أوسط";
      this.Select_Right_Letter="أختار حرف أيمن";
      this.Muharram="محرم";
      this.Safar="صفر";
      this.Rab1="ربيع الأول";
      this.Rab2="ربيع الثاني";
      this.Rajab="رجب";
      this.Jum1="جماد الأول";
      this.Jum2="جماد الثاني";
      this.Sha="شعبان";
      this.Ramadan="رمضان";
      this.Shawwal="شوال";
      this.Dhu1="ذو القعدة";
      this.Dhu2="ذو الحجة";
      this.Day="يوم";
      this.Month="شهر";
      this.Year="سنة";
      this.January="يناير";
      this.February="فبراير";
      this.March="مارس";
      this.April="أبريل";
      this.May="مايو";
      this.June="يونيو";
      this.July="يوليو";
      this.August="أغسطس";
      this.September="سبتمبر";
      this.October="أكتوبر";
      this.November="نوفمبر";
      this.December="ديسيمبر";
      var elem1 = document.getElementById('radio1');
      elem1.style.cssFloat="right";
      var elem2 = document.getElementById('radio2');
      elem2.style.cssFloat="right";


    }
    this.deviceForm = this.formBuilder.group({
      deviceName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
      deviceUniqueId: ['', [Validators.required,Validators.pattern,Validators.minLength]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
      deviceSequenceNumber : ['', [Validators.required,Validators.minLength,Validators.pattern,Validators.maxLength]],
      devicePlateType : ['', [Validators.required]],
      devicePlateNum : ['', [Validators.required,Validators.minLength,Validators.pattern]],
      deviceLeftLetter : ['', [Validators.required]],
      deviceMiddleLetter : ['', [Validators.required]],
      deviceRightLetter : ['', [Validators.required]],
      ownerName : [, [Validators.minLength,Validators.pattern]],
      ownerId: ['', [Validators.pattern,Validators.minLength]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
      userName : ['', [Validators.minLength,Validators.pattern]],
      brand : ['', [Validators.minLength,Validators.pattern]],
      model : ['', [Validators.minLength,Validators.pattern]],
      made_year : ['', []],
      carWeight:['', [,Validators.pattern,Validators.minLength]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
      color : ['', [Validators.pattern,Validators.minLength]],
      deviceHijjDay : ['', []],
      deviceHijjMonth : ['', []],
      deviceHijjYear : ['', []],
      deviceGergDay : ['', []],
      deviceGergMonth : ['', []],
      deviceGergYear : ['', []]

      
  });
  this.getConnections();
    if(this.deviceId)
    {
      let devicedata: any;
      devicedata={'deviceId':this.deviceId};
      if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.apiService.getdeviceInfo(devicedata).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            this.device = response["data"];
            this.Device_Name=response["data"].name;
            this.Sequence_Number=response["data"].sequenceNumber;
            this.Plate_Type=response["data"].plateType;
            this.Plate_Num=response["data"].plateNum;
            this.Left_Letter=response["data"].leftLetter;
            this.Right_Letter=response["data"].rightLetter;
            this.Middle_Letter=response["data"].middleLetter;
            this.device.referenceKey=response["data"].referenceKey;
            this.ownerName=response["data"].owner_name;
            this.ownerId=response["data"].owner_id;
            this.brand=response["data"].brand;
            this.model=response["data"].model;
            this.color=response["data"].color;
            this.carWeight=response["data"].carWeight;
            this.madeYear=response["data"].made_year;
            this.username=response["data"].username;
            if(response["data"].photo !=null && response["data"].photo!="")
            {
              this.imageUrl="http://31.204.150.201/sareb_backend/photos/vehicles/"+response["data"].photo;
            }
            else
            {
              this.imageUrl="http://31.204.150.201/sareb_backend/photos/vehicles/Not-available.jpg";
            }
            if(this.device.referenceKey!=null && this.device.referenceKey!="")
            {
              this.hasReferenceKey=false;
            }
            if(this.device.date_type==0)
            {
              
              var element = document.getElementById("Gregorian_date");
              (<HTMLInputElement>element).checked = true;
              this.device.Gregorianday=this.device.day;
              this.device.Gregorianmonth=this.device.month;
              this.device.Gregorianyear=this.device.year;
              this.gregorian=true;
              this.hijriday="";
              this.hijrimonth="";
              this.hijriyear="";
              this.Gregorianday=response["data"].Gregorianday;
              this.Gregorianmonth=response["data"].Gregorianmonth;
              this.Gregorianyear=response["data"].Gregorianyear;
              this.formFields.deviceHijjYear .setValue("");
              this.formFields.deviceHijjMonth.setValue("");
              this.formFields.deviceHijjDay.setValue("");
            }
            else
            {
              var element = document.getElementById("Hijri_date");
              (<HTMLInputElement>element).checked = true;
              this.device.hijriday=this.device.day;
              this.device.hijrimonth=this.device.month;
              this.device.hijriyear=this.device.year;
              this.gregorian=false;
              this.hijriday=response["data"].hijriday;
              this.hijrimonth=response["data"].hijrimonth;
              this.hijriyear=response["data"].hijriyear;
              this.Gregorianday="";
              this.Gregorianmonth="";
              this.Gregorianyear="";
              this.formFields.deviceGergDay.setValue("");
              this.formFields.deviceGergMonth.setValue("");
              this.formFields.deviceGergYear.setValue("");
             
            }
            
           // console.log("id"+currentUser['data']['id']);
            if(!this.hasReferenceKey && currentUser['data']['id'] !=53)
            {
              /*this.deviceForm.get('deviceUniqueId').disable();
              this.deviceForm.get('deviceName').disable();
              this.deviceForm.get('deviceSequenceNumber').disable();
              this.deviceForm.get('devicePlateType').disable();
              this.deviceForm.get('devicePlateNum').disable();
              this.deviceForm.get('deviceLeftLetter').disable();
              this.deviceForm.get('deviceMiddleLetter').disable();
              this.deviceForm.get('deviceRightLetter').disable();
              this.deviceForm.get('ownerName').disable();
              this.deviceForm.get('ownerId').disable();
              this.deviceForm.get('userName').disable();
              this.deviceForm.get('brand').disable();
              this.deviceForm.get('model').disable();
              this.deviceForm.get('made_year').disable();
              this.deviceForm.get('carWeight').disable();
              this.deviceForm.get('color').disable();
              this.deviceForm.get('deviceHijjDay').disable();
              this.deviceForm.get('deviceHijjMonth').disable();
              this.deviceForm.get('deviceHijjYear').disable();
              this.deviceForm.get('deviceGergDay').disable();
              this.deviceForm.get('deviceGergMonth').disable();
              this.deviceForm.get('deviceGergYear').disable();*/
            }

          }
       
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
      });
      
      
      
    }
  }
    
  }
   // convenience getter for easy access to form fields
   get formFields() { return this.deviceForm.controls; }
    findDuplicationInName(element) {
      return element == 1;
    }
    findDuplicationInUniqueid(element) {
      return element == 2;
    }
    findDuplicationInSequenceNumber(element) {
      return element == 3;
    }
    findDuplicationInPlate(element) {
      return element == 4;
    }
  CreateDevice()
  {
    this.submitted = true;
    this.duplicateName=false;
    this.duplicateUniqueId=false;
    this.duplicateSequenceNumber=false;
    this.duplicatePlate=false;
    var ownerNameValidationsError=true;
    var ownerIdValidationsError=true;
    var userNameValidationErrors=true;
    var brandValidationErrors=true;
    var modelValidationErrors=true;
    var carWeightValidationErrors=true;
    var colorValidationErrors=true;
    if(this.device.date_type==0)
    {
      this.device.day=this.device.Gregorianday;
      this.device.month=this.device.Gregorianmonth;
      this.device.year=this.device.Gregorianyear;
    }
    else if(this.device.date_type==1)
    {
      this.device.day=this.device.hijriday;
      this.device.month=this.device.hijrimonth;
      this.device.year=this.device.hijriyear;
    }
    if(this.submittedImage!=null)
    {
      this.device.photo=this.submittedImage;
    }
    else{
      this.device.photo="No_upload";
    }
    if(this.formFields.ownerName.value!="")
    {
      if(this.formFields.ownerName.errors)
      {
        ownerNameValidationsError=false;
      }
    }
    if(this.formFields.ownerId.value!="")
    {
      if(this.formFields.ownerId.errors)
      {
        ownerIdValidationsError=false;
      }
    }
    if(this.formFields.userName.value !="")
    {
      if(this.formFields.userName.errors)
      {
        userNameValidationErrors=false;
      }
    }
    if(this.formFields.brand.value!="")
    {
      if(this.formFields.brand.errors)
      {
        brandValidationErrors=false;
      }
    }
    if(this.formFields.model.value!="")
    {
      if(this.formFields.model.errors)
      {
        modelValidationErrors=false;
      }
    }
    if(this.formFields.carWeight.value !="")
    {
      if(this.formFields.carWeight.errors)
      {
        carWeightValidationErrors=false;
      }
    }
    if(this.formFields.color.value !="")
    {
      if(this.formFields.color.errors)
      {
        colorValidationErrors=false;
      }
    }
    console.log("this.device"+this.device);
    if(!this.formFields.deviceName.errors && !this.formFields.deviceUniqueId.errors && !this.formFields.deviceSequenceNumber.errors  && !this.formFields.devicePlateType.errors && !this.formFields.devicePlateNum.errors && !this.formFields.deviceLeftLetter.errors && !this.formFields.deviceMiddleLetter.errors && !this.formFields.deviceRightLetter.errors 
      && ownerNameValidationsError && ownerIdValidationsError  && userNameValidationErrors && brandValidationErrors
      && modelValidationErrors && carWeightValidationErrors && colorValidationErrors)
    {
      
      if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      if(this.device.date_type==0)
      {
       /* if(!this.formFields.deviceGergDay.errors && ! this.formFields.deviceGergMonth.errors && !this.formFields.deviceGergYear.errors)
        {*/
          this.apiService.addOrUpdatedevice(this.device).subscribe((response) => {
            if (response["error"] == 0) {
                console.log(response);
                this.device = response["data"];
                this.translate.get(response["message"]).subscribe(res => {
                  $.notify(res, {type: 'success'});
                });
                this.zone.run(() => this.router.navigate(['devices'],{ 
                  skipLocationChange: false 
                }));
               
      
              }
            else if(response['error']==1) {
              
              //$.notify({message: response["message"]}, {type: 'danger'});
               this.duplicationTypes=response['duplicate_code'];
              
             if(this.duplicationTypes.findIndex(this.findDuplicationInName)!=-1)
             {
               this.duplicateName=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInUniqueid)!=-1)
             {
               this.duplicateUniqueId=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInSequenceNumber)!=-1)
             {
               this.duplicateSequenceNumber=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInPlate)!=-1)
             {
              this.duplicatePlate=true;
             }
             
    
               
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
       
       // }
      }
      else
      {
       /* if(!this.formFields.deviceHijjDay.errors && ! this.formFields.deviceHijjMonth.errors && !this.formFields.deviceHijjYear.errors)
        {*/
          this.apiService.addOrUpdatedevice(this.device).subscribe((response) => {
            if (response["error"] == 0) {
                console.log(response);
                this.device = response["data"];
                this.translate.get(response["message"]).subscribe(res => {
                  $.notify(res, {type: 'success'});
                });
                this.zone.run(() => this.router.navigate(['devices'],{ 
                  skipLocationChange: false 
                }));
               
      
              }
            else if(response['error']==1) {
              
              //$.notify({message: response["message"]}, {type: 'danger'});
               this.duplicationTypes=response['duplicate_code'];
              
             if(this.duplicationTypes.findIndex(this.findDuplicationInName)!=-1)
             {
               this.duplicateName=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInUniqueid)!=-1)
             {
               this.duplicateUniqueId=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInSequenceNumber)!=-1)
             {
               this.duplicateSequenceNumber=true;
             }
             if(this.duplicationTypes.findIndex(this.findDuplicationInPlate)!=-1)
             {
              this.duplicatePlate=true;
             }
             
    
               
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
     // }
      }
      
    }
  }
    
  }
   /*ClearDevice()
   {
    this.deviceForm.reset();
    const plateType=this.deviceForm.get('devicePlateType');
    plateType.setValue("");
    const vch1=this.deviceForm.get('deviceLeftLetter');
    vch1.setValue("");
    const vch2=this.deviceForm.get('deviceMiddleLetter');
    vch2.setValue("");
    const vch3=this.deviceForm.get('deviceRightLetter');
    vch3.setValue("");
   }*/

   goToBackAdd()
   {
    var self=this;
   
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
     /*console.log(this.formFields.deviceName.value);
     console.log(this.formFields.deviceUniqueId.value);
     console.log(this.formFields.deviceSequenceNumber.value);
     console.log(this.formFields.devicePlateType.value);
     console.log(this.formFields.devicePlateNum.value);
     console.log(this.formFields.deviceLeftLetter.value);
     console.log(this.formFields.deviceMiddleLetter.value);
     console.log(this.formFields.deviceRightLetter.value);*/
     
     if(this.formFields.deviceName.value!="" || this.formFields.deviceUniqueId.value!=""
      || this.formFields.deviceSequenceNumber.value!=""
      || this.formFields.devicePlateType.value!=""  || this.formFields.devicePlateNum.value!=""
      || this.formFields.deviceLeftLetter.value!=""
      || this.formFields.deviceMiddleLetter.value!=""  || this.formFields.deviceRightLetter.value!="" 
      || this.formFields.ownerName.value!="" || this.formFields.ownerId.value!="" 
      || this.formFields.userName.value!="" || this.formFields.brand.value !="" 
      || this.formFields.model.value !="" || this.formFields.carWeight.value!="" 
      || this.formFields.color.value !="" || this.formFields.deviceHijjDay.value !=""
      || this.formFields.deviceHijjMonth.value !="" || this.formFields.deviceHijjYear.value !=""
      || this.formFields.deviceGergDay.value !="" || this.formFields.deviceGergMonth.value !=""
      || this.formFields.deviceGergYear.value !=""){
     var title="Do you want to disacard changes?";
     var text1="Disacard";
     var text2="Cancel";
     this.translate.get(title).subscribe(res => {
       title=res;
     });
     this.translate.get(text1).subscribe(res => {
       text1=res;
     });
     this.translate.get(text2).subscribe(res => {
       text2=res;
     });
     swal({
       title:title,
       text: "",
       type: "error",
       showCancelButton: true,
       cancelButtonClass: "btn-default",
       cancelButtonText: text2,
       confirmButtonClass: "btn-danger",
       confirmButtonText: text1,
       closeOnConfirm: true
     },
     function(){
       window.history.go(-1);
     });
    }
    else{
     window.history.go(-1);
   
    }
   }
   
     
   }
     
   goToBackEdit()
   {
    var self=this;
   
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
       /*  this.ownerName=response["data"].owner_name;
            this.ownerId=response["data"].owner_id;
            this.brand=response["data"].brand;
            this.model=response["data"].model;
            this.color=response["data"].color;
            this.carWeight=response["data"].carWeight;
            this.madeYear=response["data"].made_year;
            this.username=response["data"].username;*/ 
    if(this.formFields.deviceName.value!=this.Device_Name || this.formFields.deviceSequenceNumber.value!=this.Sequence_Number
       || this.formFields.devicePlateType.value!=this.Plate_Type
       || this.formFields.devicePlateNum.value!=this.Plate_Num
       || this.formFields.deviceLeftLetter.value!=this.Left_Letter
       || this.formFields.deviceMiddleLetter.value!=this.Middle_Letter 
       || this.formFields.deviceRightLetter.value!=this.Right_Letter 
       || this.formFields.deviceHijjDay.value!=this.hijriday
       || this.formFields.deviceHijjMonth.value !=this.hijrimonth
       || this.formFields.deviceHijjYear.value !=this.hijriyear
       || this.formFields.deviceGergDay.value !=this.Gregorianday
       || this.formFields.deviceGergMonth.value !=this.Gregorianmonth
       || this.formFields.deviceGergYear.value !=this.Gregorianyear
       || this.formFields.ownerName.value !=this.ownerName
       || this.formFields.ownerId.value !=this.ownerId
       || this.formFields.brand.value !=this.brand
       || this.formFields.model.value !=this.model
       || this.formFields.color.value !=this.color
       || this.formFields.carWeight.value !=this.carWeight
       || this.formFields.made_year.value !=this.madeYear
       || this.formFields.userName.value !=this.username
      ){
     var title="Do you want to disacard changes?";
     var text1="Disacard";
     var text2="Cancel";
     this.translate.get(title).subscribe(res => {
       title=res;
     });
     this.translate.get(text1).subscribe(res => {
       text1=res;
     });
     this.translate.get(text2).subscribe(res => {
       text2=res;
     });
     swal({
       title:title,
       text: "",
       type: "error",
       showCancelButton: true,
       cancelButtonClass: "btn-default",
       cancelButtonText: text2,
       confirmButtonClass: "btn-danger",
       confirmButtonText: text1,
       closeOnConfirm: true
     },
     function(){
       window.history.go(-1);
     });
    }
    else{
     window.history.go(-1);
   
    }
  }
   
     
   }
   setDateDislayStyle(dis){
    var display = "none";
    if(dis == true){
      display = "unset";
    }
    let styles = {
        'display':  display
    };
    return styles;
  }
  years(n: number): number[] {
    var arr = new Array();
    
    //1- to set made year
    if(n==1)
    {
      for(var i =new Date().getFullYear();i>= 1970 ;i --){
        arr.push(i);
      }
    }
    //2 for gregorian exp year 
    if(n==2)
    {
      for(var i =new Date().getFullYear();i<= new Date().getFullYear()+100 ;i ++){
        arr.push(i);
      }
    }
    //3 for hijri exp year 
    if(n==3)
    {
      let hjri=1440;
      for(var i =hjri;i<= hjri+100 ;i ++){
        arr.push(i);
      }
    }
    
    return arr;
  }
  /*setGregorianYearArray() {
    for(var i =new Date().getFullYear();i<=new Date().getFullYear() ;i ++){
      this.arrYear.push(i);
      this.arrGregYear.push(i);
    }
  }*/
  daysArray(n: number): number[] {
    var arr = new Array();
    for(var i =1;i< n+1 ;i ++){
      arr.push(i);
    }
    return arr;
  }
  changeDateType(event){
    console.log("gre"+this.gregorian);
    this.gregorian=!this.gregorian;
    if(this.gregorian==false)
    {
      this.device.date_type=1;
    }
    else if(this.gregorian==true)
    {
      this.device.date_type=0;
    }        
    console.log(this.device.date_type);
  }
  savePhoto(image)
{
  this.submittedImage=image;
    
}
  onFileSelected(file:FileList)
  {
    this.selectedFile=file.item(0);
   
    
    var reader=new FileReader();
    reader.onload=(event:any)=>{
     this.savePhoto(event.target.result);
     this.imageUrl=event.target.result;
    }
   
    reader.readAsDataURL(this.selectedFile);
   
    this.selectedFileSize=false;
    this.selectedFileType=false;
     console.log("imageTYpe"+this.selectedFile.type);
    if((this.selectedFile.size/1000)>1000)
    {
      this.selectedFileSize=true;
    }
    if((this.selectedFile.type!="image/png")&&(this.selectedFile.type!="image/jpg") &&(this.selectedFile.type!="image/jpeg"))
    {
      this.selectedFileType=true;
    }
    
  }
  
}
