import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-drivercreateloader',
  templateUrl: './drivercreateloader.component.html',
  styleUrls: ['./drivercreateloader.component.css']
})
export class DrivercreateloaderComponent implements OnInit {
  dir="";

  driverIdToEdit: number;
  constructor(private local:LocalStorageService,private router: Router,private route: ActivatedRoute) { 
    this.driverIdToEdit = 0;
  }

  ngOnInit( ) {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
    }
    else if(currentLan=='ar'){
      this.dir="rtl";

    }
    this.route.queryParams.subscribe(params => {
      this.driverIdToEdit = params['id'];
      // this.driverIdToEdit = Number.parseInt(params['id']);
    });
  }

}
