export class Device {
    name: string;
    deviceid: number;
    date: String;
    uniqueId:string;
    plateNum:string;
    rightLetter:string;
    middleLetter:string;
    leftLetter:string;
    plateType:string;
    sequenceNumber:string;
    initSensor:string;
    carWeight:Number;
    lastupdate: string;
    crash: boolean;
    batteryUnpluged: boolean;
    PowerUnpluged: boolean;
    power: number;
    latitude: string;
    longitude: string;
    speed: string;
    address: string;
    deviceMarker;
    color:string;
    weight:Number;
    referenceKey:string;
    owner_name:string;
    username:string;
    owner_id:string;
    brand:string;
    model:string;
    made_year:string;
    license_exp:string;
    date_type:number;
    hijriday:string;
    hijrimonth:string;
    hijriyear:string;
    Gregorianday:string;
    Gregorianmonth:string;
    Gregorianyear:string;
    day:string;
    month:string;
    year:string;
    deviceWorkingHoursPerDay:string;
    driverWorkingHoursPerDay:string;
    photo:string;
    driverName:String;

    constructor(){
        this.name = "";
        this.uniqueId="";
        this.plateNum="";
        this.rightLetter="";
        this.middleLetter="";
        this.leftLetter="";
        this.plateType="";
        this.sequenceNumber="";
        this.initSensor="";
        this.carWeight=0;
        this.deviceid = 0;
        this.lastupdate = "";
        this.crash = false;
        this.batteryUnpluged = false;
        this.PowerUnpluged = false;
        this.power = 0.0;
        this.latitude = "";
        this.longitude = "";
        this.speed = "";
        this.address = "";
        this.color = "";
        this.referenceKey="";
        this.weight=0;
        this.owner_name="";
        this.username="";
        this.owner_id="";
        this.brand="";
        this.model="";
        this.made_year="";
        this.license_exp="";
        this.date_type=0;
        this.hijriday="";
        this.hijrimonth="";
        this.hijriyear="";
        this.Gregorianday="";
        this.Gregorianmonth="";
        this.Gregorianyear="";
        this.day="";
        this.month="";
        this.year="";
        this.deviceWorkingHoursPerDay="";
        this.driverWorkingHoursPerDay="";
        this.date="";
        this.photo="";
        this.driverName="";
        
    }

    public deserialize(input: any) {
        Object.assign(this, input);
        return this;   
    }
}
