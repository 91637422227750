import { UsersComponent } from '../users.component'

import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-usersloader',
  templateUrl: './usersloader.component.html',
  styleUrls: ['./usersloader.component.css']
})
export class  UsersloaderComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}