import { Component, OnInit, NgZone, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../network/api.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigVariables } from '../../../../shared/config';
import { map } from 'rxjs/operators';
import { DataTableDirective } from 'angular-datatables';
import { Device } from '../../../../shared/devices';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {ExcelService} from '../../../../services/excel.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';

var self;
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-deviceworkinghours',
  templateUrl: './deviceworkinghours.component.html',
  styleUrls: ['./deviceworkinghours.component.css']
})

export class DeviceworkinghoursComponent implements AfterViewInit {

  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  submitted:boolean;
  devices: Device[];
  dir="";
  dirButton="";
  dirtable="";
  text=""
  dateCheck:boolean;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  reportForm: FormGroup;
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Device> = new Subject();
  DeviceWorkingHoursReport:Device[];
  selectedDevice:String;
  selectedStartDate:String;
  selectedEndDate:String;
  export:boolean;
  constructor(private apiService: ApiService,
    private router: Router ,
    private zone: NgZone ,
    public translate: TranslateService,
    private local:LocalStorageService,
    private  httpClient:  HttpClient,
    private formBuilder: FormBuilder ,private excelService:ExcelService) { 
      this.dateCheck=true;
    }

  ngAfterViewInit(){
    this.dtTrigger.next();
    $('.datepicker-only-init').datetimepicker({
      widgetPositioning: {
          horizontal: 'left'
      },
      icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down"
      },
      format: 'YYYY-MM-DD H:s'
    });  

     
   
  }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
  }
  ngOnInit() {
    this.getConnections();
    
    this.submitted = false;
    
    this.reportForm = this.formBuilder.group({
        deviceId: ['', [Validators.required]],
        dateFrom: ['', [Validators.required]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
        dateTo : ['', [Validators.required]],
    });
    this.getAllDevicesLastInfo();
    self = this;
    this.selectedDevice = "" ;
    this.selectedStartDate = "";
    this.selectedEndDate = "";
    self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: false,
      lengthChange: false,
      serverSide : true,
      processing: true,
      searching: false,
      sort:false,
      oLanguage: {
        
        "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
    },
      ajax: (dataTablesParameters: any, callback) => {
        
        if(this.selectedDevice == "" || this.selectedEndDate == "" || this.selectedStartDate == ""){
          
          callback({
            recordsTotal: 0,
            recordsFiltered: 0,
            data: []
          });
        
       
           
         // $('#example1').dataTable().clear().draw();
          //$('#example1').dataTable().empty();
          self.DeviceWorkingHoursReport = [];
          
          return;
        }
        this.dateCheck=true;
        if(this.checkDatesDiff(this.selectedStartDate,this.selectedEndDate)<=0)
        {
            this.dateCheck=false;
        }
        var currentUser = this.apiService.getUserToken();
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });

        var body = {
          "selectedDevices": this.selectedDevice ,
          "startDate": this.selectedStartDate,
          "endDate": this.selectedEndDate,
          "offset": dataTablesParameters["start"],
        };
        if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
        this.httpClient.post(`${ConfigVariables.API_URL}/get_total_working_hours_per_day`,body,{headers:headerss })
          .subscribe(resp => {
            console.log( resp["data"]);
            self.DeviceWorkingHoursReport = resp["data"];
            if(resp["data"].length>0)
            {
                this.export=true;
            }
            else{
              this.export=false;
            }
            
            callback({
              recordsTotal: resp["reportLength"],
              recordsFiltered: resp["reportLength"],
              data: []
            });
          });
      }}
      ,
    };
  var currentLan = this.local.get('lan');
  currentLan = JSON.parse(currentLan);
  if(currentLan=='en'){
    this.dir="ltr";
    this.dirButton="rtl";
   
  }
  else if(currentLan=='ar'){
    this.dir="rtl";
    this.dirButton="ltr";
    this.dirtable="ltr";
    
    var elem1 = document.getElementById('Select');
    //elem1.style.cssFloat="right";
    elem1.style.textAlign="right";
    
    
    var elem2 = document.getElementById('From');
    elem2.style.cssFloat="right";
    elem2.style.textAlign="right";

    var elem3 = document.getElementById('To');
    elem3.style.textAlign="right";
    elem3.style.cssFloat="right";


    var elem4 = document.getElementById('Submit');
    elem4.style.cssFloat="left";     
    /*var elem = document.getElementById('formSelect');
    elem.style.textAlign="right";*/
   
  }

   
  
  }
  displayToConsole(datatableElement: DataTableDirective): void {
    this.selectedDevice = this.formFields.deviceId.value;
    this.selectedStartDate = (<HTMLInputElement>document.getElementById("start_Date")).value;
    this.selectedEndDate = (<HTMLInputElement>document.getElementById("end_Date")).value;
    this.submitted = true;
    datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      console.log(dtInstance.ajax.reload);
    });
  }

  getAllDevicesLastInfo(){    
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.devices = response["data"];
            
          }
        else {
            // show errors message
            console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
  }
  }
  get formFields() { return this.reportForm.controls; }
  
 checkDatesDiff(from,to)
 {
  var diffc = new Date(to).getTime() - new Date(from).getTime();
  return diffc;
 }
 exportToExcel()
 {
  if(this.connectionStatus=="offline")
  {
    this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
  }
  else{
   var data;
   var reportData:any;
   var currentLan = this.local.get('lan');
   currentLan = JSON.parse(currentLan);
   reportData={"reportName":"deviceworkinghours","selectedDevices":this.selectedDevice,"startDate":this.selectedStartDate,"endDate":this.selectedEndDate};
   this.apiService.exportToExcel(reportData).subscribe((response) => {
          if (response["error"] == 0) {
              console.log(response['data']);
            data = response["data"];

            if(currentLan=='ar'){ 
              for(var i=0;i<data.length;i++){
                 
                data[i].عدد_ساعات_عمل_المركبة= data[i].deviceWorkingHoursPerDay;
                delete data[i].deviceWorkingHoursPerDay;
               
                  data[i].التاريخ= data[i].date;
                  delete data[i].date;
                  
                 
                  data[i].اسم_المركبة= data[i].name;
                  delete data[i].name;			
              }
            }
            else{ 
              for(var i=0;i<data.length;i++){
                  
                  data[i].Vehicle_Name= data[i].name;
                  delete data[i].name;
                 
                  data[i].Date= data[i].date;
                  delete data[i].date;
                 
                  data[i].VehicleWorkingHoursPerDay= data[i].deviceWorkingHoursPerDay;
                  delete data[i].deviceWorkingHoursPerDay;
                  
                  									

              }
            }
            this.excelService.exportAsExcelFile(data,'Vehicle working hours');
            }
          else {
              // show errors message
              console.log(response["error"]);
          }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
      });
 }
}
}
