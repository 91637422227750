import { Component, OnInit } from '@angular/core';
import {UsercreateComponent} from '../usercreate/usercreate.component';
import { Router,ActivatedRoute } from '@angular/router';
import { SidebarComponent } from '../../../sidebar/sidebar.component';
import { NavbarComponent } from '../../../navbar/navbar.component';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-usercreateloader',
  templateUrl: './usercreateloader.component.html',
  styleUrls: ['./usercreateloader.component.css'],
})
export class UsercreateloaderComponent implements OnInit {

  userIdToEdit:number;
  dir="";
  constructor(private local:LocalStorageService,private router: Router,private route: ActivatedRoute ) {
    this.userIdToEdit = 0;

  }
  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
    }
    else if(currentLan=='ar'){
      this.dir="rtl";

    }
    this.route.queryParams.subscribe(params => {
      this.userIdToEdit = Number.parseInt(params['id']);
    });
  }

}
