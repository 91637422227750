import { Component, OnInit } from '@angular/core';
import {ProfileComponent} from '../profile.component';
import { Router,ActivatedRoute } from '@angular/router';
import { SidebarComponent } from '../../sidebar/sidebar.component';
import { NavbarComponent } from '../../navbar/navbar.component';
@Component({
  selector: 'app-profileloader',
  templateUrl: './profileloader.component.html',
  styleUrls: ['./profileloader.component.css'],
})
export class ProfileloaderComponent implements OnInit {

  userIdToEdit:number;
  constructor(private router: Router,private route: ActivatedRoute ) {
    this.userIdToEdit = 0;

  }
  ngOnInit() {
    
  /*  this.route.queryParams.subscribe(params => {
    this.userIdToEdit = Number.parseInt(params['id']);
    });*/
  }

}