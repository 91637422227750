import { Component, OnInit } from '@angular/core';
import { StopreportComponent } from '../stopreport/stopreport.component';
@Component({
  selector: 'app-stopreportloader',
  templateUrl: './stopreportloader.component.html',
  styleUrls: ['./stopreportloader.component.css']
})
export class StopreportloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
