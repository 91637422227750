import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './components/main/main.component'
import { LoginComponent } from './components/login/login.component'
import { AuthGuard } from './security/AuthGuard';
import { UsersloaderComponent } from './components/users/usersloader/usersloader.component';
import { UsercreateloaderComponent } from './components/users/usercreate/usercreateloader/usercreateloader.component';
import { DevicesloaderComponent} from './components/devices/devicesloader/devicesloader.component'; 
import { DriversListloaderComponent } from './components/drivers/driversloader/driversloader.component';
import { DrivercreateloaderComponent } from './components/drivers/driverscreate/drivercreateloader/drivercreateloader.component';
import { DevicecreateloaderComponent } from './components/devices/devicecreate/devicecreateloader/devicecreateloader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TripreportloaderComponent } from './components/reports/trips/tripreportloader/tripreportloader.component';
import { StopreportloaderComponent } from './components/reports/stops/stopreportloader/stopreportloader.component';
import { EventreportloaderComponent } from './components/reports/events/eventreportloader/eventreportloader.component';
import { GeofencesloaderComponent } from './components/geofences/geofencesloader/geofencesloader.component';
import { GeofencecreateloaderComponent } from './components/geofences/geofencescreate/geofencecreateloader/geofencecreateloader.component';
import { UsersComponent } from './components/users/users.component';
import { ProfileloaderComponent } from './components/profile/profileloader/profileloader.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ForgetpasswordComponent } from './components/forgetPassword/forgetPassword.component';
import {CaliberationTableloaderComponent} from './components/devices/caliberationTable/caliberationTableloader/caliberationTableloader.component'
import { DeviceworkinghoursloaderComponent} from './components/reports/deviceworkinghours/deviceworkinghoursloader/deviceworkinghoursloader.component'
import { DriverworkinghoursloaderComponent} from './components/reports/driverworkinghours/driverworkinghoursloader/driverworkinghoursloader.component'
import { ElmconnectionsloaderComponent} from './components/elmconnections/elmconnectionsloader/elmconnectionsloader.component'

const routes: Routes = [
  { path: 'login', component: LoginComponent}, 
  
  {
     path: 'main', 
     component: MainComponent, 
     canActivate: [AuthGuard] 
  },
  {
    path: 'devices', 
    component: DevicesloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'drivers', 
    component: DriversListloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'createdriver', 
    component: DrivercreateloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'createdevice', 
    component: DevicecreateloaderComponent, 
    canActivate: [AuthGuard],
     
  },
  {
    path: 'tripreport', 
    component: TripreportloaderComponent, 
    canActivate: [AuthGuard],
     
  },
  {
    path: 'stopreport', 
    component: StopreportloaderComponent, 
    canActivate: [AuthGuard],
     
  },
  {
    path: 'eventreport', 
    component: EventreportloaderComponent, 
    canActivate: [AuthGuard],
     
  },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'users',
    component: UsersloaderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createuser', 
    component: UsercreateloaderComponent, 
    canActivate: [AuthGuard]
  },
  { path: 'users',component: UsersComponent},
  {
    path: 'geofences', 
    component: GeofencesloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'creategeofence', 
    component: GeofencecreateloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'profile', 
    component: ProfileloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'caliberationTable', 
    component: CaliberationTableloaderComponent, 
    canActivate: [AuthGuard]
  },
  {
    path: 'forgetPassword', 
    component:ForgetpasswordComponent
     
 },
 {
  path: 'deviceworkinghours', 
  component:DeviceworkinghoursloaderComponent
   
},
{
  path: 'driverworkinghours', 
  component:DriverworkinghoursloaderComponent
   
}
,
{
  path: 'elmconnections', 
  component:ElmconnectionsloaderComponent
   
}


  // { path: '**', component: PageNotFoundComponent }
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
                                { enableTracing: true,
                                  onSameUrlNavigation: 'reload' } // <-- debugging purposes only
  ),CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
