import { OnDestroy,Component, OnInit } from '@angular/core';
import { ApiService } from '../../network/api.service';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { TRANSLATIONS } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent,EMPTY , Observable} from 'rxjs';
import 'rxjs/add/operator/first';
import { Subscription } from 'rxjs/Subscription';
//npm i ng-connection-service
import { ConnectionService } from 'ng-connection-service';


declare var $ :any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  languages="languages";
  imageUrl:string;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus="";
  
  constructor(private translate: TranslateService,private apiService: ApiService,private router: Router,private session:SessionStorageService,private local:LocalStorageService) { 
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan!=null){
      translate.setDefaultLang(currentLan);
    }
    else{
      translate.setDefaultLang('en');
    }


  }
 
  getConnections(){
    this.onlineEvent=null;
    this.offlineEvent=null;
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    
    this.subscriptions.push(this.onlineEvent.first().subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';

      /*this.translate.get(this.connectionStatusMessage).first().subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();
      */

      }));

      this.subscriptions.push(this.offlineEvent.first().subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).first().subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
      
    }));

    
   
       
  }
 
  ngOnInit() {
    this.imageUrl="";
    this.getConnections();
    this.LoadUserPhoto();
    //console.log("connectionStatus: "+this.connectionStatus);

   
  }
  
  LoadUserPhoto()
  {
    if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
    this.apiService.getProfileInfo().subscribe((response) => {
      if (response["error"] == 0) {
        console.log("nav"+response['data'].photo);
        var data=response['data'];
        if(data.photo==null){
          data.photo="Not-available.jpg";
        }
        this.imageUrl="http://31.204.150.201/sareb_backend/photos/"+data.photo;
        
    }
  else {
      // show errors message
      console.log(response["error"]);
      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
});
        }
  }
  logoutapp(){
    if(this.connectionStatus=="offline")
    {
      console.log("connectionStatus: "+this.connectionStatus);
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
else{   
    this.apiService.logout().subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
          this.local.remove('currentUser');
          console.log("Token : "+this.local.get('currentUser'));
          
          this.router.navigate(['login']);

        
      }
      else {
        $.notify({message: response["message"]}, {type: 'danger'});

       
      }
    },
    error => {
        console.log("error" + error);
    });
  }
  }
  goToProfile()
  {
    if(this.connectionStatus=="offline")
          {
            this.translate.get(this.connectionStatusMessage).subscribe(res => {
              $.notify({ message:res}, {type:"danger"});
            });
          }
    else{    
    this.router.navigate(['profile']);
    }
  }
  onClick(lan){
    this.languages=lan;
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
else{   
    if(lan=='AR'){
      this.switchLanguage('ar');
     
    }
    else if(lan=='EN'){
      this.switchLanguage('en');

    }
  }
  }
  
  switchLanguage(language: string) {
    console.log("lan: " + language);
    this.local.set('lan', JSON.stringify(language));
    location.reload();
    //this.translate.use(language);
  }
  


}
