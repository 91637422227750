import { Component, OnInit, Input, NgZone } from '@angular/core';
import { User } from '../../../../shared/users';
import { NgModule }      from '@angular/core';
import { ApiService } from '../../../../network/api.service';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import * as swal from '../../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import { BlockUI, NgBlockUI } from 'ng-block-ui';

declare function require(path: string): any;

declare var jquery:any;
declare var $ :any;
var currentLan;
var moment = require('moment-hijri');       

@Component({
  selector: 'app-usercreate',
  templateUrl: './usercreate.component.html',
  styleUrls: ['./usercreate.component.css'],
  
})
export class UsercreateComponent implements OnInit {
  days: Array<number>=[];
  years: Array<number>=[];
  user:User;
  @BlockUI() blockUI: NgBlockUI;
  fields=[];
  userForm: FormGroup;
  submitted:boolean;
  dublicatedMail:boolean;
  dublicatedId:boolean;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  dublicatedComartial:boolean;
  dublicatedComPhone:boolean;
  dublicatedManagerPhone:boolean;
  dublicatedManagerMobile:boolean;
  dublicatedExtentionPhone:boolean;
  errorDate:boolean;
  Username="";
  Identity_Number="";
  Company_Phone="";
  Manager_Name="";
  Manager_Phone="";
  Manager_Mobile="";
  Extension_Number="";
  dir="";
  dirButton="";
  dirtable="";
  Month="";
  Day="";
  Year="";
  Muharram=""
  Safar=""
  Rab1="";
  Rab2="";
  Jum1="";
  Jum2="";
  Sha="";
  Ramadan="";
  Shawwal="";
  Dhu1="";
  Rajab=""
  Dhu2="";
  lan=false;
  hasRefrenceKey:boolean;
  @Input() userId: number;
  //@Input() name:string;
  confirmPassword:string;
  passwordMatching:boolean;
  Iscompany:number;
  constructor(public translate: TranslateService,private apiService: ApiService,private router: Router ,private routerActive :ActivatedRoute,
    private zone: NgZone,private formBuilder: FormBuilder,private local:LocalStorageService    ) {
    this.user = new User();
    this.submitted = false;
    this.routerActive.queryParams.subscribe(params => {
    this.userId = params["id"];
    this.passwordMatching=false; 
    this.Iscompany=1;

  });
  /*this.blockUI.start('Loading...'); // Start blocking
 
  setTimeout(() => {
    this.blockUI.stop(); // Stop blocking
  }, 10000);
   */
     
   }
   getConnections(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
  }
 
  ngOnInit() {
    var element = document.getElementById("company");
    (<HTMLInputElement>element).checked = true;
    this.hasRefrenceKey=false;
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    this.Iscompany=1;
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      this.lan=true;
      this.Month="Month";
      this.Muharram="Muḥarram";
      this.Safar="Ṣafar";
      this.Rab1="Rabī‘ al-awwal";
      this.Rab2="Rabī‘ ath-thānī";
      this.Jum1="Jumādá al-ūlá";
      this.Jum2="Jumādá al-ākhirah";
      this.Sha="Sha‘bān";
      this.Ramadan="Ramaḍān";
      this.Shawwal="Shawwāl";
      this.Dhu1="Dhū al-Qa‘dah";
      this.Dhu2="Dhū al-Ḥijjah";
      this.Rajab="Rajab";
      this.Day="Day";
      this.Year="Year";
     
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      this.lan=false;
      this.Month="شهر";
      this.Muharram="محرم";
      this.Safar="صفر";
      this.Rab1="ربيع الأول";
      this.Rab2="ربيع الثاني";
      this.Rajab="رجب";
      this.Jum1="جماد الأول";
      this.Jum2="جماد الثاني";
      this.Sha="شعبان";
      this.Ramadan="رمضان";
      this.Shawwal="شوال";
      this.Dhu1="ذو القعدة";
      this.Dhu2="ذو الحجة";
      this.Day="يوم";
      this.Year="سنة";
      var elem1 = document.getElementById('daydir');
      elem1.style.cssFloat="right";
      var elem2 = document.getElementById('monthdir');
      elem2.style.cssFloat="right";
      var elem3 = document.getElementById('yeardir');
      elem3.style.cssFloat="right";

    }
    this.getConnections();
    this.userForm = this.formBuilder.group({
      userName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
      userEmail: ['',[Validators.required,Validators.email]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
      userPassword : ['', [Validators.required]],
      userIdentityNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
      userCommercialNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
      userCompanyPhone : ['', [Validators.required,Validators.pattern,,Validators.minLength]],
      userRegDay : ['', [Validators.required]],
      userRegMonth : ['', [Validators.required]],
      userRegYear : ['', [Validators.required]],
      userManagerName : ['', [Validators.required,Validators.pattern,Validators.minLength]],
      userManagerPhone : ['', [Validators.required,Validators.pattern,Validators.minLength]],
      userManagerMobile : ['', [Validators.required,Validators.pattern,Validators.minLength]],
      userExtinsionNumber : ['', [Validators.pattern,Validators.minLength]],
      confirmPassword:['',[Validators.required]]
     
     });
    
    if(this.userId)
    {
      let userdata: any;
      userdata={'userId':this.userId};
      if(this.connectionStatus=="offline")
      {
        this.translate.get(this.connectionStatusMessage).subscribe(res => {
          $.notify({ message:res}, {type:"danger"});
        });
      }
    else{
      this.apiService.getUserInfo(userdata).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            this.user = response["data"];
            this.user.identityNum=response["data"].identityNum;
            this.user.commercialNum=response["data"].commercialNum;
            this.Username=response["data"].name;
            this.user.companyPhone=response["data"].companyPhone;
            this.Identity_Number=response["data"].identityNum;
            this.Company_Phone=response["data"].companyPhone;
            this.Manager_Name=response["data"].managerName;
            this.Manager_Phone=response["data"].managerPhone;
            this.Manager_Mobile=response["data"].managerMobile;
            this.Extension_Number=response["data"].phone;
            this.user.referenceKey=response["data"].reference_key;
            if(this.user.IsCompany==1)
            {
              var element = document.getElementById("company");
              (<HTMLInputElement>element).checked = true; 
              this.Iscompany=1;
              //this.formValidations(true);
            }
            else if(this.user.IsCompany==0)
            {
              var element = document.getElementById("individula");
              (<HTMLInputElement>element).checked = true; 
              this.Iscompany=0;
              //this.formValidations(false);
            }
            else if(this.user.IsCompany==2)
            {
              var element = document.getElementById("test");
              (<HTMLInputElement>element).checked = true; 
              this.Iscompany=2;
            }
            this.userForm.get('userPassword').disable();
            this.userForm.get('confirmPassword').disable();
            if(this.user.referenceKey!=null && this.user.referenceKey!="" )
            {
              this.hasRefrenceKey=true;
            }
           
            if(this.hasRefrenceKey)
            {
              
              this.userForm.get('userCommercialNumber').disable();
              this.userForm.get('userRegDay').disable();
              this.userForm.get('userRegMonth').disable();
              this.userForm.get('userRegYear').disable();
              this.userForm.get('userEmail').disable();
              this.userForm.get('userName').disable();
              this.userForm.get('userIdentityNumber').disable();
              this.userForm.get('userCompanyPhone').disable();
              this.userForm.get('userManagerName').disable();
              this.userForm.get('userManagerPhone').disable();
              this.userForm.get('userManagerMobile').disable();
              this.userForm.get('userExtinsionNumber').disable();
              
              
            }
           

          }
        else {
            // show errors message
            console.log(response["error"]);
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
      });
     

      

     
    }
  }
  else{
   // this.formValidations(this.Iscompany);
  }
    for(let i=1;i<31;i++)
    {
      this.days.push(i);
    }
    for(let i=1440;i>1339;i--)
    {
      this.years.push(i);
    }
    
    

    
  }
  get formFields() { return this.userForm.controls; }
  checkPasswordMatching()
  {
    //console.log("password"+this.confirmPassword);
    if(this.confirmPassword!=this.user.password)
    {
      this.passwordMatching=true;
    }
    
  }
  formValidations(IscompanyCheck)
  {
    if(IscompanyCheck)
    {
      console.log("check"+IscompanyCheck);
      this.userForm = this.formBuilder.group({
        userName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
        userEmail: ['',[Validators.required,Validators.email]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
        userPassword : ['', [Validators.required]],
        userIdentityNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userCommercialNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userCompanyPhone : ['', [Validators.required,Validators.pattern,,Validators.minLength]],
        userRegDay : ['', [Validators.required]],
        userRegMonth : ['', [Validators.required]],
        userRegYear : ['', [Validators.required]],
        userManagerName : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userManagerPhone : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userManagerMobile : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userExtinsionNumber : ['', [Validators.pattern,Validators.minLength]],
        confirmPassword:['',[Validators.required]]
       
       });
    }
    else{
      console.log("check"+IscompanyCheck);
      this.userForm = this.formBuilder.group({
        userName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
        userEmail: ['',[Validators.required,Validators.email]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
        userPassword : ['', [Validators.required]],
        userIdentityNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userCommercialNumber : ['', [Validators.pattern,Validators.minLength]],
        userCompanyPhone : ['', [Validators.pattern,,Validators.minLength]],
        userRegDay : ['', []],
        userRegMonth : ['', []],
        userRegYear : ['', []],
        userManagerName : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userManagerPhone : ['', [Validators.required,Validators.pattern,Validators.minLength]],
        userManagerMobile : ['', [Validators.pattern,Validators.minLength]],
        userExtinsionNumber : ['', [Validators.pattern,Validators.minLength]],
        confirmPassword:['',[Validators.required]]
       
       }); 
      
    }
    
  }
  CreateUser()
  {
    //  console.log("user"+this.user.day);
      this.submitted = true;
      this.dublicatedMail=false;
      this.dublicatedId=false;
      this.dublicatedComartial=false;
      this.dublicatedComPhone=false;
      this.dublicatedManagerPhone=false;
      this.dublicatedManagerMobile=false;
      this.dublicatedManagerPhone=false;
      this.passwordMatching=false;
      var commercialNumberValidationError=false;
      var regDayValidationError=false;
      var regMonthValidationError=false;
      var regYearValidationError=false;
      var managerMobileValidationError=false;
      this.user.IsCompany=this.Iscompany;
     
      /*console.log(this.userForm.get('userRegDay').value);
      console.log(this.userForm.get('userRegMonth').value);
      console.log(this.userForm.get('userRegYear').value);*/
      var day=this.userForm.get('userRegDay').value;
      if(day<9){
        day="0"+day;
      }
      
      var month=this.userForm.get('userRegMonth').value;
      if(month<9){
        month="0"+month;
      }
      var year=this.userForm.get('userRegYear').value;
      var date=day+'/'+month+'/'+year;
      //console.log(date);
      
      var currDay =moment().format('iDD');
      var currMonth =moment().format('iMM');
      var currYear =moment().format('iYYYY');
      
      var oldDay=moment().format(day,'iDD');
      var oldMonth=moment().format(month,'iMM');
      var oldYear=moment().format(year,'iYYYY');

      console.log("currDay "+currDay);
      console.log("currMonth "+currMonth);
      console.log("currYear "+currYear);
      console.log("oldDay "+oldDay);
      console.log("oldMonth "+oldMonth);
      console.log("oldYear "+oldYear);
      if(day!=""&&year!=""&&month!=""){
      if(oldYear>currYear){
        console.log("error year");
        this.errorDate=true;

      }
      else if(oldYear<currYear){
        //console.log("go year ");
       this.errorDate=false;
      }
      else if(oldYear==currYear){
        if(currMonth<oldMonth){
         // console.log("error month");
          this.errorDate=true;
        }
        else if(currMonth>oldMonth){
          //console.log("go month ");
          this.errorDate=false;
        }
        else if(currMonth==oldMonth){
          if(currDay<oldDay){
            //console.log("error day ");
            this.errorDate=true;
          }
          else if(currDay>=oldDay){
           // console.log("go day");
            this.errorDate=false;
          }


        }
      }
    }
      else{
        this.errorDate=false;
      }
     
      console.log("thisuser"+this.user);
      this.checkPasswordMatching();

      if(this.errorDate==false&&!this.formFields.userName.errors && !this.formFields.userEmail.errors && !this.formFields.userPassword.errors && !this.formFields.userCommercialNumber.errors && !this.formFields.userCompanyPhone.errors && !this.formFields.userIdentityNumber.errors && !this.formFields.userRegDay.errors && !this.formFields.userRegMonth.errors && !this.formFields.userRegYear.errors && !this.formFields.userManagerName.errors && !this.formFields.userManagerPhone.errors && !this.formFields.userManagerMobile.errors && !this.formFields.userExtinsionNumber.errors && !this.passwordMatching )
      {
          
          
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
          this.apiService.addOrUpdateUser(this.user).subscribe((response) => {
            console.log("user: "+this.user.companyPhone);
            if (response["error"] == 0) {
                console.log(response);
                this.user = response["data"];
                this.translate.get(response["message"]).subscribe(res => {
                  $.notify({message: res}, {type: 'success'});
                });

                this.zone.run(() => this.router.navigate(['users'],{ 
                  skipLocationChange: false 
                }));
              }
            else {
              //$.notify({ message:response["message"]}, {type:"danger"});
             // console.log(response["details"]);
              //console.log(response["message"]);

              for (var k = 0; k <response["details"].length; k++) {
                if(response["details"][k]==1){
                  this.dublicatedMail=true;
                }
                if(response["details"][k]==2){
                  this.dublicatedId=true;
                }
                if(response["details"][k]==3){
                  this.dublicatedComartial=true;
                }
                if(response["details"][k]==4){
                  this.dublicatedComPhone=true;
                }
                if(response["details"][k]==5){
                  this.dublicatedManagerPhone=true;
                }
                if(response["details"][k]==6){
                  this.dublicatedManagerMobile=true;
                }
                if(response["details"][k]==7){
                  this.dublicatedExtentionPhone=true;
                }
                
              } 
              

              //console.log(response["details"]);
             // console.log(response["message"]);
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
        
      }
    }
      

    
  }
  /*
  clearUser()
  {
    
      this.userForm.reset();
      const day=this.userForm.get('userRegDay');
      day.setValue("");
      const month=this.userForm.get('userRegMonth');
      month.setValue("");
      const year=this.userForm.get('userRegYear');
      year.setValue("");
    
  }*/
  goToBackAdd()
{
 var self=this;

 if(this.connectionStatus=="offline")
 {
   this.translate.get(this.connectionStatusMessage).subscribe(res => {
     $.notify({ message:res}, {type:"danger"});
   });
 }
 else{
  /*console.log(this.formFields.userName.value);
  console.log(this.formFields.userEmail.value);
  console.log(this.formFields.userPassword.value);
  console.log(this.formFields.confirmPassword.value);
  console.log(this.formFields.userIdentityNumber.value);
  console.log(this.formFields.userCompanyPhone.value);
  console.log(this.formFields.userCommercialNumber.value);
  console.log(this.formFields.userManagerName.value);
  console.log(this.formFields.userManagerPhone.value);
  console.log(this.formFields.userManagerMobile.value);
  console.log(this.formFields.userExtinsionNumber.value);userRegDay*/
  if(this.formFields.userName.value!=""||this.formFields.confirmPassword.value!=null || this.formFields.userEmail.value!="" || this.formFields.userPassword.value!=""
  || this.formFields.userIdentityNumber.value!=""  || this.formFields.userCommercialNumber.value!=""  || this.formFields.userCompanyPhone.value!=""  || this.formFields.userManagerName.value!="" 
  || this.formFields.userManagerMobile.value!=""  || this.formFields.userManagerPhone.value!=""  || this.formFields.userExtinsionNumber.value!=""||this.formFields.userRegDay.value!=""||this.formFields.userRegMonth.value!="" ||this.formFields.userRegYear.value!="" ){
  var title="Do you want to disacard changes?";
  var text1="Disacard";
  var text2="Cancel";
  this.translate.get(title).subscribe(res => {
    title=res;
  });
  this.translate.get(text1).subscribe(res => {
    text1=res;
  });
  this.translate.get(text2).subscribe(res => {
    text2=res;
  });
  swal({
    title:title,
    text: "",
    type: "error",
    showCancelButton: true,
    cancelButtonClass: "btn-default",
    cancelButtonText: text2,
    confirmButtonClass: "btn-danger",
    confirmButtonText: text1,
    closeOnConfirm: true
  },
  function(){
    window.history.go(-1);
  });
 }
 else{
  window.history.go(-1);

 }
}

  
}
  
goToBackEdit()
{
 var self=this;

 if(this.connectionStatus=="offline")
 {
   this.translate.get(this.connectionStatusMessage).subscribe(res => {
     $.notify({ message:res}, {type:"danger"});
   });
 }
 else{
   /*console.log(this.formFields.userName.value);
   console.log(this.formFields.userIdentityNumber.value);
   console.log(this.formFields.userCompanyPhone.value);
   console.log(this.formFields.userManagerName.value);
   console.log(this.formFields.userManagerPhone.value);
   console.log(this.formFields.userManagerMobile.value);
   console.log(this.formFields.userExtinsionNumber.value);

   console.log(this.Username);
   console.log(this.Identity_Number);
   console.log(this.Company_Phone);
   console.log(this.Manager_Name);
   console.log(this.Manager_Phone);
   console.log(this.Manager_Mobile);
   console.log(this.Extension_Number);*/


   if(this.formFields.userName.value!=this.Username || this.formFields.userIdentityNumber.value!=this.Identity_Number || this.formFields.userCompanyPhone.value!=this.Company_Phone
    || this.formFields.userManagerName.value!=this.Manager_Name  || this.formFields.userManagerPhone.value!=this.Manager_Phone || this.formFields.userManagerMobile.value!=this.Manager_Mobile
    || this.formFields.userExtinsionNumber.value!=this.Extension_Number){
  var title="Do you want to disacard changes?";
  var text1="Disacard";
  var text2="Cancel";
  this.translate.get(title).subscribe(res => {
    title=res;
  });
  this.translate.get(text1).subscribe(res => {
    text1=res;
  });
  this.translate.get(text2).subscribe(res => {
    text2=res;
  });
  swal({
    title:title,
    text: "",
    type: "error",
    showCancelButton: true,
    cancelButtonClass: "btn-default",
    cancelButtonText: text2,
    confirmButtonClass: "btn-danger",
    confirmButtonText: text1,
    closeOnConfirm: true
  },
  function(){
    window.history.go(-1);
  });
 }
 else{
  window.history.go(-1);

 }
}

  
}
changeUserType(event){
  console.log("event"+event);
   this.Iscompany=event;
   console.log("iscomoany"+this.Iscompany);
   //this.formValidations(this.Iscompany);
}

  
}
