import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from  '@angular/common/http';
import { HttpModule,RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { ConfigVariables } from '../shared/config';
import { throwError } from 'rxjs';
import { Body } from '@angular/http/src/body';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

// import 'rxjs/add/operator/toPromise';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // API_URL  =  'http://localhost:8000';
  constructor(private local:LocalStorageService,
    private  httpClient:  HttpClient,
    private session:SessionStorageService,private router: Router) {}
    checkLogin(currentUser)
    {
     if(currentUser=null){
      
      this.router.navigate(['login']);          
     }
     
    }
  
  getTriprReport(){
    
    return  this.httpClient.get(`${ConfigVariables.API_URL}/contacts`);
  }

  public login(username: string, password: string){
      
    // conf.apiurl
    
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'Authorization':'Basic '+ btoa(unescape(encodeURIComponent(username + ":" + password))),
       // basic from user and pass
      'Accept': 'application/json',
    });
    
    return this.httpClient.post<any>(`${ConfigVariables.API_URL}/login`,{},{headers:headerss })
      .pipe(map(user => {
        
              return user;
      }));
  }

  public getDevicesAndDriversStatusService(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.get(`${ConfigVariables.API_URL}/get_devices_status_and_drivers`,{headers:headerss })
        .pipe(map(result => {
          
        return result;
        }));

    }
    else
    {
      
      this.router.navigate(['login']); 
    }
    
    
  }

  public removeProfilePhoto(profileData){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        return this.httpClient.post(`${ConfigVariables.API_URL}/remove_profile_photo`,profileData,{headers:headerss })
          .pipe(map(result => {
            
                  return result;
          }));
    }
    else{
      
      this.router.navigate(['login']); 
    }
    
  }
  public getCurrentDayNotifications(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.get(`${ConfigVariables.API_URL}/get_notifiction_current_day`,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
     
      this.router.navigate(['login']); 
    }
    
  }
  public getProfileInfo(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.get(`${ConfigVariables.API_URL}/get_profile_info`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public getAllLastInfoService(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.get(`${ConfigVariables.API_URL}/all_devices_last_info`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public getAllUsersLastInfoService(){
      
    // conf.apiurl
   var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.get(`${ConfigVariables.API_URL}/get_all_users`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }


  public getAllDevicesLastInfoService(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.get(`${ConfigVariables.API_URL}/all_devices_last_info`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }

  public getAllDrivers(){
      
    // conf.apiurl
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.get(`${ConfigVariables.API_URL}/get_all_drivers`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public addOrUpdateUser(userData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/do_edit_user`,userData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else{
     
      this.router.navigate(['login']);
    }
    

  }
  public getUserInfo(userData)
  { 
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/edit_user`,userData,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));

  }
  public deleteUser(userData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/delete_user`,userData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
    

  }
  public deleteDevice(deviceData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/delete_device`,deviceData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
   else{
    
    this.router.navigate(['login']);
   }
  }
  public deleteTestDevice(deviceData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/delete_test_device`,deviceData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
   else{
    
    this.router.navigate(['login']);
   }
  }
  public addOrUpdatedevice(deviceData)
  { 
    var currentUser = this.local.get('currentUser');
   if(currentUser!=null)
   {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/do_edit_device`,deviceData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
   }
   else{
    
    this.router.navigate(['login']);
   }
    

  }
  public getdeviceInfo(deviceData)
  {
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/edit_device`,deviceData,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));

  }
  public deleteDriver(driverId)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/delete_driver`,driverId,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));

    }else
    {
      
      this.router.navigate(['login']);
    }
   

  }
  public addOrUpdateDriver(driverData)
  {  
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/do_edit_driver`,driverData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else{
     
      this.router.navigate(['login']);
    }
    

  }
  public getDriverInfo(driverData)
  { 
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/edit_driver`,driverData,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));

  }
  public get_all_drivers_devices(deviceId)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/get_all_drivers_assign_devices`,{deviceId:deviceId},{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
  
    }
    else{
      
      this.router.navigate(['login']);
    }
   

  }
  public get_all_geofences_devices(deviceId)
  { 
    var currentUser = this.local.get('currentUser');
   if(currentUser!=null)
   {
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/get_all_geofences_assign_devices`,{deviceId:deviceId},{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
   }
   else{
    
    this.router.navigate(['login']);
   }
    

  }
  public getTripPositions(tripData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/view_trip`,tripData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
    

  }
  public updateProfilePhoto(profileData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/remove_profile_photo`,profileData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
   

  }
  public updateProfileData(profileData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/edit_profile`,profileData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
  
    }
    else{
      
      this.router.navigate(['login']);
    }
    
  }
  public changePassword(passwordData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/change_password`,passwordData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else{
      
      this.router.navigate(['login']);
    }

  }
  public getAllGeofences(){
 
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    
    return this.httpClient.get(`${ConfigVariables.API_URL}/get_all_geofences`,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public getGeofence(geoId){
   
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    
    return this.httpClient.post(`${ConfigVariables.API_URL}/edit_geofence`,{geoId:geoId},{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public addGeofence(geofence,geo_id){
      
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/do_edit_geofence`,{geoId:geo_id,name:geofence['name'],area:geofence['area'],type:geofence['type']},{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }

    
    
  }
  public deleteGeofence(geo_id){
      
    var currentUser =this.local.get('currentUser');
   if(currentUser!=null)
   {
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/delete_geofence`,{geoId:geo_id},{headers:headerss })
      .pipe(map(result => {
         return result;
      }));
   }
   else
   {
    
    this.router.navigate(['login']);
   }
    
  }
  public logout(){
      
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.get(`${ConfigVariables.API_URL}/logout_get`,{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else{
      
      this.router.navigate(['login']);
    }
   
  }
  public get_all_drivers_not_assign_devices(){
      
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.get(`${ConfigVariables.API_URL}/get_all_drivers_not_assign_devices`,{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else{
     
      this.router.navigate(['login']);
    }
    
  }
  public assignDriverToDevice(driverId,deviceId){
      
   
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/assign_driver_to_device`,{driverId:driverId,deviceId:deviceId},{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
    
  }
  public get_all_geofences_not_assign_devices(){
    

    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/get_all_geofences_not_assign_devices`,{},{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
   
    
  }
  public assignGeofenceToDevice(geoId,deviceId){
      
    
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/save_geofence_of_device`,{geoId:geoId,deviceId:deviceId},{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }else
    {
      

      this.router.navigate(['login']);
    }
    
  }
  public getReportOfDevice(deviceId){
      
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/get_device_last_location`,{deviceId:deviceId},{headers:headerss })
        .pipe(map(result => {
           return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);

    }
   
  }
  public getReportOfDriver(deviceId){
      
    
    var currentUser = this.local.get('currentUser');
    
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/get_summary_data_of_device`,{deviceId:deviceId},{headers:headerss })
      .pipe(map(result => {
         return result;
      }));
  }
  GetHttpHeaders() : HttpHeaders{
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return headers;
  }
  public exportToExcel(reportData)
  { 
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.API_URL}/export_to_excel`,reportData,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
   

  }

  getUserToken() {
    var currentUser = this.local.get('currentUser');
    if(currentUser== null)
    {
      return null;
    }
    currentUser = JSON.parse(currentUser);
    return currentUser["data"];
  }

  public getCalibrationData(deviceId)
  { 
    
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/get_calibration_data`,{'deviceId':deviceId},{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));

  }
  public addTestDevice(deviceData)
  { 
    
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/add_test_device`,deviceData,{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));

  }
  public addCaliberationData(deviceId,caliberationData)
  {
    var currentUser = this.local.get('currentUser');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser["data"];
    var headerss = new HttpHeaders({
      'content-type' : 'application/json',
      'TOKEN': currentUser["token"], 
      'Accept': 'application/json'
    });
    return this.httpClient.post(`${ConfigVariables.API_URL}/add_data_to_caliberation`,{'deviceId':deviceId,"calibrationData":caliberationData},{headers:headerss })
      .pipe(map(result => {
        
              return result;
      }));
  }
  public registerVehicleToElm(deviceId)
  {
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.Gold_URL}/devices_registration`,deviceId,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
  }
  public registerDriverToElm(driverId)
  {
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.Gold_URL}/drivers_registration`,driverId,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
  }
  public registerUserToElm(driverId)
  {
    var currentUser = this.local.get('currentUser');
    if(currentUser!=null)
    {
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      return this.httpClient.post(`${ConfigVariables.Gold_URL}/company_registrtaion_in_elm`,driverId,{headers:headerss })
        .pipe(map(result => {
          
                return result;
        }));
    }
    else
    {
      
      this.router.navigate(['login']);
    }
  }
 
}
