import { Component, OnInit } from '@angular/core';
import {DriverworkinghoursComponent } from '../driverworkinghours/driverworkinghours.component';
@Component({
  selector: 'app-driverworkinghoursloader',
  templateUrl: './driverworkinghoursloader.component.html',
  styleUrls: ['./driverworkinghoursloader.component.css']
})
export class DriverworkinghoursloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}