export class User {
    name: string;
    userId: number;
    email: string;
    password: string;
    identityNum: string;
    commercialNum: string;
    companyPhone: string;
    day: string;
    month:string;
    year:string;
    managerName: string;
    managerPhone: string;
    managerMobile: string;
    phone:string;
    commercialRegDate:string;
    photo:string;
    referenceKey:string;
    IsCompany:Number;

    
    constructor(){
        this.name = "";
        this.userId = 0;
        this.email = "";
        this.password ="";
        this.identityNum = "";
        this.commercialNum = "";
        this.companyPhone = "";
        this.day = "";
        this.month="";
        this.year="";
        this.managerName = "";
        this.managerPhone = "";
        this.managerMobile = "";
        this.phone = "";
        this.commercialRegDate="";
        this.photo="";
        this.referenceKey="";
        this.IsCompany=1;
    }

    public deserialize(input: any) {
        Object.assign(this, input);
        return this;   
    }
}
