import { OnDestroy ,Component, OnInit, Input, NgZone } from '@angular/core';
import { User } from '../../shared/users';
import { NgModule }      from '@angular/core';
import { ApiService } from '../../network/api.service';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { PlatformLocation, NgForOf } from '@angular/common';
import * as swal from '../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import { checkNoChanges } from '@angular/core/src/render3/instructions';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

//import {resizeService} from 'angular-images-resizer'
//import { error } from '@angular/compiler/src/util';
//npm install angular-images-resizer

declare function require(path: string): any;

declare var jquery:any;
declare var $ :any;
var moment = require('moment-hijri');       

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  days: Array<number>=[];
  years: Array<number>=[];
  user:User;
 
  fields=[];
  userForm: FormGroup;
  passwordForm:FormGroup;
  submitted:boolean;
  dublicatedManagerPhone:boolean;
  dublicatedManagerMobile:boolean;
  duplicationTypes:any;
  passwordMatching:boolean;
  passwordSubmitted:boolean;
  oldPassword:string;
  newPassword:string;
  dir="";
  dirButton="";
  dirtable="";
  confirmPassword:string;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  changePhoto:boolean;
  selectedFile:File;
  selectedFileSize:boolean;
  selectedFileType:boolean;
  imageUrl:string;
  noPhoto:boolean;
  subm:boolean;
  submProf:boolean;
  submittedImage:string;
  userChanges:boolean;
  passwordChanges:boolean;
  managerName:string="";
  managerPhone:string;
  managerMobile:string;
  noChangePassword:boolean;
  constructor(private local:LocalStorageService,
    public translate: TranslateService,private apiService: ApiService,private router: Router ,private routerActive :ActivatedRoute,
    private zone: NgZone,private formBuilder: FormBuilder,location: PlatformLocation) {
    this.user = new User();
    this.submitted = false;
    this.dublicatedManagerPhone=false;
    this.dublicatedManagerMobile=false;
    this.passwordMatching=false;
    this.passwordSubmitted=false;
    this.selectedFile=null;
    this.selectedFileSize=false;
    this.selectedFileType=false;
    this.imageUrl="";
    this.submittedImage=null;
    this.noPhoto=false;
    this.userChanges=false;
    this.passwordChanges=false;
    this.noChangePassword=false;
    this.changePhoto=false;
    location.onPopState(() => {
    // this.checkChanges();
  // this.goToDashboard(true);
 // window.onbeforeunload = function() { alert("doyou want to discard"); };

  });
  
     
   }
   onChange(){
    this.submProf=false;
  }
  ngOnDestroy(){

  }
  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
     
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      var elem1 = document.getElementById('user');
      elem1.style.cssFloat="right";
      var elem2 = document.getElementById('mail');
      elem2.style.cssFloat="right";
      var elem3 = document.getElementById('identity');
      elem3.style.cssFloat="right";
      var elem4 = document.getElementById('commercial');
      elem4.style.cssFloat="right";
      var elem5 = document.getElementById('companyPhone');
      elem5.style.cssFloat="right";
      var elem6 = document.getElementById('commercialRegDate');
      elem6.style.cssFloat="right";
      var elem7 = document.getElementById('managerName');
      elem7.style.cssFloat="right";
      var elem8 = document.getElementById('managerPhone');
      elem8.style.cssFloat="right";
      var elem9 = document.getElementById('managerMobile');
      elem9.style.cssFloat="right";
      var elem10 = document.getElementById('Extension');
      elem10.style.cssFloat="right";
      var elem11 = document.getElementById('set1');
      elem11.style.cssFloat="right";
      var elem12 = document.getElementById('set2');
      elem12.style.cssFloat="right";
      var elem13 = document.getElementById('old');
      elem13.style.cssFloat="right";
      var elem14 = document.getElementById('new');
      elem14.style.cssFloat="right";
      var elem15 = document.getElementById('conf');
      elem15.style.cssFloat="right";
     
    }
  
   
    this.subm=true;
    this.submProf=true;
    this.userForm = this.formBuilder.group({
       userName: ['', [Validators.required,Validators.minLength,Validators.pattern]],
       userEmail: ['',[Validators.required,Validators.email]],// Validators.pattern('[009665|9665|+9665|05|5][5|0|3|6|4|9|1|8|7]([0-9]{7})')]], //, Validators.minLength(6)]
       userIdentityNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
       userCommercialNumber : ['', [Validators.required,Validators.pattern,Validators.minLength]],
       userCompanyPhone : ['', [Validators.required,Validators.pattern,,Validators.minLength]],
       userRegDate : ['', [Validators.required]],
       userManagerName : ['', [Validators.required,Validators.pattern,Validators.minLength]],
       userManagerPhone : ['', [Validators.required,Validators.pattern,Validators.minLength]],
       userManagerMobile : ['', [Validators.required,Validators.pattern,Validators.minLength]],
       userExtinsionNumber : ['', [Validators.pattern,Validators.minLength]]
      
      });
      this.passwordForm=this.formBuilder.group({
        oldPassword:['',[Validators.required,Validators.minLength,Validators.pattern]],
        newPassword:['',[Validators.required]],
        confirmPassword:['',[Validators.required]]
      });

      this.getProfileData();
      this.userForm.get('userName').disable();
      this.userForm.get('userEmail').disable();
      this.userForm.get('userIdentityNumber').disable();
      this.userForm.get('userCommercialNumber').disable();
      this.userForm.get('userCompanyPhone').disable();
      this.userForm.get('userRegDate').disable();
      this.userForm.get('userExtinsionNumber').disable();
     this.getConnections();
    

  }
  get formFields() { return this.userForm.controls; }
  get passwordFormFields() { return this.passwordForm.controls; }
  getConnections(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
  }
  
  getProfileData()
  {
    
    this.noPhoto=false;
    this.subm=true;
    //console.log(this.user);
    console.log("data");
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getProfileInfo().subscribe((response) => {
      if (response["error"] == 0) {
          //console.log(response["data"]);
          this.user = response["data"];
          this.managerName=response["data"].managerName;
          this.managerPhone=response["data"].managerPhone;
          this.managerMobile=response["data"].managerMobile;
          if(this.user.month=="01")
            {
              this.user.month="Muḥarram";
            }
          else if(this.user.month=="02")
            {
              this.user.month="Ṣafar";
            }
          else if(this.user.month=="03")
            {
              this.user.month="Rabī‘ al-awwal";
            }  
          else if(this.user.month=="04")
            {
              this.user.month="Rabī‘ ath-thānī";
            }  
          else if(this.user.month=="05")
            {
              this.user.month="Jumādá al-ūlá";
            } 
          else if(this.user.month=="06")
            {
              this.user.month="Jumādá al-ākhirah";
            } 
          else if(this.user.month=="07")
            {
              this.user.month="Rajab";
            } 
          else if(this.user.month=="08")
            {
              this.user.month="ha‘bān";
            }    
          else if(this.user.month=="09")
            {
              this.user.month="Ramaḍān";
            } 
          else if(this.user.month=="10")
            {
              this.user.month="Shawwāl";
            } 
          else if(this.user.month=="11")
            {
              this.user.month="Dhū al-Qa‘dah";
            } 
          else if(this.user.month=="12")
            {
              this.user.month="Dhū al-Ḥijjah";
            } 
            if(this.user.photo == "Not-available.jpg")
            {
              this.noPhoto=true;
            }
            if(this.user.email=="admin@fuinco.com")
            {
              this.noChangePassword=true;
            }
            this.translate.get(this.user.month).subscribe(res => {
              this.user.month=res;
            });
            /*this.translate.get(this.user.photo).subscribe(res => {
              this.user.photo=res;
            });*/
            console.log("noPhoto"+this.noPhoto);
            this.user.commercialRegDate=this.user.day+" ,"+this.user.month+" ,"+this.user.year;
            if(this.user.photo==null){
              this.user.photo="Not-available.jpg";
            }
             this.imageUrl="http://31.204.150.201/sareb_backend/photos/"+this.user.photo;
            
        }
      else {
          // show errors message
          console.log(response["error"]);
          }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
    });
  }
  
  }
  submitProfile()
  {
    this.submitted=true;
    this.dublicatedManagerMobile=false;
    this.dublicatedManagerPhone=false;
    var self=this;
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else
    {
     
       
        if(!this.formFields.userManagerName.errors && !this.formFields.userManagerPhone.errors && !this.formFields.userManagerMobile.errors )
        {
          var profileData:any;
          this.submProf=true;
          profileData={'managerMobile':this.user.managerMobile,'managerPhone':this.user.managerPhone,'managerName':this.user.managerName};
          //console.log("profileData: "+profileData);
          this.apiService.updateProfileData(profileData).subscribe((response) => {
            if (response["error"] == 0) {
                //console.log(response);
                //this.user = response["data"];
               
                this.getProfileData();
                this.translate.get(response["message"]).subscribe(res => {
                  $.notify({message: res}, {type: 'success'});
                });
                this.submProf=true;
              }
            else if(response['error']==2)
            {
              
                this.duplicationTypes=response['details'];
                
                if(this.duplicationTypes.findIndex(this.findDuplicationInManagerMobile)!=-1)
                {
                  
                  this.dublicatedManagerMobile=true;
                }
                if(this.duplicationTypes.findIndex(this.findDuplicationInManagerPhone)!=-1)
                {
                  this.dublicatedManagerPhone=true;
                }
            } 
            else {
                // show errors message
                console.log(response["error"]);
                }
            },
            error => {
                // this.alertService.error(error);
                console.log("error" + error);
                // this.loading = false;
          });
        }
    }       
 }
  findDuplicationInManagerMobile(element) {
    return element == 6;
  }
  findDuplicationInManagerPhone(element) {
    return element == 5;
  }
  checkPasswordMatching()
  {
    if(this.confirmPassword!=this.newPassword)
    {
      this.passwordMatching=true;
    }
    
  }
  submitPassword()
  {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else
    {
      this.passwordSubmitted=true;
      this.passwordMatching=false;
      this.checkPasswordMatching();

      if(!this.passwordFormFields.oldPassword.errors && !this.passwordFormFields.newPassword.errors && !this.passwordFormFields.confirmPassword.errors && !this.passwordMatching)
      {
        var passwordData:any;
        this.submProf=true;
        passwordData={'oldPassword':this.oldPassword,'newPassword':this.newPassword};
        this.apiService.changePassword(passwordData).subscribe((response) => {
          this.submProf=true;
          if (response["error"] == 0) {
              console.log(response);
              this.user = response["data"];
              this.getProfileData();
              this.translate.get(response["message"]).subscribe(res => {
                $.notify({message: res}, {type: 'success'});
              });
              this.submProf=true;

            }
          else if(response['error']==1)
          {
            this.submProf=true;

          //  this.getProfileData();
            var currentLan = this.local.get('lan');
            currentLan = JSON.parse(currentLan);
            if(currentLan=='en'){
              $.notify({message: "Old Password is incorrect"}, {type: 'danger'});

            
            }
            else if(currentLan=='ar'){
              $.notify({message: "كلمة المرور السابقة غير صحيحة"}, {type: 'danger'});

            }
            
          } 
          else {
              // show errors message
              this.submProf=true;
              console.log(response["error"]);
              }
          },
          error => {
              // this.alertService.error(error);
              console.log("error" + error);
              // this.loading = false;
        });
        
      }
    }
 

  }
 
  onFileSelected(file:FileList)
  {
    this.selectedFile=file.item(0);
    this.noPhoto=false;
    this.subm=false;
    var reader=new FileReader();
    reader.onload=(event:any)=>{
     this.savePhoto(event.target.result);
     this.imageUrl=event.target.result;
    }
   
    reader.readAsDataURL(this.selectedFile);
   
    this.selectedFileSize=false;
    this.selectedFileType=false;
     console.log("imageTYpe"+this.selectedFile.type);
    if((this.selectedFile.size/1000)>1000)
    {
      this.selectedFileSize=true;
    }
    if((this.selectedFile.type!="image/png")&&(this.selectedFile.type!="image/jpg") &&(this.selectedFile.type!="image/jpeg"))
    {
      this.selectedFileType=true;
    }
    this.changePhoto=true;
    
  }
savePhoto(image)
{
  this.submittedImage=image;
    
}
goToDashboard(fromBrowser=false)
{
 var self=this;
 console.log("Old: "+this.formFields.userManagerName.value);
 console.log("New: "+this.managerName);
 if(this.connectionStatus=="offline")
 {
   this.translate.get(this.connectionStatusMessage).subscribe(res => {
     $.notify({ message:res}, {type:"danger"});
   });
 }
 else{
 if(this.passwordFormFields.oldPassword.value!=null||this.passwordFormFields.newPassword.value!=null||this.passwordFormFields.confirmPassword.value!=null||this.changePhoto ||this.formFields.userManagerName.value!=this.managerName || this.formFields.userManagerPhone.value!=this.managerPhone || this.formFields.userManagerMobile.value!=this.managerMobile && !this.submitted)
 {
  var title="Do you want to disacard changes?";
  var text1="Disacard";
  var text2="Cancel";
  this.translate.get(title).subscribe(res => {
    title=res;
  });
  this.translate.get(text1).subscribe(res => {
    text1=res;
  });
  this.translate.get(text2).subscribe(res => {
    text2=res;
  });
  swal({
    title:title,
    text: "",
    type: "error",
    showCancelButton: true,
    cancelButtonClass: "btn-default",
    cancelButtonText: text2,
    confirmButtonClass: "btn-danger",
    confirmButtonText: text1,
    closeOnConfirm: true
  },
  function(){
    //self.router.navigate(['main']);
    if(!fromBrowser)
    {
      window.history.go(-1);
    }
    
   
  });
 }
 else{
    //this.router.navigate(['main']);
    if(!fromBrowser)
    {
      window.history.go(-1);
    }
 }
 }
  
}
addProfilePhoto(){
  this.changePhoto=false;
  var profileData={'photo':this.submittedImage};
  //document.getElementById("photo").innerHTML="";
  var container = document.getElementById('photo');
  var refreshContent = container.innerHTML;
  container.innerHTML = refreshContent;
  //(<HTMLInputElement>element).refr(true);
  //console.log("filebefore: "+element);
  if(this.connectionStatus=="offline")
  {
    this.translate.get(this.connectionStatusMessage).subscribe(res => {
      $.notify({ message:res}, {type:"danger"});
    });
  }
  else{
  this.apiService.updateProfilePhoto(profileData).subscribe((response) => {
    if (response["error"] == 0) {
        console.log(response);
    
        this.getProfileData();
        this.translate.get(response["message"]).subscribe(res => {
          $.notify({message: res}, {type: 'success'});
        });
        this.subm=true;

      }
    else if(response['error']==1)
    {
      this.translate.get(response["message"]).subscribe(res => {
        $.notify({message: res}, {type: 'danger'});
      });
    }  
    else {
        // show errors message
        console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
  });
}
} 
removeProfilePhoto()
{
  
  var self=this;
  this.subm=false;
  this.savePhoto(null);
  this.imageUrl="http://31.204.150.201/sareb_backend/photos/"+"Not-available.jpg";
  this.changePhoto=true;
  //this.noPhoto=true;
  //self.addProfilePhoto();

    /*self.apiService.removeProfilePhoto(profileData).subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
          //self.user = response["data"];
          self.getProfileData();
          this.translate.get(response["message"]).subscribe(res => {
            $.notify({message: res}, {type: 'success'});
          });
         
        }
      else if(response['error']==1)
      {
        
   //     this.getProfileData();
      this.translate.get(response["message"]).subscribe(res => {
        $.notify({message: res}, {type: 'danger'});
      });
      } 
      else {
          // show errors message
          console.log(response["error"]);
          }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
    });*/
          
  /*var title1="Are you sure you want to delete this photo?";
  var text1="You will not be able to recover this photo";
  var title2="Deleted!";
  var text2="Your photo has been deleted.";
  var text3="Cancel";
  var text4="Remove";
  var text5="Ok";

  this.translate.get(title1).subscribe(res => {
    title1=res;
  });
  this.translate.get(text1).subscribe(res => {
    text1=res;
  });
  this.translate.get(title2).subscribe(res => {
    title2=res;
  });
  this.translate.get(text2).subscribe(res => {
    text2=res;
  });
  this.translate.get(text3).subscribe(res => {
    text3=res;
  });
  this.translate.get(text4).subscribe(res => {
    text4=res;
  });
  this.translate.get(text5).subscribe(res => {
    text5=res;
  });
  swal({
    title: title1,
    text: text1,
    type: "error",
    showCancelButton: true,
    cancelButtonClass: "btn-default",
    cancelButtonText: text3,
    confirmButtonClass: "btn-danger",
    confirmButtonText: text4,
    closeOnConfirm: true
  },
  function(){
    
     
  });*/
  
} 



  
}