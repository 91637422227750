import {OnDestroy, Component, OnInit, AfterViewInit, NgZone, ViewChild } from '@angular/core';
//import * as $ from 'jquery';
import { User } from '../../shared/users';
import { Subject } from 'rxjs';
import { ApiService } from '../../network/api.service';
import { Router } from '@angular/router';
import {fromEvent, Observable, Subscription} from 'rxjs';
import * as swall from '../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import Swal from 'sweetalert2'
import {  HttpHeaders} from  '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

var self;
declare var $ :any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  users: User[];
  Edit="Edit";
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  Delete="Delete";
  dir="";
  dirButton="";
  dirtable="";
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<User> = new Subject();
  
  constructor(public apiService: ApiService,
              private router: Router ,
              private zone: NgZone,
              private local:LocalStorageService,
              private translate: TranslateService  )
   { 
    //this.getConnections();

   }

  ngAfterViewInit(){
    
  }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    self = this;
    self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: false,
      lengthChange: false,
      sort:false
    };
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
    
    }
   
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
      
    }
    
      this.getConnections();
    
      self.getAllUsersLastInfo();
    
  }

  getAllUsersLastInfo(){
    //swal("test");
    
    self.users=[];
    this.apiService.getAllUsersLastInfoService().subscribe((response) => {
      console.log("header"+response)
        if (response["error"] == 0) {
            console.log(response);
            self.users = response["data"];
            if(self.users.length==0){
              self.users=[];
            }
            else{
            self.dtTrigger.next();
            }

          }
        else {
          self.users = [];
            // show errors message
            console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
  
  }
  goCreateUser(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['createuser'],{ 
      skipLocationChange: false 
    }));
    }
  }
  goUpdateUser(userId)
  {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['createuser'],{ queryParams: { id: userId } ,skipLocationChange: false }));
    }
}
  

  showDeleteAlert(userId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    self=this;
    var title1="Are you sure you want to delete this user?";
    var text1="You will not be able to recover this record";
    var title2="Deleted!";
    var text2="Your user has been deleted.";
    var text3="Cancel";
    var text4="Remove";
    var text5="Ok";
    this.translate.get(title1).subscribe(res => {
      title1=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(title2).subscribe(res => {
      title2=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    this.translate.get(text3).subscribe(res => {
      text3=res;
    });
    this.translate.get(text4).subscribe(res => {
      text4=res;
    });
    this.translate.get(text5).subscribe(res => {
      text5=res;
    });
    
    swall({
      title: title1,
      text: text1,
      type: "error",
       showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text3,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text4,
      closeOnConfirm: true
    },
    function(){
      
        let userdata: any;
        userdata={"userId": userId };
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
        self.apiService.deleteUser(userdata).subscribe((response) => {
          if (response["error"] == 0) {
              console.log(response);
             // self.users = response["data"];
              //self.dtTrigger.next()
              self.rerender();
              swall({
                title: title2,
                text: text2,
                type:'success',
                confirmButtonText: text5,
              });
             
            }
          else {
              // show errors message
              console.log(response["error"]+response["message"]);
          }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
      });
    } 
    });

    }
  }
  connectionToElm(userId)
  {
   
  Swal({
    title: 'Are you sure you want to send this user to Elm?',
   
    inputAttributes: {
      autocapitalize: 'Cancel'
    },
    showCancelButton: true,
    confirmButtonText: 'Send',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      let userdata: any;
      var currentUser = this.local.get('currentUser'); 
      currentUser = JSON.parse(currentUser);
      currentUser = currentUser["data"];
      var headerss = new HttpHeaders({
        'content-type' : 'application/json',
        'TOKEN': currentUser["token"], 
        'Accept': 'application/json'
      });
      userdata={"userId": userId };
    
      return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/company_registrtaion_in_elm', {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(userdata), // data can be `string` or {object}!
        headers:{
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
    if (result.value) {
      Swal({
        title:result.value.message,
        preConfirm: () => {
          self.rerender();
        }
      })
    }
  })
  }
  rerender(): void {
    self.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
      self.getAllUsersLastInfo();
    });
  }
  verfiyWithElm(userId)
  {
    Swal({
      title: 'Are you sure you want to verfiy this Company with Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let userdata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        userdata={"userId": userId };
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/company_inquery_service', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(userdata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
            //self.rerender();
          }
          
        })
      }
    })
  }

  
}
