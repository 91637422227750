import { Component, OnInit } from '@angular/core';
import {CaliberationTableComponent} from '../caliberationTable/caliberationTable.component';
import { Router,ActivatedRoute } from '@angular/router';
import { SidebarComponent } from '../../../sidebar/sidebar.component';
import { NavbarComponent } from '../../../navbar/navbar.component';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-devicecreateloader',
  templateUrl: './caliberationTableloader.component.html',
  styleUrls: ['./caliberationTableloader.component.css'],
})
export class CaliberationTableloaderComponent implements OnInit {
  dir="";
  deviceIdToEdit:number;
  constructor(private local:LocalStorageService,private router: Router,private route: ActivatedRoute ) {
    this.deviceIdToEdit = 0;

  }
  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
    }
    else if(currentLan=='ar'){
      this.dir="rtl";

    }
    this.route.queryParams.subscribe(params => {
      this.deviceIdToEdit = Number.parseInt(params['id']);
    });
  }

}
