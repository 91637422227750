import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Geofence } from 'src/app/shared/geofences';
import { GeofencesComponent } from 'src/app/components/geofences/geofences.component';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

@Component({
  selector: 'app-geofencecreateloader',
  templateUrl: './geofencecreateloader.component.html',
  styleUrls: ['./geofencecreateloader.component.css']
})
export class GeofencecreateloaderComponent implements OnInit {
  //geofence:Geofence;
  geofenceId: number;
  dir="";
  //geofenceTypeToEdit: string;
  //geofence=GeofencesComponent;
  
  constructor(private local:LocalStorageService,private router: Router,private route: ActivatedRoute) { 
    this.geofenceId = 0;
    //this.geofenceNameToEdit = "";
    //this.geofenceTypeToEdit = "";
    //this.geofence=new Geofence();
    //this.geofence;
  }

  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
    }
    else if(currentLan=='ar'){
      this.dir="rtl";

    }
    //console.log("array : "+this.geofence);
    this.route.queryParams.subscribe(params => {
      this.geofenceId = params['geofence'];
      //this.geofenceNameToEdit = params['name'];
      //this.geofenceTypeToEdit = params['type'];
      //this.driverIdToEdit = Number.parseInt(params['id']);
      //console.log("array : "+params.geofence[1]);
    });
    //console.log("hereLoader : "+this.geofence.name+","+this.geofence.type+","+this.geofence.area);

  }
  
  

}
