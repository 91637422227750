import { Component, OnInit, AfterViewInit, NgZone, ViewChild } from '@angular/core';
//import * as $ from 'jquery';
import { Device } from '../../shared/devices';
import { Driver } from '../../shared/drivers';
import { Geofence } from '../../shared/geofences';
import {fromEvent, Observable,Subject, Subscription} from 'rxjs';
import { ApiService } from '../../network/api.service';
import { Router } from '@angular/router';
import * as swal from '../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js';
import { DataTableDirective } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import { ConfigVariables } from '../../shared/config.js';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import Swal from 'sweetalert2';
import { forEach } from '@angular/router/src/utils/collection';
declare var jquery:any;
declare var $ :any;

var self;
@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})

export class DevicesComponent implements AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  devices: Device[];
  drivers: Driver;
  geofences: Geofence;
  names=[];
  deviceid:any;
  id=[];
  DriverID:any;
  GeofenceID=[];
  selectedGeofenceNames:any;
  driverData:Driver;
 // geofenceData:Geofence;
  geofenceData:any[]; 
  selectedDriverName:string;
  selectedDriverId:Number;
  Edit='Edit';
  Delete='Delete';
  dir="";
  dirButton="";
  dirtable="";
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  AssignDriver='Assign Driver';
  AssignGeofence='Assign Geofence';
  caliberationTable="caliberationTable";
  AssigntoElm="Assign to Elm";
  VerifyWithElm="Verify With Elm";
  isMaster:boolean;
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Device> = new Subject();
  
  constructor(private apiService: ApiService,
              private router: Router ,
              private zone: NgZone,
              public translate: TranslateService,
              private local:LocalStorageService,
              private  httpClient:  HttpClient
              )
   {   
   }

  ngAfterViewInit(){
    self.dtTrigger.next();
    
  }
  getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
  ngOnInit() {
    self = this;
    this.selectedDriverName="";
    this.selectedDriverId=0;
    this.selectedGeofenceNames=[];
    this.isMaster=false;
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    /*self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: false,
      lengthChange: false,
      sort:false,
      

      
    };*/
    
    var user=this.local.get('currentUser');
    user = JSON.parse(user);
    var userid=user['data']['id'];
    if(userid==53)
    {
      this.isMaster=true;
    }
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
     
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
        
      }
    
   
    
   
    this.getConnections();
    self.getAllDevicesLastInfo();
  }

  getAllDevicesLastInfo(){
    self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange: false,
      responsive: false,
      serverSide : true,
      processing: true,
      searching: true,
      sort:false,
      "language": {
        "lengthMenu": "Display _MENU_ records per page",
        "zeroRecords": "Nothing found - sorry",
        "info": "Showing page _PAGE_ of _PAGES_",
        "infoEmpty": "No records available",
        "infoFiltered": "(filtered from _MAX_ total records)"
    },
      oLanguage: {
  
          "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
          
         },
      
      
      ajax: (dataTablesParameters: any, callback) => {
          var currentUser = this.apiService.getUserToken();
      
          var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
          });
          if(this.connectionStatus=="offline")
          {
            this.translate.get(this.connectionStatusMessage).subscribe(res => {
              $.notify({ message:res}, {type:"danger"});
            });
          }
          else
          {
              this.httpClient.get(`${ConfigVariables.API_URL}/all_devices_last_info?offset=${dataTablesParameters["start"]}&search=${dataTablesParameters["search"].value}`,{headers:headerss })
              .subscribe(resp => {
                console.log( dataTablesParameters["start"]);
                console.log( resp["data"]);
                console.log("length"+ resp["dataLength"]);
                if(resp["error"]==0){
                     
                  self.devices=resp["data"];
                   
                }
                else{
                  self.devices=[];
                    resp["dataLength"]=0;
                }
               
                
                
                callback({
                    recordsTotal: resp["dataLength"],
                    recordsFiltered: resp["dataLength"],
                    data: []
                  });   
                
              });
           
        
          }
      },

  };
    /*//swal("test");
    self.devices = [];
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.devices = response["data"];
            if(self.devices.length==0){
              self.devices = [];
            }
            else{
              self.dtTrigger.next();
            }

          }
        else {
            // show errors message
            console.log(response["data"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
  }*/
  }

  goCreateDevice(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['createdevice'],{ 
      skipLocationChange: false 
    }));
  }
  }

  goUpdateDevice(deviceId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['createdevice'],{ queryParams: { id: deviceId } ,skipLocationChange: false }));
  }
} 

  showDeleteAlert(deviceId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    self=this;
    var title1="Are you sure you want to delete this device?";
    var text1="You will not be able to recover this record";
    var title2="Deleted!";
    var text2="Your device has been deleted.";
    var text3="Cancel";
    var text4="Remove";
    var text5="Ok";
    this.translate.get(title1).subscribe(res => {
      title1=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(title2).subscribe(res => {
      title2=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    this.translate.get(text3).subscribe(res => {
      text3=res;
    });
    this.translate.get(text4).subscribe(res => {
      text4=res;
    });
    this.translate.get(text5).subscribe(res => {
      text5=res;
    });
    swal({
      title: title1,
      text: text1,
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text3,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text4,
      closeOnConfirm: true
    },
    function(){
      let devicedata: any;
      devicedata={"deviceId": deviceId };
      
      self.apiService.deleteDevice(devicedata).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
           // self.users = response["data"];
            //self.dtTrigger.next()
            swal({
              title: title2,
              text: text2,
              type:'success',
              confirmButtonText: text5,
            });
            // window.location.reload();
           // self.rerender();
           $('#exampleDevices').DataTable().ajax.reload( null, false );
          }
        else {
            // show errors message
            console.log(response["error"]+response["message"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
     
    });
  }
  }

  rerender(): void {
    self.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
      self.getAllDevicesLastInfo();
    });
  }
  showDriversAssignDevice(deviceId){
    
    this.selectedDriverName="";
    this.selectedDriverId=0;
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.get_all_drivers_devices(deviceId).subscribe((response) => {
      if (response["error"] == 0) {
          this.driverData=response['details'];
          console.log(response['details']);
          this.selectedDriverName=this.driverData[0].name;
          this.selectedDriverId=this.driverData[0].id;
          //console.log(this.driverData.name);
         // console.log(this.driverData.id);
         

          //self.devices = response["data"];
          //self.dtTrigger.next();

        }
      else {
       //Dname='No device'
      
          // show errors message
          console.log(response["error"]);
      }
      },
      error => {
          // this.alertService.error(error);
          console.log("error" + error);
          // this.loading = false;
      });

     
    this.apiService.get_all_drivers_not_assign_devices().subscribe((response) => {
      if (response["error"] == 0) {
          self.drivers = response["data"];
          //self.dtTrigger.next();
          console.log(self.drivers);
          this.deviceid=deviceId;
         
        }
      else {
          // show errors message
          
          console.log(response["error"]);
      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
}
  
  }
  changeDriver(){
    console.log("driver.id : " + this.selectedDriverId);
    if(this.selectedDriverId==null || this.selectedDriverId== undefined){
      this.selectedDriverId=0;

    }
  }
  assignDriver(){
    
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.assignDriverToDevice(this.selectedDriverId,this.deviceid).subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
         
         // if(this.selectedDriverId!=""){
            this.translate.get("Driver was assigned successfully").subscribe(res => {
              $.notify({message: res}, {type: 'success'});
            });
         /* } 
          if(this.DriverID==""){
            this.translate.get("Updated successfully.").subscribe(res => {
              $.notify({message: res}, {type: 'success'});
            });
          } */
          //self.rerender();
          $('#exampleDevices').DataTable().ajax.reload( null, false );
        }
      else {
          // show errors message
          this.translate.get("no data entered").subscribe(res => {
            $.notify({message: res}, {type: 'danger'});
          });
          console.log(response["error"]);
        //  self.rerender();
        $('#exampleDevices').DataTable().ajax.reload( null, false );
      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
    }

  }

  
  showGeofencesAssignDevice(deviceId){
   var nameg='';
   this.selectedGeofenceNames=[];
   this.geofenceData=[];
   
   if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
   
    this.apiService.getAllGeofences().subscribe((response) => {
      if (response["error"] == 0) {
          self.geofences = response["deta"];
          //self.dtTrigger.next();
         
          console.log(self.geofences);
        //  this.selectedGeofenceNames=["68"];
        this.apiService.get_all_geofences_devices(deviceId).subscribe((response) => {
          if(response["error"] == 0) {
             for(var i=0;i<response['details'].length;i++)
             {
                 this.geofenceData.push(response['details'][i].id);
             }
             this.selectedGeofenceNames=this.geofenceData;
             console.log("old selected"+this.selectedGeofenceNames);
             /* for (var k = 0; k <response['details'].length; k++) {
              
                this.geofenceData=response['details'][k];
              }*/
              //this.Gname=this.geofenceData[1].name;
             /* this.geofenceData.forEach(element => {
                console.log("geofenceData: "+ element);
              });*/
    

           
            }
          else {
            nameg='No device'
              // show errors message
              console.log(response["error"]);
          }
          },
          error => {
              // this.alertService.error(error);
              console.log("error" + error);
              // this.loading = false;
          });
    
     
          //console.log(self.drivers[1].name);
          this.deviceid=deviceId;
  
         
        }
      else {
          // show errors message
          
          console.log(response["error"]);
          console.log(response["message"]);

      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
}
  }
  changeGeofence(){
    //console.log("driver.id : " + this.GeofenceID);
   // console.log("driver.id : " + this.GeofenceID[0]);
    console.log("selectedgeofence"+this.selectedGeofenceNames);
   /* if(this.DriverID==null || this.DriverID=="null"||this.DriverID=="undefined" || this.DriverID== undefined){
      this.DriverID="";

    }*/
  }
  assignGeofence(){
    var geo="";
    if(this.selectedGeofenceNames.length>0){
      for(var i=0;i<this.selectedGeofenceNames.length;i++){
        if(i!=0){
          geo+=',';
        }
        geo+=this.selectedGeofenceNames[i];

      }
    }
    console.log("Geo: "+this.GeofenceID.length);
    console.log("Geo: "+geo);
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.assignGeofenceToDevice(geo,this.deviceid).subscribe((response) => {
      if (response["error"] == 0) {
          console.log(response);
         // response["success"];
          //self.dtTrigger.next();
          if(geo==""){
            this.translate.get("Updated successfully.").subscribe(res => {
              $.notify({message: res}, {type: 'success'});
            });

          }
          else{
            this.translate.get("Geofence was assigned successfully").subscribe(res => {
              $.notify({message: res}, {type: 'success'});
            });  

          }
                
           //self.rerender();
           $('#exampleDevices').DataTable().ajax.reload( null, false );
        }
      else {
          // show errors message
          this.translate.get("no data entered").subscribe(res => {
            $.notify({message: res}, {type: 'danger'});
          });
          console.log(response["error"]);
          //self.rerender();
          $('#exampleDevices').DataTable().ajax.reload( null, false );
          

      }
  },
  error => {
      // this.alertService.error(error);
      console.log("error" + error);
      // this.loading = false;
  });
    }
  }
  showCalibrationTableData(deviceId)
  {
    this.zone.run(() => this.router.navigate(['caliberationTable'],{ queryParams: { id: deviceId } ,skipLocationChange: false }));
  }
  connectionToElm(deviceId)
  {
    Swal({
      title: 'Are you sure you want to send this Vehicle to Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let devicedata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        devicedata={"deviceId": deviceId };
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/devices_registration', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(devicedata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
            //self.rerender();
            $('#exampleDevices').DataTable().ajax.reload( null, false );
          }
          
        })
      }
    })
  }
  verfiyWithElm(deviceId)
  {
    Swal({
      title: 'Are you sure you want to verfiy this Vehicle with Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let devicedata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        devicedata={"deviceId": deviceId };
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/device_inquery_service', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(devicedata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
           // self.rerender();
          }
          
        })
      }
    })
  }
  updateIMEI(deviceData){
    // console.log(deviceData['sequenceNumber']);
    // console.log(deviceData['uniqueid']);
    Swal({
      title: 'Are you sure you want to update Vehicle IMEI with Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let devicedata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        devicedata={"sequenceNumber": deviceData['sequenceNumber'],"imeiNumber": deviceData['uniqueid']};
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/update_vehicle_imei', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(devicedata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
           // self.rerender();
          }
          
        })
      }
    })


  }

}
