import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ApiService } from '../../network/api.service';
import { Driver } from '../../shared/drivers';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as swall from '../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'
import { DataTableDirective } from 'angular-datatables';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import {  HttpHeaders} from  '@angular/common/http';
import Swal from 'sweetalert2';
declare var $ :any;
var self;
@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  drivers: Driver[];
  Edit="Edit";
  dir="";
  dirButton="";
  dirtable="";
  Delete="Delete";
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Driver> = new Subject();
  isMaster:boolean;
  
  constructor(private local:LocalStorageService,
    public translate: TranslateService,private apiService: ApiService,private router: Router,private zone: NgZone) { }

  ngAfterViewInit(){
    
  }
  getConnections(){
    this.isMaster=false;
    var user=this.local.get('currentUser');
    user = JSON.parse(user);
    var userid=user['data']['id'];
    if(userid==53)
    {
      this.isMaster=true;
    }
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
  ngOnInit() {
    
    this.getConnections();
    self = this;
    
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    self.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: false,
      lengthChange: false,
      sort:false
    };
    if(currentLan=='en'){
      this.dir="ltr";
      this.dirButton="rtl";
      self = this;
      
      
    }
    else if(currentLan=='ar'){
      this.dir="rtl";
      this.dirButton="ltr";
      this.dirtable="ltr";
     
    }
    
    self.drivers = [];
    self.getAllDevicesLastInfo();
  }

  getAllDevicesLastInfo(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getAllDrivers().subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.drivers = response["data"];
            if(self.drivers.length==0){
              self.drivers = [];
            }
            else{
              self.dtTrigger.next();
            }

          }
        else {
            // show errors message
            console.log(response["error"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
  }
  }

  goCreateDriver(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['createdriver']);
    }
  }

  goUpdateDriver(driverId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.zone.run(() => this.router.navigate(['createdriver'],{ queryParams: { id: driverId } ,skipLocationChange: false }));
    }
  }

  showDeleteAlert(driverId){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    self=this;
    var title1="Are you sure you want to delete this driver?";
    var text1="You will not be able to recover this record";
    var title2="Deleted!";
    var text2="Your driver has been deleted.";
    var text3="Cancel";
    var text4="Remove";
    var text5="Ok";

    this.translate.get(title1).subscribe(res => {
      title1=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(title2).subscribe(res => {
      title2=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    this.translate.get(text3).subscribe(res => {
      text3=res;
    });
    this.translate.get(text4).subscribe(res => {
      text4=res;
    });
    this.translate.get(text5).subscribe(res => {
      text5=res;
    });
    swall({
      title: title1,
      text: text1,
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text3,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text4,
      closeOnConfirm: true
    },
    function(){
    
      let driverdata: any;
      driverdata={"driverId": driverId };
      
      self.apiService.deleteDriver(driverdata).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
           // self.users = response["data"];
            //self.dtTrigger.next()
            swall({
              title: title2,
              text: text2,
              type:'success',
              confirmButtonText: text5,
            });
            self.rerender();
          }
        else {
            // show errors message
            console.log(response["error"]+response["message"]);
        }
    },
    error => {
        // this.alertService.error(error);
        console.log("error" + error);
        // this.loading = false;
    });
    });
  }
  }
  connectionToElm(driverId)
  {
    Swal({
      title: 'Are you sure you want to send this Driver to Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let driverdata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        driverdata={"driverId": driverId };
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/drivers_registration', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(driverdata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
            self.rerender();
          }
          
        })
      }
    })
  }

  rerender(): void {
    self.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      // this.dtTrigger.next();
      self.getAllDevicesLastInfo();
    });
  }
  verfiyWithElm(driverId)
  {
    Swal({
      title: 'Are you sure you want to verfiy this Driver with Elm?',
     
      inputAttributes: {
        autocapitalize: 'Cancel'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let driverdata: any;
        var currentUser = this.local.get('currentUser'); 
        currentUser = JSON.parse(currentUser);
        currentUser = currentUser["data"];
        var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });
        driverdata={"driverId": driverId };
      
        return fetch('https://track.sareb.co/sareb_backend/Gold_cruds/driver_inquery_service', {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(driverdata), // data can be `string` or {object}!
          headers:{
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
          }
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json()
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal({
          title:result.value.message,
          preConfirm: () => {
           // self.rerender();
          }
          
        })
      }
    })
  }

}
