import { Component, OnInit, AfterViewInit,ChangeDetectionStrategy, Input } from '@angular/core';
import { Geofence } from '../../../shared/geofences';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../network/api.service';
import { Router } from '@angular/router';
declare var jquery:any;
declare var $ :any;
import { ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonModule} from '@angular/common';
import { CallBack } from 'google-maps';
import { MarkerClusterer } from '@google/markerclusterer';
import { first } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import * as Swal from '../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'

var self;
var map;
var Gname;
var Garea;
var shape;
var Gtype;
var patterns=[];
var shapearea;
var oldshape;
var name;
var center:any;
var points:any=[];
var shape_points=[];
var raduis;
var shape_center=[];
var lat;
var lng;
var type;

var selectedDevice ;
var cityCircle=new google.maps.Circle();
var bermudaTriangle=new google.maps.Polygon();
var flightPath = new google.maps.Polyline();
declare function require(path: string): any;
import * as swal from '../../../../assets/vendors/bootstrap-sweetalert/dist/sweetalert.min.js'


@Component({
  selector: 'app-geofencescreate',
  templateUrl: './geofencescreate.component.html',
  styleUrls: ['./geofencescreate.component.css']
})
export class GeofencescreateComponent implements AfterViewInit,OnInit {
  @Input() geoId: number;
  geofence: Geofence;
  geofenceForm: FormGroup;
  submitted:boolean;
  dublicated:boolean;
  maped:boolean;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;
  Select_Type="";
  Circle="";
  Polygon="";
  Polyline="";
  
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Geofence> = new Subject();
  constructor(private local:LocalStorageService ,public translate: TranslateService,private formBuilder: FormBuilder,private apiService: ApiService,private router: Router) {
    this.geofence=new Geofence();
    

   }
   ngAfterViewInit() {
   }
   getConnections(){
    this.subscriptions=[];
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
      location.reload();*/
      }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
    }));
   
  }
   countryForm: FormGroup;
   getGeofencebyId(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.apiService.getGeofence(this.geoId).subscribe((response) => {
      if (response["error"] == 0) {
          //console.log("respose: "+response["data"][0]);
          this.geofence = response["data"];
          oldshape=this.geofence.type;
          Gtype=this.geofence.type;
          Garea=this.geofence.area;
          shapearea=this.geofence.area;
          Gname=this.geofence.name;
         // geo_id=this.geofence.id;
          /*console.log("respose oldshape: "+oldshape);
          console.log("respose shapearea: "+shapearea);
          console.log("respose name: "+Gname);*/
          
         
        this.onSelect();

        }
      else {
          
          console.log("Error : "+response["error"]);
          oldshape="";
          this.onSelect();

      }
  },
  error => {
      console.log("error" + error);
  });
    }
  }
  ngOnInit() {
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
     this.Select_Type="Select Type";
     this.Circle="Circle";
     this.Polygon="Polygon";
     this.Polyline="Polyline";

    }
    else if(currentLan=='ar'){
      this.Select_Type="أختار نوع";
      this.Circle="دائري";
      this.Polygon="مضلع";
      this.Polyline="متعدد الخطوط";
    }
    this.geofenceForm = this.formBuilder.group({
      geofenceName: ['', [Validators.required,,Validators.pattern]],
      geofenceType: ['', [Validators.required]],
      geofenceArea: ['', [Validators.required]]
      
  });
  this.getConnections();
    this.getGeofencebyId();
    
  }
 
  onSelect(){
    console.log(this.geofenceForm.get('geofenceType').value);
    shape= this.geofenceForm.get('geofenceType').value;
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
     this.clear_map();
     this.initMap();
    }

  } 

  get formFields() { return this.geofenceForm.controls; }
  onSubmit() {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.dublicated=false;
    this.submitted = true;
    self = this;
    self.geofence = [];
    name= this.geofenceForm.get('geofenceName').value; 
    console.log("name"+name);
    shape= this.geofenceForm.get('geofenceType').value;
    this.geofence['name']=name; 
    this.geofence['type']=shape;
    var wktformat="";
  
    if(shape=="Circle"||shape=="circle"){
      wktformat="CIRCLE((";
    }
    else if(shape=="Polygon"||shape=="polygon"){
      wktformat="POLYGON((";
    }
    else if(shape=="Polyline"||shape=="polyline"){
      wktformat="LINESTRING((";
    }
    for(var i=0;i<points.length;i++)
    {
      if(i==points.length-1)
      {
        wktformat +=points[i]+"))";
      }
      else
      {
        wktformat +=points[i]+",";	
      }

    }
    this.geofence['area']=wktformat;
    
    console.log("wktformat: "+wktformat);
    if(points.length==0){
      self.maped=true;
     
    }
    if(points.length!=0){
      self.maped=false;
     
    }
    if(points.length==0||name==''||shape==''){
     
    }
    else{
      this.addGeofenceService();
    }
  }
      
      
    
    

  }
  addGeofenceService(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
   console.log("type: "+type);
   if(!this.formFields.geofenceName.errors&&!this.formFields.geofenceType.errors){
      this.apiService.addGeofence(this.geofence,this.geoId).subscribe((response) => {
        if (response["error"] == 0) {
            console.log(response);
            self.geofences = response["details"];
            self.dtTrigger.next();
            this.translate.get(response["message"]).subscribe( res=> {
              $.notify({message: res}, {type: 'success'});
            });

            this.router.navigate(['geofences']);



        }
        
        else {
          //$.notify({ message:response["message"]}, {type:"danger"});
          console.log("dublicated");

          this.dublicated=true;
      
        
        }
      
    },
    error => {
        console.log("error" + error);
    });
  }
  }
  }
  clear_map(){  
    points=[];
    type="";
    cityCircle.setMap(null);
    bermudaTriangle.setMap(null); 
    flightPath.setMap(null);
  }	


  initMap(){
    center = {lat: 24.7241504, lng: 46.2620616};
    map = new google.maps.Map(
      document.getElementById('map'), {zoom: 7, center: center});
    shape=this.geofenceForm.get('geofenceType').value;
    var shape_center=[];
    google.maps.event.addListener(map, 'click' ,function(event){
      if(shape=="Circle"||shape=="circle"){
        points=[];
        cityCircle.setMap(null);
        var lat = event.latLng.lat();
        var lng = event.latLng.lng();
        shape_center=[];
        shape_center.push({lat: lat,lng: lng});
       draw(shape_center,'',shape);

      }
      else if(shape=="Polygon"||shape=="polygon"){
        lat = event.latLng.lat();
        lng = event.latLng.lng();
       shape_center.push({lat: lat,lng: lng});
      draw(shape_center,'',shape);
        
      }
      else if(shape=="Polyline"||shape=="polyline"){
        lat = event.latLng.lat();
        lng = event.latLng.lng();
       shape_center.push({lat: lat,lng: lng});
       draw(shape_center,'',shape);
        
      }
    });
      
      drawold();
      oldshape="";
  function drawold()
  {
    var shape_center=[];
    console.log("htsvcsaav >> "+oldshape);
    if(oldshape!="")
    {
         type="old";
         console.log("type"+oldshape);
         
          shapearea=shapearea.replace( 'POLYGON', '');
          shapearea=shapearea.replace( 'CIRCLE', '');
          shapearea=shapearea.replace( 'LINESTRING', '');
          shapearea=shapearea.replace( ')', '');
          shapearea=shapearea.replace( ')', '');
          shapearea=shapearea.replace( '(', '');
          shapearea=shapearea.replace( '(', '');
          shapearea=shapearea.split(',');
          var new_area = new Array();
          
          for (var k = 0; k < shapearea.length; k++) {
            shapearea[k]=shapearea[k].split(' ');
            new_area.push(shapearea[k]);

          }

          console.log("new_area: "+new_area[0]);
      if(oldshape =="polygon"||oldshape =="Polygon")
      {
        
                  for(var i=0;i<shapearea.length;i++)
                  {
                  shape_center.push({lat: parseFloat(shapearea[i][0]),lng: parseFloat(shapearea[i][1])});
                  draw(shape_center,'',"polygon");
                  
                  }
                  
                  
      }
      else if(oldshape=="circle"||oldshape=="Circle")
      {         
                shape_center.push({lat: parseFloat(shapearea[0][0]),lng: parseFloat(shapearea[0][1])});
                console.log("shape_center"+shapearea[0][0]);

            draw(shape_center,parseFloat(shapearea[1]),"circle");
                
      }
      else if(oldshape=="Polyline"||oldshape=="polyline")
      {
                  for(var i=0;i<shapearea.length;i++)
                  {
                  shape_center.push({lat: parseFloat(shapearea[i][0]),lng: parseFloat(shapearea[i][1])});
                  }
                  draw(shape_center,'',"polyline");
      }
    }
    else{
      console.log(" new");

    }
   
  }
  function draw(center,population,shape){
    shape_points=[];
    if(shape=="Circle"||shape=="circle"){
      if (!population){
        population= Math.sqrt(10) * 100;
      }
      cityCircle = new google.maps.Circle({
      editable: true,
      draggable: true,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: map,
      center:center[0],
      radius:population
      });
      center=center[0].lat+" "+center[0].lng;
      raduis=10;
      if (population)
      {
        raduis=population;
      }
      points.push(center);
      points.push(raduis);
      google.maps.event.addListener(cityCircle, 'radius_changed', function() {
      raduis=cityCircle.getRadius();  	   
      points[1]=raduis;
      });
      google.maps.event.addListener(cityCircle, 'center_changed', function() {
      center=cityCircle.getCenter().lat()+" "+cityCircle.getCenter().lng() ;  	   
      points[0]=center;
      });
      console.log("circle: "+points);
    
    }
  
  else if(shape=="Polygon"||shape=="polygon"){

     bermudaTriangle = new google.maps.Polygon({
      editable: true,
      paths: center,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: '#FF0000',
      fillOpacity: 0.35
    });
    
    bermudaTriangle.setMap(map);
    var pointToAdd = bermudaTriangle.getPath().getAt(bermudaTriangle.getPath().getLength()-1);
    var str =  pointToAdd.lat() + " "+ pointToAdd.lng();

    points.push(str);
  
    google.maps.event.addListener(bermudaTriangle.getPath(), 'set_at', function() {
 
  
    shape_points.push(bermudaTriangle.getPath().getArray());
          
    for(var j=0;j<shape_points.length;j++)
    {
      
      for (var i=0;i<shape_points[j].length;i++)
      {
          pointToAdd=shape_points[j][i];
          
          str =  pointToAdd.lat() + " "+ pointToAdd.lng();
                      
          points.push(str);

      }
    }

    
    
  });

  google.maps.event.addListener(bermudaTriangle.getPath(), 'insert_at', function() {
    //shape_points.pop();
    shape_points.push(bermudaTriangle.getPath().getArray());
          
    for(var j=0;j<shape_points.length;j++)
    {
      
      for (var i=0;i<shape_points[j].length;i++)
      {
          pointToAdd=shape_points[j][i];
          
          str =  pointToAdd.lat() + " "+ pointToAdd.lng();
        
          
                      
        points.push(str);

      }
    }

  });
  console.log("Polygin : "+points);


  }
  else if(shape=="Polyline"||shape=="polyline"){
      flightPath = new google.maps.Polyline({
      editable: true,
      path: center,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    flightPath.setMap(map);
    var pointToAdd = flightPath.getPath().getAt(flightPath.getPath().getLength()-1);
    var str =  pointToAdd.lat() + " "+ pointToAdd.lng();
    points.push(str);
    google.maps.event.addListener(flightPath.getPath(), 'set_at', function() {
      
    shape_points.push(flightPath.getPath().getArray());
            
    for(var j=0;j<shape_points.length;j++)
    {
      
      for (var i=0;i<shape_points[j].length;i++)
      {
          pointToAdd=shape_points[j][i];
          
          str =  pointToAdd.lat() + " "+ pointToAdd.lng();
                      
          points.push(str);

      }
    }
    });

    google.maps.event.addListener(flightPath.getPath(), 'insert_at', function() {
    shape_points.push(flightPath.getPath().getArray());
            
    for(var j=0;j<shape_points.length;j++)
    {
      
      for (var i=0;i<shape_points[j].length;i++)
      {
          pointToAdd=shape_points[j][i];
          
          str =  pointToAdd.lat() + " "+ pointToAdd.lng();
                      
          points.push(str);

      }
    }
    });
    console.log("str : "+str);
    console.log("Polyline : "+points);

        
  }

}

  }
  
  /*clearGeofence()
  {
   this.geofenceForm.reset();
   center = {lat: 24.7241504, lng: 46.2620616};
   map = new google.maps.Map(
     document.getElementById('map'), {zoom: 7, center: center});
  }*/
  
  goToBackAdd()
  {
   var self=this;
  
   if(this.connectionStatus=="offline")
   {
     this.translate.get(this.connectionStatusMessage).subscribe(res => {
       $.notify({ message:res}, {type:"danger"});
     });
   }
   else{
    // console.log(this.formFields.geofenceArea.value);
    if(this.formFields.geofenceName.value!=""|| this.formFields.geofenceType.value!=""){
    var title="Do you want to disacard changes?";
    var text1="Disacard";
    var text2="Cancel";
    this.translate.get(title).subscribe(res => {
      title=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    Swal({
      title:title,
      text: "",
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text2,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text1,
      closeOnConfirm: true
    },
    function(){
      window.history.go(-1);
    });
   }
   else{
    window.history.go(-1);
  
   }
  }
  
    
  }
    
  goToBackEdit()
  {
   var self=this;
  
   if(this.connectionStatus=="offline")
   {
     this.translate.get(this.connectionStatusMessage).subscribe(res => {
       $.notify({ message:res}, {type:"danger"});
     });
   }
   else{
    
    /*console.log("respose oldshape1: "+Gtype);
    console.log("respose shapearea1: "+Garea);
    console.log("respose formArea: "+ this.formFields.geofenceArea.value);
    console.log("respose name1: "+Gname);*/
    var wktformat="";
  
    if(Gtype=="Circle"||Gtype=="circle"){
      wktformat="CIRCLE((";
    }
    else if(Gtype=="Polygon"||Gtype=="polygon"){
      wktformat="POLYGON((";
    }
    else if(Gtype=="Polyline"||Gtype=="polyline"){
      wktformat="LINESTRING((";
    }
    for(var i=0;i<points.length;i++)
    {
      if(i==points.length-1)
      {
        wktformat +=points[i]+"))";
      }
      else
      {
        wktformat +=points[i]+",";	
      }

    }
    //console.log("Area: "+wktformat);

     if(this.formFields.geofenceName.value!= Gname || this.formFields.geofenceType.value!=Gtype
      || wktformat!= Garea ){
    var title="Do you want to disacard changes?";
    var text1="Disacard";
    var text2="Cancel";
    this.translate.get(title).subscribe(res => {
      title=res;
    });
    this.translate.get(text1).subscribe(res => {
      text1=res;
    });
    this.translate.get(text2).subscribe(res => {
      text2=res;
    });
    Swal({
      title:title,
      text: "",
      type: "error",
      showCancelButton: true,
      cancelButtonClass: "btn-default",
      cancelButtonText: text2,
      confirmButtonClass: "btn-danger",
      confirmButtonText: text1,
      closeOnConfirm: true
    },
    function(){
      window.history.go(-1);
    });
   }
   else{
    window.history.go(-1);
  
   }
  }
  
    
  }
    
}
