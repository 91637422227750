import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {fromEvent,EMPTY , Observable, Subscription} from 'rxjs';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Device } from 'src/app/shared/devices';
import { ApiService } from '../../network/api.service.js';
//npm install angular-admin-lte --save

declare var $ :any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus="";
  dir=""
  isAdmin:boolean;
  isMaster:boolean;
  accountType:number;
  devices:Device[];
  constructor(private local:LocalStorageService,private translate: TranslateService,private router: Router,
    private apiService: ApiService) {
   
   }
  
  
  ngOnInit() {
    
    this.isAdmin=false;
    this.isMaster=false;
    var user=this.local.get('currentUser');
    user = JSON.parse(user);
    var userid=user['data']['id'];
    if(userid==1)
    {
      this.isAdmin=true;
    }
    if(userid==53)
    {
      this.isMaster=true;
    }
   
    var currentLan = this.local.get('lan');
    currentLan = JSON.parse(currentLan);
    if(currentLan=='en'){
      this.dir="ltr";
    }
    else if(currentLan=='ar'){
      this.dir="rtl";

    }
    this.onlineEvent=null;
    this.offlineEvent=null;
   // console.log("onlineEvent: "+this.onlineEvent);
    //console.log("offlineEvent: "+this.offlineEvent);
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.pop();
    //console.log("SubPOP: "+this.subscriptions.length);
    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
     /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"success"});
      });
        location.reload();*/
        
      }));
     
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';

      
      /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });*/
     
    }));
     
  }


  goUsers=function(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.router.navigate(['users']);
    }
  }

  goDevices=function(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.router.navigate(['devices']);
    }
  }
  goDashboard=function(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.router.navigate(['main']);
    }
  }

  goDrivers=function(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
      this.router.navigate(['drivers']);
    }
  }
  goGeofences=function(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['geofences']);
    }
  }
  goTripReport(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['tripreport']);
    }
  }
  goStopReport(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['stopreport']);
    }
  }
  goEventReport(){
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['eventreport']);
    }
  }
  godeviceWorkingHours()
  {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['deviceworkinghours']);
    }
  }
  godriverWorkingHours()
  {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['driverworkinghours']);
    }
  }
  goelmconnections()
  {
    if(this.connectionStatus=="offline")
    {
      this.translate.get(this.connectionStatusMessage).subscribe(res => {
        $.notify({ message:res}, {type:"danger"});
      });
    }
    else{
    this.router.navigate(['elmconnections']);
    }
  }
}
