import {OnDestroy, Component, OnInit, AfterViewInit ,ViewChildren,QueryList,Directive} from '@angular/core';
import * as homescripts from './homescripts.js';
import { ApiService } from '../../network/api.service.js';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Dashboarddevicesstatusnumbers } from './dashboarddevicesstatusnumbers';
import { Device } from '../../shared/devices.js';
import { EventReportModel } from '../../shared/eventreportmodel';
import { ConfigVariables } from '../../shared/config.js';
import { fromEvent,timer, Observable, Subject , interval, Subscription } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';
import { CommonModule} from '@angular/common';
import { CallBack } from 'google-maps';
import * as MarkerClusterer from '@google/markerclusterer'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'cfb/types';
import { DataTableDirective } from 'angular-datatables';
import * as Chart from 'chart.js';
import { element } from '@angular/core/src/render3';
import {NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap'


// import * as Chartist from 'chartist';
//import * as Chartist from '../../../assets/vendors/chartist/dist/chartist.min.js';
//import * as ChartistTooltips from '../../../assets/vendors/chartist-plugin-tooltip/dist/chartist-plugin-tooltip.min.js';

//import *  as tooltip from  'chartist-plugin-tooltips';

var self;
var map;
declare var $ :any;
declare function require(path: string): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
@Directive({
    selector: '[myChart,myBarChart]'
  })
export class DashboardComponent implements AfterViewInit, OnInit {
    @ViewChildren(DataTableDirective)
    dtElements: QueryList<DataTableDirective>; 
    devices: Device[];
    device: Device;
    vehicleData: Device[];
    notifications:EventReportModel[];
    ModalDeviceId=0;
    deviceIntervalIds=[];
    deviceDetails:[];
    deviceData:[];
    deviceMap;
    driverName="";
    driverId="";
    deviceName="";
    devicename="";
    deviceId="";
    uniqId="";
    squence="";
    platnum="";
    platetype="";
    latitudekey="latitude";
    latitudevalue="";
    longitudekey="longitude";
    longitudevalue="";
    ignitionkey="ignition";
    ignitionvalue="";
    motionkey="motion";
    motionvalue="";
    powerkey="power";
    powervalue="";
    batterykey="battery";
    batteryvalue="";
    alarmkey="alarm";
    alarmvalue="";
    totalDistancekey="totalDistance";
    totalDistancevalue="";
    hourskey="hours";
    processing="";
    hoursvalue="";
    speedkey="speed";
    speedvalue="";
    dis=0;
    hou=0;
    left_letter="";
    middle_letter= "";
    right_letter= "";
    plate:string="";
    online="";
    offline="";
    out_of_network="";
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions: Subscription[] = [];
    connectionStatusMessage: string;
    connectionStatus: string;
    subscription: Subscription;
    markers :any = [];
    markerCluster:MarkerClusterer;
    dir="";
    dirtable="";                  
    currentLan = "";
    deviceimageURL:String;
    isCollapsed : Boolean;
    dashboardIntervalsIds=[];
    isMaster:boolean;
    public dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Device> = new Subject();
    public dtOptionss: DataTables.Settings = {};
    dtTriggerr: Subject<Device> = new Subject();
    public dtOptionsNotifications: DataTables.Settings = {};
    dtTriggerNotifications: Subject<EventReportModel> = new Subject();
    public dtOptionsVehicleData: DataTables.Settings = {};
    dtTriggerVehicleData: Subject<EventReportModel> = new Subject();
    public dashboarddevicesstatusnumbers = new Dashboarddevicesstatusnumbers();
    constructor(public translate: TranslateService,
        private local:LocalStorageService,
        private session:SessionStorageService,
        private apiService: ApiService ,
         private  httpClient:  HttpClient,private router: Router) { 
        this.isCollapsed=false;
           
    }
    
    getConnections(){
        this.subscriptions=[];
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.push(this.onlineEvent.subscribe(e => {
          this.connectionStatusMessage = 'Back to online';
          this.connectionStatus = 'online';

          /*this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"success"});
          });
          location.reload();*/
         }));

    
        this.subscriptions.push(this.offlineEvent.subscribe(e => {
          this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
          this.connectionStatus = 'offline';
         
          
         }));
    
      }
 
    ngOnInit(){
        self = this;
        self.currentLan = this.local.get('lan');
        self.currentLan = JSON.parse(self.currentLan);
        this.isMaster = false;
        var user=this.local.get('currentUser');
        user = JSON.parse(user);
        var userid=user['data']['id'];
        if(userid==53)
        {
          this.isMaster=true;
        }
        this.getConnections();
        self.OnlineAndOffline();
        self.getAllDevicesLastInfo();
        this.devicesDataTable();
        if(!this.isMaster){
            this.notificationsDataTable();
        }
        
        this.deviceModalDataTable();
        this.getVehicleData();
        var ctx = document.getElementById('myChart');
       
        var currentLan = this.local.get('lan');
       currentLan = JSON.parse(currentLan);
   
   
      if(self.currentLan=='en'){
        self.online="ONLINE";
        self.offline="OFFLINE";
        self.out_of_network="OUT OF NETWORK";
      }
      else if(self.currentLan=='ar')
      {
        self.online="متصل";
        self.offline="غير متصل";
        self.out_of_network="خارج التغطية";
      }
      
       /* self.dtOptionss = {
            pagingType: 'numbers',
            pageLength: 10,
            responsive: false,
            lengthChange: false,
            searching: false,
            info:true,
            sort:false,
            processing: true,
            oLanguage: {
    
                "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                
               },
            
          };*/
          
        
        if(self.currentLan=='en'){
          this.dir="ltr";
          /*self.dtOptionsNotifications = {
            pagingType: 'numbers',
            pageLength: 10,
            responsive: false,
            lengthChange: false,
            searching: true,
            info:true,
            sort:false,
          };*/
         /* */
        }
        else if(self.currentLan=='ar'){
          this.dir="rtl";
          this.dirtable="ltr";
         /* self.dtOptionsNotifications = {
            pagingType: 'numbers',
            pageLength: 10,
            responsive: false,
            lengthChange: false,
            searching: true,
            info:true,
            sort:false,
            oLanguage: {
          
                "sSearch": ' بحث ',
                "oPaginate": {
                  "sFirst":    "الأولي",
                  "sLast":     "الاخيرة",
                  "sNext":     "القادمة",
                  "sPrevious": "السابقة" 
              },
              "sInfo":           "عرض من _START_ إلي _END_ من أجمالي _TOTAL_ بيانات",
              "sInfoEmpty":      "عرض من 0 إلي 0 من إجمالي 0 البيانات",
             },
          };*/
         /* self.dtOptionss = {
            pagingType: 'numbers',
            pageLength: 10,
            responsive: false,
            lengthChange: false,
            searching: false,
            info:true,
            sort:false,
            oLanguage: {
          
                "sSearch": ' بحث ',
                "oPaginate": {
                  "sFirst":    "الأولي",
                  "sLast":     "الاخيرة",
                  "sNext":     "القادمة",
                  "sPrevious": "السابقة" 
              },
              "sInfo":           "عرض من _START_ إلي _END_ من أجمالي _TOTAL_ بيانات",
              "sInfoEmpty":      "عرض من 0 إلي 0 من إجمالي 0 البيانات",
             },
          };*/
        }
       //this.getConnections();
       //
       //
     // 
        //self.getTodayNotifications();
      
        if(this.connectionStatus=="offline")
        {
         /* this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });*/

        }
        else{
       
         /* console.log(this.processing);
          var currentLan = this.local.get('lan');
          currentLan = JSON.parse(currentLan);
          if(currentLan=='en'){
           
              self.dtOptionsNotifications = {
                pagingType: 'full_numbers',
                pageLength: 10,
                lengthChange: false,
                responsive: true,
                serverSide : true,
                processing: true,
                searching: false,
                sort:false,
                oLanguage: {
            
                    "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                    
                   },
                
                ajax: (dataTablesParameters: any, callback) => {
                    var currentUser = this.apiService.getUserToken();
                var headerss = new HttpHeaders({
                  'content-type' : 'application/json',
                  'TOKEN': currentUser["token"], 
                  'Accept': 'application/json'
                });
    
                if(this.connectionStatus=="offline")
                {
                  this.translate.get(this.connectionStatusMessage).subscribe(res => {
                    $.notify({ message:res}, {type:"danger"});
                  });
                }
                else{
                    this.httpClient.get(`${ConfigVariables.API_URL}/get_notifiction_current_day?offset=${dataTablesParameters["start"]}`,{headers:headerss })
                      .subscribe(resp => {
                        console.log( dataTablesParameters["start"]);
                        console.log( resp["data"]);
                        console.log( resp["data"].length);
                        console.log(  resp["dataLength"]);
                        
                        if(resp["data"].length>0){
                            self.allnotifications=resp["data"];

                        }
                        else if(resp["data"].length==0){
                            self.allnotifications=[];
                        }
                        
                        callback({
                            recordsTotal: resp["dataLength"],
                            recordsFiltered: resp["dataLength"],
                            data: []
                          });   
                        
                      });
                    }
                  },
    
              };
             
          }
          else if(currentLan=='ar'){
           /* self.dtOptions = {
                pagingType: 'full_numbers',
                pageLength: 10,
                lengthChange: false,
                responsive: true,
                serverSide : true,
                processing: true,
                searching: false,
                sort:false,
                
                oLanguage: {
            
                    "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                    ,"oPaginate": {
                        "sFirst":    "الأولي",
                        "sLast":     "الاخيرة",
                        "sNext":     "التالية",
                        "sPrevious": "السابقة" 
                    },
                    "sInfo":           "عرض من _START_ إلي _END_ من إجمالي _TOTAL_ بيانات",
                    "sInfoEmpty":      "عرض من 0 إلي 0 من إجمالي 0 البيانات",
                   },
                
                
                ajax: (dataTablesParameters: any, callback) => {
                    var currentUser = this.apiService.getUserToken();
                var headerss = new HttpHeaders({
                  'content-type' : 'application/json',
                  'TOKEN': currentUser["token"], 
                  'Accept': 'application/json'
                });
    
                if(this.connectionStatus=="offline")
                {
                  this.translate.get(this.connectionStatusMessage).subscribe(res => {
                    $.notify({ message:res}, {type:"danger"});
                  });
                }
                else{
                    
                    this.httpClient.get(`${ConfigVariables.API_URL}/all_devices_last_info?offset=${dataTablesParameters["start"]}`,{headers:headerss })
                      .subscribe(resp => {
                        console.log( dataTablesParameters["start"]);
                        console.log( resp["data"]);
                        //console.log( resp["data"].length);
                        console.log(  resp["dataLength"]);
                        
                        if(resp["error"]==0){
                            self.alldevices=resp["data"];
                        }
                        else{
                            self.alldevices=[];
                            resp["dataLength"]=0;
                        }
                        
                        callback({
                            recordsTotal: resp["dataLength"],
                            recordsFiltered: resp["dataLength"],
                            data: []
                          });   
                        
                      });
                    }
                  },
    
              };*/
             /* self.dtOptionsNotifications = {
                pagingType: 'full_numbers',
                pageLength: 10,
                lengthChange: false,
                responsive: true,
                serverSide : true,
                processing: true,
                searching: false,
                sort:false,
                oLanguage: {
            
                    "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                    ,"oPaginate": {
                        "sFirst":    "الأولي",
                        "sLast":     "الاخيرة",
                        "sNext":     "التالية",
                        "sPrevious": "السابقة" 
                    },
                    "sInfo":           "عرض من _START_ إلي _END_ من إجمالي _TOTAL_ بيانات",
                    "sInfoEmpty":      "عرض من 0 إلي 0 من إجمالي 0 البيانات",
                   },
                
                
                ajax: (dataTablesParameters: any, callback) => {
                    var currentUser = this.apiService.getUserToken();
                var headerss = new HttpHeaders({
                  'content-type' : 'application/json',
                  'TOKEN': currentUser["token"], 
                  'Accept': 'application/json'
                });
    
                if(this.connectionStatus=="offline")
                {
                  this.translate.get(this.connectionStatusMessage).subscribe(res => {
                    $.notify({ message:res}, {type:"danger"});
                  });
                }
                else{
                    
                    this.httpClient.get(`${ConfigVariables.API_URL}/get_notifiction_current_day?offset=${dataTablesParameters["start"]}`,{headers:headerss })
                      .subscribe(resp => {
                        console.log( dataTablesParameters["start"]);
                        console.log( resp["data"]);
                        //console.log( resp["data"].length);
                        console.log(  resp["dataLength"]);
                        
                        if(resp["data"].length>0){
                            self.allnotifications=resp["data"];

                        }
                        else if(resp["data"].length==0){
                            self.allnotifications=[];
                        }
                        
                        callback({
                            recordsTotal: resp["dataLength"],
                            recordsFiltered: resp["dataLength"],
                            data: []
                          });   
                        
                      });
                    }
                  },
    
              };
              
          }*/
        
    
    }

      
   
        
  
    }
    
    onExit(){
       // $('#exampleM').DataTable().destroy();
        console.log("destroy table from exit");
        this.deviceIntervalIds.forEach(element => {clearInterval(element);
            
        });

    }
    moveMarker(data)
    {
       
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
           let i=0;
        self.devices.forEach(element => {
           
            data.forEach(element1 => {
                if(element.deviceid==element1.deviceid)
                {
                   if(element.status!=element1.status)
                   {
                    var color="";
                    var iconColor:any;
                    if(element1.status=="online"){
                        color="green";
                        iconColor={url:"http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                    labelOrigin: new google.maps.Point(16,64)};
                    }
                    else if(element1.status=="offline"){
                        color="red";
                        iconColor={url:"http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                        labelOrigin: new google.maps.Point(16,64)};
        
                    }
                    else if(element1.status=="out of network"){
                        color="orange";
                        iconColor={url:"http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
                        labelOrigin: new google.maps.Point(16,64)};
                        
                    } 
                      element.status=element1.status;
                       var newPosition= new google.maps.LatLng(element1.latitude, element1.longitude);
                       element.deviceMarker.setIcon(iconColor);
                      element.deviceMarker.setLabel({
                         text: element["deviceName"],
                         color: color,
                         fontSize: "20px",
                         fontWeight: "bold",
 
                             })
                }
                
                  
                    var newPosition= new google.maps.LatLng(element1.latitude, element1.longitude);
                    element.deviceMarker.animateTo(newPosition, { 
                    duration: 15000
                    }); 
                    element.latitude=element1.latitude;
                    element.longitude=element1.longitude; 
                     
                    data.break;                
                }
                
                
            });
            self.devices[i]=element;
            i++;
        });
    }
    }
    // 2 intervals on markers and data table
     displayDeviceDataTable(): void {
         let i=1;
        this.dtElements.forEach((dtElement: DataTableDirective) => {
            if(i==3)
            {
                dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    
                  //  dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                   // this.dtTriggerr.next();
                    dtInstance.ajax.reload();

                  //  dtInstance.destroy();
                    // Call the dtTrigger to rerender again
                   // this.dtTriggerr.next();
                   // this.dtTriggerr.next();
                });
                
            }
            i++;
          });
          
       
          
       
      }
      displayVehicleDataTable(): void {
        let i=1;
       this.dtElements.forEach((dtElement: DataTableDirective) => {
           if(i==4)
           {
               dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                   
                 //  dtInstance.destroy();
                   // Call the dtTrigger to rerender again
                  // this.dtTriggerr.next();
                   dtInstance.ajax.reload();

                 //  dtInstance.destroy();
                   // Call the dtTrigger to rerender again
                  // this.dtTriggerr.next();
                  // this.dtTriggerr.next();
               });
               
           }
           i++;
         });
        }
    onClick(devicee){
     
    console.log("deviceid"+this.ModalDeviceId);
         this.deviceimageURL="";
        this.deviceIntervalIds=[];
        this.device=devicee;
        this.deviceimageURL=this.device['photo'];
        this.devicename=this.device['deviceName'];
        this.ModalDeviceId=this.device['deviceid'];
        this.displayDeviceDataTable();
        this.displayVehicleDataTable();
        this.getDeviceLocation(this.device['deviceid']);
        this.deviceModalInterval();
      

    }
    //function init modal map
    getDeviceLocation(deviceId)
    {   
        let color="";
        let iconColor="";
        let deviceMarker="";
        let center={lat: 25.8541828, lng: 41.48963};
        let status="";
        
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
            this.apiService.getReportOfDevice(this.device['deviceid']).subscribe((response) => {
            
                if (response["error"] == 0) {
                    if(response['data'].length==0){
                        
                        this.initDeviceMap(center,false,iconColor);
                    }
                    else{
                        status=response['data'].status;
                        center={lat: parseFloat(response["data"].latitude), lng: parseFloat(response["data"].longitude)};
                        if(response['data'].status=="online"){
                            color="green";
                            iconColor="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                        }
                        else if(response['data'].status=="offline"){
                            color="red";
                            iconColor="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                
                        }
                        else if(response['data'].status=="out of network"){
                            color="orange";
                            iconColor="http://maps.google.com/mapfiles/ms/icons/orange-dot.png"
                            
                        }
                        self.deviceMarker=this.initDeviceMap(center,true,iconColor);  
                     //  let position= new google.maps.LatLng( lat,lng );
                        self.moveDeviceMarker(self.deviceMarker,status,this.device['deviceid']);
                    }
                }  
            }); 
           
            
        }        
    }
    initDeviceMap(center,HasMarker,iconColor){
       
        //var myLatLng={lat:parseFloat(this.device.latitude),lng: parseFloat(this.device.longitude)};
         this.deviceMap = new google.maps.Map(
        document.getElementById('mapLocation'), {zoom: 4, center:center
          });
          this.deviceMap.setMapTypeId('satellite');
        if(HasMarker)
        {
            var marker = new google.maps.Marker({
            icon:iconColor,
            position: center,
            map: this.deviceMap
            }); 
        }
        return marker;
        
    }
    
    deviceModalInterval()
    {
        self=this;
        var intervalid
        intervalid=setInterval(function() {
            console.log("Start device modal");

               //var newPosition= new google.maps.LatLng( 25.8541828,41.48963 );
               //self.moveDeviceMarker(deviceMarker,newPosition);
               self.displayDeviceDataTable();
                console.log("End device mdal");
        }, 15000 );
        self.deviceIntervalIds.push(intervalid);
    }
    //function move map marker 
    moveDeviceMarker(deviceMarker,status,deviceId)
    {
       var intervalid;
        intervalid=setInterval(function() {
            console.log("Start map modal");
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
            self.apiService.getReportOfDevice(deviceId).subscribe((response) => {
            
                if (response["error"] == 0) {
                    if(response['data'].length==0){
                        
                        
                    }
                    else{
                        if(status !=response['data'].status)
                        {
                            let color="";
                            let iconColor:any;
                            if(response['data'].status=="online"){
                                color="green";
                                iconColor={url:"http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                           labelOrigin: new google.maps.Point(16,64)
                                          };
                            }
                            else if(response['data'].status=="offline"){
                                color="red";
                                iconColor={url:"http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                          labelOrigin: new google.maps.Point(16,64)};
                    
                            }
                            else if(response['data'].status=="out of network"){
                                color="orange";
                                iconColor={url:"http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
                                        labelOrigin: new google.maps.Point(16,64)};
                                
                            }
                            status=response['data'].status;
                            var lat=parseFloat(response['data'].latitude);
                            var lng=parseFloat(response['data'].longitude);
                            var newPosition=new google.maps.LatLng(lat,lng );
                            self.deviceMarker.setIcon(iconColor);;
                           
                            
                        }
                            var lat=parseFloat(response['data'].latitude);
                            var lng=parseFloat(response['data'].longitude);
                            var newPosition=new google.maps.LatLng(lat,lng );
                            deviceMarker.animateTo(newPosition, { 
                                duration: 15000
                            });   
                        
                    }
                }  
            }); 
    
                   //var newPosition= new google.maps.LatLng( 25.8541828,41.48963 );
                   //self.moveDeviceMarker(deviceMarker,newPosition);
                  
                  
                   
        
                   
                     
                                   
                }
                console.log("End map mdal");
            }, 15000 );
            this.deviceIntervalIds.push(intervalid) ;      
          
    }

    getAllDevicesLastInfo(){
        self.devices=[];
        self.markers=[];
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
        this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
            if (response["error"] == 0) {
                //console.log(response);
                self.devices = response["data"];
                //console.log(response["data"]);
                //console.log(response["data"].length);
                if(self.devices.length==0){
                    self.initMap();
                }
                  else{

                    self.initMap();
                   

                  }
              

            }
            else {
              
               self.devices=[];
               self.initMap();
              

            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
    }
    }
    
    initMap(){  
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{    
        var uluru = {lat: 25.8541828, lng: 41.48963};
        map = new google.maps.Map(
            document.getElementById('map'), {
                zoom: 4,
                center: uluru,
              });
        map.setMapTypeId('satellite');
        this.devices.forEach(device => {
            var color="";
            var iconColor:any;
            if(device['status']=="online"){
                color="green";
                iconColor={url:"http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                           labelOrigin: new google.maps.Point(16,64)};
            }
            else if(device['status']=="offline"){
                color="red";
                iconColor={ url:"http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                           labelOrigin: new google.maps.Point(16,64)};

            }
            else if(device['status']=="out of network"){
                color="orange";
                iconColor={url:"http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
                       labelOrigin: new google.maps.Point(16,64)};
                
            }
            var pos = {lat: parseFloat(device.latitude) ,lng: parseFloat(device.longitude)};
            var dMarker = new google.maps.Marker({icon:iconColor,position: pos, map: map,label: {
                text: device["deviceName"],
                color: color,
                fontSize: "20px",
                fontWeight: "bold",   
            }
            });
            var deviceInfoData=[];
            
          // deviceInfoData['devicePhoto']='<img class="avatar" style=" margin: 0px; padding: 0px;border: none;background-size: cover;" [src]=device.photo alt="Alternative text to the image"></td>';
            deviceInfoData['Driver Name']=device.driverName;
            deviceInfoData['Last Update']=device.lastupdate;
            deviceInfoData['Weight']=device.weight;
            deviceInfoData['Address']=device.address;
            deviceInfoData['Last Speed']=device.speed; 
            deviceInfoData['Vehicle Plate']=device.leftLetter+" "+device.middleLetter+" "+device.rightLetter+" "+device.plateNum;
            deviceInfoData['Vehicle Working Hours Per Day']=device.deviceWorkingHoursPerDay;
            deviceInfoData['Driver Working Hours Per Day']=device.driverWorkingHoursPerDay;
        
            var infowindow = new google.maps.InfoWindow({
                content: self.tableCreate(deviceInfoData)
            });
            
            dMarker.addListener('mouseover', function(device) {
                
                infowindow.open(map, dMarker);
              });
        
            
            
            device.deviceMarker = dMarker;
            device.color = color;
            
            this.markers.push( device.deviceMarker);
       
        });

        var markerCluster = new  MarkerClusterer( map, this.markers ,
               {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
               
            });
                                
        }
        var infowindowCluster = new google.maps.InfoWindow({
            content: "data"
        });
       /* google.maps.event.addListener(markerCluster, 'mouseover', (cluster:any) =>{
            console.log("worked"); // here I want to place display some infowindow
     
            })*/
        
       /* markerCluster.addListener(map,'click',function(cluster){
            infowindowCluster.open(map, markerCluster);
        });*/

    }
    tableCreate(device) {
        var body = document.createElement('div');
        body.setAttribute('class','form-group');
        var tbl = document.createElement('table');
        tbl.style.width = '100%';
        tbl.setAttribute('class', 'table table-hover nowrap margin-bottom-0');
        
        
        var thead = document.createElement('thead');
        var trH = document.createElement('tr');
        
        var tdH = document.createElement('th');
        var tdH2 = document.createElement('th');
        self.translate.get('Attributes').subscribe(res => {
            tdH.appendChild(document.createTextNode(res));
        });
        self.translate.get('Value').subscribe(res => {
            tdH2.appendChild(document.createTextNode(res));
        });
        if(self.currentLan == 'ar')
        {
            tbl.style.direction = 'rtl';
            tdH.style.textAlign = 'right';
            tdH2.style.textAlign = 'right';
        }
        trH.appendChild(tdH);
        trH.appendChild(tdH2);
        thead.appendChild(trH);
        tbl.appendChild(thead);
        var tbdy = document.createElement('tbody');
        for (var property in device) {
            var tr = document.createElement('tr');
            var td = document.createElement('td');
            var translatedProperty = "";
            self.translate.get(property).subscribe(res => {
                translatedProperty = res
            });

            td.appendChild(document.createTextNode("  "+translatedProperty));
            tr.appendChild(td)

            var td2 = document.createElement('td');
            var translatedValue = device[property];
            if(translatedValue != null && translatedValue != 0){
                console.log(translatedValue);
                self.translate.get(translatedValue).subscribe(res => {
                    translatedValue = res
                });
            }
            
            td2.appendChild(document.createTextNode(translatedValue));
            tr.appendChild(td2);
            tbdy.appendChild(tr);
        }
        tbl.appendChild(tbdy);
        body.appendChild(tbl)
        return body.innerHTML;
      }
    ngAfterViewInit() {
        self.dtTrigger.next();
        self.dtTriggerNotifications.next();
        self.dtTriggerr.next();
        self.dtTriggerVehicleData.next();
      // self.devicesDataTable();
      this.dashboardIntervalsIds.push(setInterval(function() {
        console.log("Start device");
            $('#example1').DataTable().ajax.reload( null, false );
      //  $('#example1').DataTable().ajax.reload();
        //self.devicesDataTable()
            console.log("End device");
            }, 15000 )); 
            if(!this.isMaster){
            this.dashboardIntervalsIds.push(setInterval(function() {
            console.log("Start notifi");
            //$('#example2').DataTable().ajax.reload( null, false );
            $('#example2').DataTable().ajax.reload( null, false);
            console.log("End notifi");
            }, 15000 )); 
        }
            this.dashboardIntervalsIds.push(setInterval(function() {
            console.log("Start");
            self.OnlineAndOffline();
            console.log("End");
            }, 15000 )); 
        this.dashboardIntervalsIds.push(setInterval(function() {
            console.log("Start");
            self.getAllDevicesLastInfoInterval();
            console.log("End");
            }, 15000 ));      
    }
    OnlineAndOffline(){
         // call dashbopard service
         if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{

        
         this.apiService.getDevicesAndDriversStatusService().subscribe((response) => {
            if (response["error"] == 0) {
                self.dashboarddevicesstatusnumbers.deserialize(response["data"]);
                var options = {
                    scales: {
                            ticks: {
                                fontColor:'#514d6a'
                            }
                    }
                   
                }
            var data = {
                datasets: [{
                    data: [this.dashboarddevicesstatusnumbers.online_devices, this.dashboarddevicesstatusnumbers.offline_devices, this.dashboarddevicesstatusnumbers.unknown_devices],
                    backgroundColor:['#46be8a','#ff4d4d','#ff9900'],
                }],
            
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    this.online,
                    this.offline,
                    this.out_of_network
                ]
            };
            var myChart = new Chart('myChart', {
                data: data,
                type: 'polarArea',
                options:options
            
            });
            }
            else {
                // show errors message
                console.log(response["error"]);
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
    }
    

    }
    setStyles(device) {
       
        if(device.status == 'offline'){
        let styles = {
            'color':'#d00909' 

        };
    
        return styles;
       }   
    
        if(device.status == 'online'){
            let styles = {
                'color':'green' 

            };
        
            return styles;
        }   
        if(device.status == 'out of network'){
            let styles = {
                'color':'orange' 

            };
        
            return styles;
        }   
    }
    getTodayNotifications(){
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
        this.apiService.getCurrentDayNotifications().subscribe((response) => {
            if (response["error"] == 0) {
                console.log(response);
                self.notifications= response["data"];
                if( self.notifications.length==0){
                    self.notifications=[];
                    //why reinite the array twice???!!![maryam]
                   // self.notifications=[];
                }
                else{
                    
                  
                }
            }
            else {
                // show errors message
                console.log(response["error"]);
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });
    }
    }
    getAllDevicesLastInfoInterval(){
        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
        this.apiService.getAllDevicesLastInfoService().subscribe((response) => {
            if (response["error"] == 0) {
                
                self.moveMarker(response["data"]);
            }
            else {
                // show errors message
               // console.log(response["error"]);
            }
        },
        error => {
            // this.alertService.error(error);
            console.log("error" + error);
            // this.loading = false;
        });1
    }
   }
    devicesDataTable() {
        self.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            lengthChange: false,
            responsive: false,
            serverSide : true,
            processing: true,
            searching: false,
            sort:false,
            "language": {
              "lengthMenu": "Display _MENU_ records per page",
              "zeroRecords": "Nothing found - sorry",
              "info": "Showing page _PAGE_ of _PAGES_",
              "infoEmpty": "No records available",
              "infoFiltered": "(filtered from _MAX_ total records)"
          },
            oLanguage: {
        
                "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                
               },
            
            
            ajax: (dataTablesParameters: any, callback) => {
                var currentUser = this.apiService.getUserToken();
            this.checkLogin(currentUser);
                var headerss = new HttpHeaders({
                'content-type' : 'application/json',
                'TOKEN': currentUser["token"], 
                'Accept': 'application/json'
                });
                if(this.connectionStatus=="offline")
                {
                  this.translate.get(this.connectionStatusMessage).subscribe(res => {
                    $.notify({ message:res}, {type:"danger"});
                  });
                }
                else
                {
                    this.httpClient.get(`${ConfigVariables.API_URL}/all_devices_last_info?offset=${dataTablesParameters["start"]}`,{headers:headerss })
                    .subscribe(resp => {
                      console.log( dataTablesParameters["start"]);
                      console.log( resp["data"]);
                      console.log("length"+ resp["dataLength"]);
                      if(resp["error"]==0){
                           
                          self.alldevices=resp["data"];
                          var names=[];
                          var values=[];
                          self.alldevices.forEach(element => {
                              names.push(element.deviceName);
                              if(element.driverWorkingHoursPerDay == 0)
                              {
                                values.push(element.driverWorkingHoursPerDay);
                              }
                              else
                              {
                                  var hours=Number(element.driverWorkingHoursPerDay.split(":")[0]);
                                  values.push(hours);
                                 // console.log(element.driverWorkingHoursPerDay.split(":"));


                              }
                               
                            
                              
                          });
                         var options = {
                            scales: {
                                xAxes: [{
                                    barPercentage: 0.5,
                                    barThickness: 50,
                                    maxBarThickness: 50,
                                    minBarLength: 2,
                                    gridLines: {
                                        offsetGridLines: false
                                    },
                                    ticks: {
                                        fontColor:'#514d6a'   
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        fontColor:'#514d6a'   
                                    }
                                }]
                            },
                            legend: {
                                display: false,
                            },
                            backdropColor:'#b3edff'
                        };
                        var data = {
                            datasets: [{
                                data: values,
                                backgroundColor:['#e6e6e6','#b3edff','#e6e6e6','#b3edff','#e6e6e6','#b3edff','#e6e6e6','#b3edff','#e6e6e6','#b3edff'],
                                
                            }],
                        
                            // These labels appear in the legend and in the tooltips when hovering different arcs
                            labels:names
                        };
                        console.log("names"+names);
                          var myBarChart = new Chart('myBarChart', {
                            type: 'bar',
                            data: data,
                            options: options
                        });
                       
                      }
                      else{
                          self.alldevices=[];
                          resp["dataLength"]=0;
                      }
                     
                      
                      
                      callback({
                          recordsTotal: resp["dataLength"],
                          recordsFiltered: resp["dataLength"],
                          data: []
                        });   
                      
                    });
                 
              
                }
            },
  
        };
                
   }
   notificationsDataTable()
   {
    self.dtOptionsNotifications = {
            
            pageLength: 10,
            lengthChange: false,
            responsive: false,
            serverSide : true,
            processing: true,
            searching: false,
            sort:false,
            
           "language": {
            "lengthMenu": "Display _MENU_ records per page",
            "zeroRecords": "Nothing found - sorry",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
           
        },
        oLanguage: {
    
            "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            
           },
        
        ajax: (dataTablesParameters: any, callback) => {
            var currentUser = this.apiService.getUserToken();
            this.checkLogin(currentUser);
          var headerss = new HttpHeaders({
          'content-type' : 'application/json',
          'TOKEN': currentUser["token"], 
          'Accept': 'application/json'
        });

        if(this.connectionStatus=="offline")
        {
          this.translate.get(this.connectionStatusMessage).subscribe(res => {
            $.notify({ message:res}, {type:"danger"});
          });
        }
        else{
            this.httpClient.get(`${ConfigVariables.API_URL}/get_notifiction_current_day?offset=${dataTablesParameters["start"]}`,{headers:headerss })
              .subscribe(resp => {
                console.log( dataTablesParameters["start"]);
                console.log("notifications"+ resp["data"]);
                console.log( resp["data"].length);
                console.log(  resp["dataLength"]);
                
                if(resp["data"].length>0){
                    self.allnotifications=resp["data"];

                }
                else if(resp["data"].length==0){
                    self.allnotifications=[];
                    
                }
                
                callback({
                    recordsTotal:resp["dataLength"] ,
                    recordsFiltered: resp["dataLength"],
                    data:  []
                    
                  });   
                
              });
            }
          },

      };

   }
   deviceModalDataTable() {
    self.dtOptionss = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthChange: false,
        responsive: true,
        serverSide : false,
        processing: true,
        searching: false,
        sort:false,
        paginate:false,
        info:false,
        oLanguage: {
    
            "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            
           },
           
        
        ajax: (dataTablesParameters: any, callback) => {
            if(this.ModalDeviceId==0){
          
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: []
                });
                self.deviceData = [];
                return;
              }
            var currentUser = this.apiService.getUserToken();
            this.checkLogin(currentUser);
            var headerss = new HttpHeaders({
            'content-type' : 'application/json',
            'TOKEN': currentUser["token"], 
            'Accept': 'application/json'
            });
            
            if(this.connectionStatus=="offline")
            {
              this.translate.get(this.connectionStatusMessage).subscribe(res => {
                $.notify({ message:res}, {type:"danger"});
              });
            }
            else
            {
                this.httpClient.post(`${ConfigVariables.API_URL}/get_last_data_of_device`,{deviceId:this.ModalDeviceId},{headers:headerss })
                .subscribe(resp => {
                 
                  if(resp["error"]==0){
                      console.log("devicedata"+resp['data'][0].key+resp['data'][0].value);
                        this.deviceData = resp['data'];
                        console.log(this.deviceData);
                      
                    
                  }
                  else{
                     
                    self.deviceDetails=[];
                    self.deviceData=[]; 
                  }
                 
                  
                  
                  callback({
                      recordsTotal: resp["data"].length,
                      recordsFiltered: resp["data"].length,
                      data: []
                    });   
                  
                });
               
          
            }
        },
        
         
    };
  
}
checkLogin(currentUser)
{
 if(currentUser=null){
  
  this.router.navigate(['login']);          
 }
 
}

    print(){
            
        let  popupWin;
        var listInfo =  document.getElementById('listInfo').innerHTML;
        var listInfo2=  document.getElementById('listInfo2').innerHTML;
        var vehiclename= document.getElementById('vehiclename').innerHTML;
        let mapLocation =  document.getElementById('mapLocationParent').innerHTML;

        listInfo = listInfo.replace("No data available in table","");
        listInfo2 = listInfo2.replace("No data available in table","");
        vehiclename=vehiclename.replace("No data available in table","");
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
            <head>
            <title>Print tab</title>
            <style>                          

            </style>
            </head>
        <body onload='window.print();window.close()'>${vehiclename} <br>${listInfo} <div style='page-break-before: always;'> </div>${listInfo2}<div style='page-break-before: always;'> </div> ${mapLocation} </body>
        </html>`
        );
        popupWin.document.close();
        
    }
   getVehicleData()
   {
        self.dtOptionsVehicleData = {
            pagingType: 'full_numbers',
            pageLength: 5,
            lengthChange: false,
            responsive: true,
            serverSide : false,
            processing: true,
            searching: false,
            sort:false,
            paginate:false,
            info:false,
            oLanguage: {
        
                "sProcessing": '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
                
            },
            
            
            ajax: (dataTablesParameters: any, callback) => {
                if(this.ModalDeviceId==0){
            
                    callback({
                    recordsTotal: 0,
                    recordsFiltered: 0,
                    data: []
                    });
                    self.vehicleData = [];
                    return;
                }
                var currentUser = this.apiService.getUserToken();
                this.checkLogin(currentUser);
                var headerss = new HttpHeaders({
                'content-type' : 'application/json',
                'TOKEN': currentUser["token"], 
                'Accept': 'application/json'
                });
                
                if(this.connectionStatus=="offline")
                {
                this.translate.get(this.connectionStatusMessage).subscribe(res => {
                    $.notify({ message:res}, {type:"danger"});
                });
                }
                else
                {
                    this.httpClient.post(`${ConfigVariables.API_URL}/get_vehicle_data`,{deviceId:this.ModalDeviceId},{headers:headerss })
                    .subscribe(resp => {
                    
                    if(resp["error"]==0){
                        console.log("devicedata"+resp['data'][0].key+resp['data'][0].value);
                            this.vehicleData = resp['data'];
                            console.log("vehicleData"+this.vehicleData);
                        
                        
                    }
                    else{
                        
                       // self.deviceDetails=[];
                        self.vehicleData=[]; 
                    }
                    
                    
                    
                    callback({
                        recordsTotal: resp["data"].length,
                        recordsFiltered: resp["data"].length,
                        data: []
                        });   
                    
                    });
                
            
                }
            },
            
            
        };
    }
    getListOfBox(clickedBox)
    {
        console.log("collapse"+this.isCollapsed);
      if(clickedBox==1){
          //online devices
      }
      else if(clickedBox==2)
      {
          //offline devices
      }
      else if(clickedBox==3)
      {
          //outofnetwork devices
      }
      else if(clickedBox==4)
      {
          //drivers
      }
    }
    ngOnDestroy()
    {
        this.dashboardIntervalsIds.forEach(element => {
            clearInterval(element);
        });
    } 

}
