export class Geofence {
    id: number;
    name: String;
    description: String;
    area: String;
    type: String;
    attrbuits: String;
    
    constructor(){
        this.id = 0;
        this.name = "";
        this.description = "";
        this.area = "";
        this.type = "";
        this.attrbuits = "";
    }

    public deserialize(input: any) {
        Object.assign(this, input);
        return this;   
    }
}
